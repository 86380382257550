
// Calculate Height for SVG
// -------------------
@mixin svg-icon($width,$height,$w:100%) {
    max-width: $w;

	&:before {
		padding-top: ($height / $width) * 100%;
	}
}

.svg-icon {
    width: 100%;
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        right: -50%;
        height: 100%;
        bottom: -50%;
        transform: translateX(-50%) translateY(-50%);
        transform: translate3d(-50%,-50%,0);
        // transition: .6s $easeOut;
    }
}
