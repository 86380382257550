.header-search {
	border:2px $textColor solid;
	padding:1rem 1rem 1rem 3.5rem;
	position:relative;
	i {
		position:absolute;
		top:1.5rem;
		left:1rem;
		font-size:1.5rem;
	}
	input {
		border:none;
		margin:0;
	}
}

.header-search-more {
	a {
		font-weight:bold;
		color:$primaryColor;
	}
}

.header-search-none,
.header-search-loading {
	margin:1rem 0;
	color:$primaryColor;
	font-weight:bold;
}

.feed-section .feed.header-search-results {
	margin:0 0 1rem;
}