.can_embed {

	font-family:$paragraphFont;

	#can_embed_form, #donate_auto_modal {
		padding:0 !important;	
		border:none !important;
		background:transparent !important;
		#can_thank_you {
			padding:2rem;
			background:transparent !important;
			color:$textColor !important;
		}
	}

	// Typography
	#can_embed_form h1, #can_embed_form h2, #can_embed_form h3, #can_embed_form h4, #can_embed_form h5, #can_embed_form h6,
	#can_embed_form input, #can_embed_form textarea, #can_embed_form select {
		font-family:$paragraphFont !important;
	}
	#can_embed_form h1 {
		font-size:2rem;
	}

	// Buttons 
	.can_button, 
	#can_embed_form input[type=submit],
	#can_embed_form .button, 
	#donate_auto_modal input[type=submit], 
	#donate_auto_modal .button {
		background:$primaryColor !important;
		color:$textColor !important;
		font-family:$paragraphFont !important;
		font-weight:bold !important;
		padding: .65em 1.5em 0.55em 1.5em !important;
		&:hover,
		&:focus {
			background:darken($primaryColor,10%) !important;
			color:darken($textColor,10%) !important;

		}
	}

	// Remove titles
	#can_embed_form_inner {
		> h2:first-child {
			display:none;
			+ h4 {
				display:none;
			}
		}
	}

	// Removals
	#logo_wrap,
	#action_info,
	.can_thank_you-block {
		display:none;
	}

	// Unremovals
	.can_thank_you-block:first-child {
		display:block;
	}

	// Full width columns
	#can_embed_form.can_float #form_col1,
	#can_embed_form.can_float #form_col2 {
		width:100% !important;
	}

	// Checkboxes
	#can_embed_form #d_sharing input,
	#can_embed_form .check_radio_field label input {
		-webkit-appearance: checkbox !important;
		top: 0px!important;
		@include respond-to('mobile') {
			top: 5px!important;
			width:20px;
		}
	}

	#can_embed_form form li {
		width: 48%!important;
		margin: 0 2% 15px 0 !important;
		float: left !important;
		&.form_builder_output {
			width:100% !important;
		}
		@include respond-to('mobile') {
			margin-right:0 !important;
			width:100% !important;
		}
	}

}