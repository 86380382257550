
.section.calltoaction-section {
	padding:4rem 0;
	background:$primaryColor;
	color:white;

    &.-centered {
        text-align: center;
    }

    .content {
        @include columns(9,1);

        @include respond-to('mobile') {
            width: 80vw;
        }

        h1,h2,h3 {
            color:white;
        }
    }

    img { border-radius: $br }

}
