//
// Buttons
// --------------------------------------------------
button,
.button,
[class^="button"],
input[type="button"],
input[type="submit"],
input[type="reset"] {
	padding: .65em 1.5em 0.55em 1.5em;
	font-size: 1rem;

	@include respond-to('tablet') { font-size: 1.2rem }
	
	@include button-style($primaryColor, $textColor);

	&:not(:last-child) {
		margin-bottom: 1em;
	}
	
	&:not(:last-of-type) {
		margin-right: .8em;
		@include respond-to('mobile') { margin-right: 0; }
	}
	
	&.-small {
		font-size:.7rem;
	}
	
	&.-large,
	&.huge {
		font-size: 1.25rem;
	}
}

button.-primary,
.button.-primary,
input.button-primary,
.button.wc-forward.alt  {
	@include button-style($primaryColor, $textColor);
}

button.-secondary,
.button.-secondary,
input.button-secondary {
	@include button-style($secondaryColor, $textColor);
}

button.-inverse,
.button.-inverse,
input.button-inverse,
.button.wc-forward {
	@include button-style(white, $textColor);
	color:$textColor;
	&:hover,
	&:focus {
		color:$textColor;
	}
}

button.-dark,
.button.-dark,
input.button-secondary {
	@include button-style($textColor, $baseColor);
	color:$primaryColor;
	&:hover,
	&:focus {
		color:white	;
	}
}

button.-action,
.button.-action,
input.button-secondary {
	@include button-style($actionColor, $textColor);
}

button.-action,
.button.-action {
	@include button-style($actionColor, $textColor);
}

button.facebook,
.button-facebook,
input.button-facebook {
	@include button-style($socialFacebook, white);
	padding-left: 0.6em;
	
	&.disabled {
		background: #9099ae;
		color: white;
		cursor: default;
	}
}

button.twitter,
.button-twitter,
input.button-twitter {
	@include button-style($socialTwitter, white);
	
	&.disabled {
		background-color: #aebcc7;
		color: white;
		cursor: default;
	}
}


// Icon Buttons
// ----------------------------

.search-icon {
	$unit: 26px;
	
	appearance: none;
	width: $unit;
	height: $unit;
	position: relative;
	padding: 0;
	box-sizing: content-box;
	border: none;
	// outline: none;
	cursor: pointer;
	color: $textColor;
	background: transparent;
	
	&:hover,
	&:focus,
	&.-active {
		color: $secondaryColor;
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 1px;
		left: 1px;
		width: $unit*.66;
		height: $unit*.66;
		// box-shadow: inset 0 0 0 3px currentColor;
		border-radius: 20em;
		transition: .4s $easeOut;
		transform-origin: top left;
	}
	
	span {
		@include full;
		
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 3px;
			background: currentColor;
			transition: .4s $easeOut;
		}
		
		&:before {
			transform: translate(0,-50%) rotate(45deg);
			transform: translate3d(20%,150%,0) rotate(45deg) scale3d(.5,1,1);
		}
		
		&:after {
			opacity: 0;
			transform: translate(0,-50%) rotate(-45deg) scale(.4,1);
			transform: translate3d(0,-50%,0) rotate(-45deg) scale3d(.4,1,1);
		}
	}
	
	&.-active {
		
		&:before {
			opacity: 0;
			transform: scale(.7,.8);
			transform: scale3d(.7,.8,1);
		}
		
		span:before {
			transform: translate(0,-50%) rotate(45deg);
			transform: translate3d(0,-50%,0) rotate(45deg);
		}
		
		span:after {
			opacity: 1;
			transform: translate(0,-50%) rotate(-45deg);
			transform: translate3d(0,-50%,0) rotate(-45deg);
		}
	}
}
