
.tab-section {
	
	.content {
		@include columns(12,0);
		text-align: left;
		border-radius: $br;
		overflow: hidden;
		background-image: linear-gradient(35deg, #FFFFFF 67%, rgba(255,255,255,0.00) 100%);
		background-size: 120% 100%;
		
		@include respond-to('mobile') {
			margin: 0 $MBgutter;
			width: 90vw;
		}
	}
	
	
	// Tab Navigation
	// -------------------
	
	.tab-nav {
		box-shadow: 0 1px 0 currentColor;
		@include clearfix;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	
	.-secondary {
		
		.nav-item,
		.tab-nav {
			color: $textColor;
		}
	}
	
	.-dark {
		
		.nav-item,
		.tab-nav {
			color: $secondaryColor;
		}
	}
	
	&.-secondary,
	&.-dark {
		
		h3 {
			color: white;
		}
		
		.content {
			background-size: 150% 100%;
		}
	}
	
	&.-primary {
		
		.nav-item {
			color: $secondaryColor;
			
			&:not(:first-of-type) {
				box-shadow: -1px 0 0 $primaryColor;
			}
		}
		
		.tab-nav {
			color: $primaryColor;
		}
	}
	
	.nav-item {
		float: left;
		border-radius: none;
		border: none;
		appearance: none;
		background: transparent;
		color: $secondaryColor;
		outline: none;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		margin: 0;
		pointer-events: all;
		padding: 3.3vw 1vw;
		&:hover,
		&:focus {
			
		}
		
		&.-long {
			padding: 2vw 0vw;
		}
		
		&.-active {
			// pointer-events: none;
			@include respond-to('mobile') { pointer-events: all }
			background:$secondaryColor;
			color:white;
			
			h5 {
				color: white;
			}
		}
		
		h5 {
			position: relative;
			z-index: 1;
			display: inline-block;
			transition: .6s $easeOut;
			will-change: color, transform;
			vertical-align: middle;
			margin: 0;
			max-width: calc(100% - calc(26px + 1em));
			
			&.-long {
				margin-bottom: .2em;
			}
		}
		
		&:not(:first-of-type) {
			box-shadow: -1px 0 0 currentColor;
		}
		
	}
	
	
	// Tab Content
	// -------------------
	
	.tab {
		color: $textColor;
		position: relative;
		overflow: hidden;
		float: left;
		visibility: hidden;
		height: 0;
		display: block;
		width: 100%;
		
		.tab-content {
			padding: $gutter;
			display: flex;
			flex-wrap: wrap;
			overflow-x: auto;
			min-height: 30vw;
			width: 55%;
			flex-direction: column;
			flex-basis: 0;
			align-items: flex-start;
			transition: .4s $easeOut .2s;
			
			p {
				width: 100%;
			}
			
			@include respond-to('mobile') {
				width: 100%;
				padding: $MBgutter;
			}
		}
		
		.image {
			background-color: grey;
			position: absolute;
			top: 1px;
			right: 0;
			bottom: 0;
			left: 60%;
			transition: .5s $easeOut;
			border-radius: 0 0 $br 0;
			
			@include respond-to('mobile') {
				position: relative;
				top: auto;
				right: auto;
				bottom: auto;
				left: auto;
				height: 40vh;
				border-radius: 0 0 $br*.5 $br*.5;
			}
		}
		
		.button {
			margin-top: auto;
		}
	}
	
	
	// Active Tab
	// -------------------
	
	.tab-nav {
		@include respond-to('mobile') { display: none }
		
		&[class*="active-"] ~ .tabs .tab {
			visibility: hidden;
			height: 0;
		}
		
		@media only screen and (min-width: 736px) {
			@for $i from 1 to 6 {
				
				&.tabs-#{$i} .nav-item {
					width: calc(100% / #{$i});
					float: left;
				}
				
				&.active-#{$i} ~ .tabs {
					
					.tab {
						
						.tab-content {
							opacity: 0;
							transform: translate(1vw,0);
							transform: translate3d(1vw,0,0);
							will-change: transform, opacity;
						}
						
						.image {
							transform: translate(1vw,0,0);
							transform: translate3d(1vw,0,0);
							opacity: 0;
						}
					}
					
					.tab:nth-of-type(#{$i}) {
						visibility: visible;
						height: auto;
						
						.tab-content {
							opacity: 1;
							transform: translate(0,0);
							transform: translate3d(0,0,0);
						}
						
						.image {
							opacity: 1;
							transform: translate(0,0);
							transform: translate3d(0,0,0);
							transition: .5s $easeOut .3s;
						}
					}
				}
			}
		}
	}
	
	// Mobile Tabs
	// -------------------
	.tabs .nav-item {
		display: none;
		width: 100%;
		@include respond-to('mobile') { display: block; }
		
		&:not(:last-of-type) {
			box-shadow: inset 0 -1px 0 rgba($secondaryColor,.2);
		}
		
		&.-active + .tab {
			visibility: visible;
			height: auto;
			
			.tab-content {
				opacity: 1;
				transform: translate(0,0);
				transform: translate3d(0,0,0);
			}
			
			.image {
				opacity: 1;
				transform: translate(0,0);
				transform: translate3d(0,0,0);
				transition: .5s $easeOut .3s;
			}
		}
	}
}
