@charset "UTF-8";
/*
  Based on the Eric Meyer CSS Reset v2.0 (http://meyerweb.com/eric/tools/css/reset/)
*/
@import url("https://use.typekit.net/lju5mwr.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@keyframes appear {
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1); } }

@keyframes fadeIn {
  to {
    opacity: 1; } }

@keyframes load {
  to {
    transform: rotate(360deg); } }

body {
  color: #2D2A2B;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  line-height: 1.1; }

h1 {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.1;
  font-size: 50px;
  font-size: 3.125/pxrem;
  line-height: 1.17;
  color: #F79020; }
  @media (max-width: 1024px) {
    h1 {
      font-size: 41.25px;
      font-size: 2.57812/pxrem; } }
  @media (max-width: 736px) {
    h1 {
      font-size: 37.5px;
      font-size: 2.34375/pxrem; } }
  h1:not(:last-child) {
    margin-bottom: .4em; }

h2 {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.1;
  font-size: 30px;
  font-size: 1.875/pxrem;
  margin-bottom: 0.85em; }
  @media (max-width: 1024px) {
    h2 {
      font-size: 19.8px;
      font-size: 1.2375/pxrem; } }
  @media (max-width: 736px) {
    h2 {
      font-size: 18px;
      font-size: 1.125/pxrem; } }

h3 {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: 600;
  font-size: 20px;
  font-size: 1.25/pxrem;
  margin-bottom: .5em;
  margin-bottom: 0.85em; }
  @media (max-width: 1024px) {
    h3 {
      font-size: 22px;
      font-size: 1.375/pxrem; } }
  @media (max-width: 736px) {
    h3 {
      font-size: 20px;
      font-size: 1.25/pxrem; } }
  @media (max-width: 736px) {
    h3 {
      margin-bottom: 1rem; } }

h4 {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.1;
  font-size: 20px;
  font-size: 1.25/pxrem;
  margin-bottom: 0.85em;
  line-height: 1.25; }
  @media (max-width: 1024px) {
    h4 {
      font-size: 22px;
      font-size: 1.375/pxrem; } }
  @media (max-width: 736px) {
    h4 {
      font-size: 20px;
      font-size: 1.25/pxrem; } }

h5 {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.1;
  font-size: 14px;
  font-size: 0.875/pxrem;
  margin-bottom: 0.85em; }
  @media (max-width: 1024px) {
    h5 {
      font-size: 9.24px;
      font-size: 0.5775/pxrem; } }
  @media (max-width: 736px) {
    h5 {
      font-size: 8.4px;
      font-size: 0.525/pxrem; } }

h6 {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.1;
  font-size: 14px;
  font-size: 0.875/pxrem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.02em;
  margin-bottom: 0.85em; }
  @media (max-width: 1024px) {
    h6 {
      font-size: 9.24px;
      font-size: 0.5775/pxrem; } }
  @media (max-width: 736px) {
    h6 {
      font-size: 8.4px;
      font-size: 0.525/pxrem; } }
  @media (max-width: 736px) {
    h6 {
      font-size: 18px; } }

p {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-size: 19px;
  font-size: 1.1875/pxrem;
  line-height: 1.4;
  margin: 0 0 1em; }
  @media (max-width: 1024px) {
    p {
      font-size: 17px;
      font-size: 1.0625/pxrem; } }
  @media (max-width: 736px) {
    p {
      font-size: 16px;
      font-size: 1/pxrem; } }
  p a {
    color: #2D2A2B;
    transition: 0.1s cubic-bezier(0.5, 0, 0, 1);
    box-shadow: inset 0 -2px 0 rgba(247, 144, 32, 0.5); }
    p a:hover {
      box-shadow: inset 0 -3px 0 #F79020; }
    p a.button {
      box-shadow: none; }

a {
  color: inherit;
  text-decoration: none; }

abbr,
acronym {
  border-bottom: 1px dotted;
  cursor: help; }

address {
  font-size: 1rem;
  font-style: italic; }

blockquote {
  padding-left: 0;
  position: relative;
  text-align: center;
  margin: 2em 0;
  clear: both; }
  blockquote:before, blockquote:after {
    font-size: 1.3em; }
    @media (max-width: 1024px) {
      blockquote:before, blockquote:after {
        font-size: 1.15em; } }
    @media (max-width: 736px) {
      blockquote:before, blockquote:after {
        font-size: 1em; } }
  blockquote:before {
    font-family: "fontello";
    content: '\e91d';
    color: #F79020;
    left: -1.75em;
    top: 0;
    position: absolute; }
  blockquote:after {
    font-family: "fontello";
    content: '\e91c';
    color: #F79020;
    right: -1.5em;
    bottom: 0;
    position: absolute; }
    @media (max-width: 1024px) {
      blockquote:after {
        right: -1.75em; } }
    @media (max-width: 736px) {
      blockquote:after {
        right: -1.65em; } }
  blockquote p {
    font-size: 25px;
    font-size: 1.5625/pxrem;
    line-height: 1.2;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 0; }
    @media (max-width: 1024px) {
      blockquote p {
        font-size: 16.5px;
        font-size: 1.03125/pxrem; } }
    @media (max-width: 736px) {
      blockquote p {
        font-size: 15px;
        font-size: 0.9375/pxrem; } }

cite {
  font-style: italic; }

code {
  font-size: 0.96rem;
  font-family: monospace;
  background: #F4F4F4;
  border: 1px solid #e7e7e7;
  padding: 0.125em 0.25em;
  line-height: 150%; }

em,
i {
  font-style: italic; }

strong,
b {
  font-weight: bold; }

hr {
  outline: none;
  border: none;
  margin: 1em 0;
  padding: 0;
  border-top: 1px solid #F4F4F4; }

ol,
ul {
  margin: 1em 0;
  padding: 0 0 0 2em; }
  ol li,
  ul li {
    font-size: 1em;
    list-style-position: outside; }

ul li {
  list-style: none inside none; }

ol li {
  list-style: decimal; }

section .content ol,
section .content ul,
main .content ol,
main .content ul,
.section-split .split-main ol,
.section-split .split-main ul {
  padding-left: 1em;
  line-height: 1.6; }
  section .content ol li,
  section .content ul li,
  main .content ol li,
  main .content ul li,
  .section-split .split-main ol li,
  .section-split .split-main ul li {
    position: relative; }
    section .content ol li a,
    section .content ul li a,
    main .content ol li a,
    main .content ul li a,
    .section-split .split-main ol li a,
    .section-split .split-main ul li a {
      color: #2D2A2B;
      transition: 0.1s cubic-bezier(0.5, 0, 0, 1);
      box-shadow: inset 0 -2px 0 rgba(247, 144, 32, 0.5); }
      section .content ol li a:hover,
      section .content ul li a:hover,
      main .content ol li a:hover,
      main .content ul li a:hover,
      .section-split .split-main ol li a:hover,
      .section-split .split-main ul li a:hover {
        box-shadow: inset 0 -3px 0 #F79020; }

section .content ol,
main .content ol,
.section-split .split-main ol {
  margin: 1em 0;
  padding: 0 0 0 1.5em; }
  section .content ol li,
  main .content ol li,
  .section-split .split-main ol li {
    list-style: none;
    padding-left: 1em;
    font-weight: 400;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1.4;
    font-size: 19px;
    font-size: 1.1875/pxrem;
    line-height: 1.4; }
    @media (max-width: 1024px) {
      section .content ol li,
      main .content ol li,
      .section-split .split-main ol li {
        font-size: 17px;
        font-size: 1.0625/pxrem; } }
    @media (max-width: 736px) {
      section .content ol li,
      main .content ol li,
      .section-split .split-main ol li {
        font-size: 16px;
        font-size: 1/pxrem; } }
    section .content ol li:not(:last-of-type),
    main .content ol li:not(:last-of-type),
    .section-split .split-main ol li:not(:last-of-type) {
      margin-bottom: .25em; }
    section .content ol li:before,
    main .content ol li:before,
    .section-split .split-main ol li:before {
      margin-right: .7em;
      display: inline-block;
      color: #F79020;
      font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-weight: 600;
      position: absolute;
      left: 0;
      top: .05em; }

section .content ul > li,
main .content ul > li,
.section-split .split-main ul > li {
  padding-left: 1.25em;
  font-weight: 400;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-size: 19px;
  font-size: 1.1875/pxrem;
  line-height: 1.4; }
  @media (max-width: 1024px) {
    section .content ul > li,
    main .content ul > li,
    .section-split .split-main ul > li {
      font-size: 17px;
      font-size: 1.0625/pxrem; } }
  @media (max-width: 736px) {
    section .content ul > li,
    main .content ul > li,
    .section-split .split-main ul > li {
      font-size: 16px;
      font-size: 1/pxrem; } }
  section .content ul > li:not(:last-of-type),
  main .content ul > li:not(:last-of-type),
  .section-split .split-main ul > li:not(:last-of-type) {
    margin-bottom: .25em; }
  section .content ul > li:before,
  main .content ul > li:before,
  .section-split .split-main ul > li:before {
    content: '»';
    font-weight: bold;
    color: #F79020;
    padding-bottom: 6px;
    position: absolute;
    left: 0;
    vertical-align: baseline; }

section .content ul li > li:before,
section .content ul ul li:before,
main .content ul li > li:before,
main .content ul ul li:before,
.section-split .split-main ul li > li:before,
.section-split .split-main ul ul li:before {
  background: transparent; }

section .content ol > li,
main .content ol > li,
.section-split .split-main ol > li {
  counter-increment: steps; }
  section .content ol > li:before,
  main .content ol > li:before,
  .section-split .split-main ol > li:before {
    content: counter(steps); }

section .content ol li > li,
section .content ol ol li,
main .content ol li > li,
main .content ol ol li,
.section-split .split-main ol li > li,
.section-split .split-main ol ol li {
  counter-increment: more; }
  section .content ol li > li:before,
  section .content ol ol li:before,
  main .content ol li > li:before,
  main .content ol ol li:before,
  .section-split .split-main ol li > li:before,
  .section-split .split-main ol ol li:before {
    content: counter(more); }

form {
  margin: 1em 0; }

dl {
  margin: 1em 0; }
  dl dt {
    font-weight: bold; }
  dl dd {
    margin: 0 0 0.5em; }

table {
  width: 100%;
  margin: 1em 0;
  border: 1px solid #F4F4F4; }
  table:first-child {
    margin-top: 0; }
  table th {
    padding: 1em;
    border: 1px solid #F4F4F4;
    background: #fdfdfd;
    text-align: left;
    font-weight: bold; }
  table td {
    padding: 1em;
    border: 1px solid rgba(45, 42, 43, 0.1); }
    @media (max-width: 736px) {
      table td {
        width: 100% !important;
        height: auto !important;
        display: block; }
        table td:not(:last-of-type) {
          border-bottom: none; } }

@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello/fontello.eot?59575301");
  src: url("../fonts/fontello/fontello.eot?59575301#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello.woff?59575301") format("woff"), url("../fonts/fontello/fontello.ttf?59575301") format("truetype"), url("../fonts/fontello/fontello.svg?59575301#entypo") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'entypo';
    src: url('../font/entypo.svg?59575301#entypo') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-note:before {
  content: '\e800'; }

/* '' */
.icon-cancel-circled:before {
  content: '\e801'; }

/* '' */
.icon-cancel-squared:before {
  content: '\e802'; }

/* '' */
.icon-note-beamed:before {
  content: '\e803'; }

/* '' */
.icon-upload-cloud:before {
  content: '\e804'; }

/* '' */
.icon-music:before {
  content: '\e805'; }

/* '' */
.icon-plus:before {
  content: '\e806'; }

/* '' */
.icon-bookmark:before {
  content: '\e807'; }

/* '' */
.icon-chat:before {
  content: '\e808'; }

/* '' */
.icon-bell:before {
  content: '\e809'; }

/* '' */
.icon-light-down:before {
  content: '\e80a'; }

/* '' */
.icon-basket:before {
  content: '\e80b'; }

/* '' */
.icon-down:before {
  content: '\e80c'; }

/* '' */
.icon-left-dir:before {
  content: '\e80d'; }

/* '' */
.icon-left-circled:before {
  content: '\e80e'; }

/* '' */
.icon-search:before {
  content: '\e80f'; }

/* '' */
.icon-mail:before {
  content: '\e810'; }

/* '' */
.icon-heart:before {
  content: '\e811'; }

/* '' */
.icon-flashlight:before {
  content: '\e812'; }

/* '' */
.icon-heart-empty:before {
  content: '\e813'; }

/* '' */
.icon-star:before {
  content: '\e814'; }

/* '' */
.icon-star-empty:before {
  content: '\e815'; }

/* '' */
.icon-user:before {
  content: '\e816'; }

/* '' */
.icon-users:before {
  content: '\e817'; }

/* '' */
.icon-user-add:before {
  content: '\e818'; }

/* '' */
.icon-video:before {
  content: '\e819'; }

/* '' */
.icon-camera:before {
  content: '\e81a'; }

/* '' */
.icon-picture:before {
  content: '\e81b'; }

/* '' */
.icon-layout:before {
  content: '\e81c'; }

/* '' */
.icon-check:before {
  content: '\e81d'; }

/* '' */
.icon-tag:before {
  content: '\e81e'; }

/* '' */
.icon-cancel:before {
  content: '\e81f'; }

/* '' */
.icon-eye:before {
  content: '\e820'; }

/* '' */
.icon-lock-open:before {
  content: '\e821'; }

/* '' */
.icon-menu:before {
  content: '\e822'; }

/* '' */
.icon-lock:before {
  content: '\e823'; }

/* '' */
.icon-attach:before {
  content: '\e824'; }

/* '' */
.icon-link:before {
  content: '\e825'; }

/* '' */
.icon-home:before {
  content: '\e826'; }

/* '' */
.icon-back:before {
  content: '\e827'; }

/* '' */
.icon-info-circled:before {
  content: '\e828'; }

/* '' */
.icon-info:before {
  content: '\e829'; }

/* '' */
.icon-help-circled:before {
  content: '\e82a'; }

/* '' */
.icon-help:before {
  content: '\e82b'; }

/* '' */
.icon-minus-circled:before {
  content: '\e82c'; }

/* '' */
.icon-minus:before {
  content: '\e82d'; }

/* '' */
.icon-plus-squared:before {
  content: '\e82e'; }

/* '' */
.icon-plus-circled:before {
  content: '\e82f'; }

/* '' */
.icon-minus-squared:before {
  content: '\e830'; }

/* '' */
.icon-bookmarks:before {
  content: '\e831'; }

/* '' */
.icon-flag:before {
  content: '\e832'; }

/* '' */
.icon-thumbs-up:before {
  content: '\e833'; }

/* '' */
.icon-upload:before {
  content: '\e834'; }

/* '' */
.icon-thumbs-down:before {
  content: '\e835'; }

/* '' */
.icon-download:before {
  content: '\e836'; }

/* '' */
.icon-reply:before {
  content: '\e837'; }

/* '' */
.icon-reply-all:before {
  content: '\e838'; }

/* '' */
.icon-forward:before {
  content: '\e839'; }

/* '' */
.icon-quote:before {
  content: '\e83a'; }

/* '' */
.icon-code:before {
  content: '\e83b'; }

/* '' */
.icon-export:before {
  content: '\e83c'; }

/* '' */
.icon-pencil:before {
  content: '\e83d'; }

/* '' */
.icon-feather:before {
  content: '\e83e'; }

/* '' */
.icon-print:before {
  content: '\e83f'; }

/* '' */
.icon-retweet:before {
  content: '\e840'; }

/* '' */
.icon-keyboard:before {
  content: '\e841'; }

/* '' */
.icon-comment:before {
  content: '\e842'; }

/* '' */
.icon-book:before {
  content: '\e843'; }

/* '' */
.icon-book-open:before {
  content: '\e844'; }

/* '' */
.icon-newspaper:before {
  content: '\e845'; }

/* '' */
.icon-doc-text-inv:before {
  content: '\e846'; }

/* '' */
.icon-doc-text:before {
  content: '\e847'; }

/* '' */
.icon-doc-landscape:before {
  content: '\e848'; }

/* '' */
.icon-doc:before {
  content: '\e849'; }

/* '' */
.icon-trash:before {
  content: '\e84a'; }

/* '' */
.icon-cup:before {
  content: '\e84b'; }

/* '' */
.icon-compass:before {
  content: '\e84c'; }

/* '' */
.icon-direction:before {
  content: '\e84d'; }

/* '' */
.icon-map:before {
  content: '\e84e'; }

/* '' */
.icon-location:before {
  content: '\e84f'; }

/* '' */
.icon-address:before {
  content: '\e850'; }

/* '' */
.icon-vcard:before {
  content: '\e851'; }

/* '' */
.icon-alert:before {
  content: '\e852'; }

/* '' */
.icon-attention:before {
  content: '\e853'; }

/* '' */
.icon-folder:before {
  content: '\e854'; }

/* '' */
.icon-archive:before {
  content: '\e855'; }

/* '' */
.icon-box:before {
  content: '\e856'; }

/* '' */
.icon-rss:before {
  content: '\e857'; }

/* '' */
.icon-phone:before {
  content: '\e858'; }

/* '' */
.icon-cog:before {
  content: '\e859'; }

/* '' */
.icon-tools:before {
  content: '\e85a'; }

/* '' */
.icon-share:before {
  content: '\e85b'; }

/* '' */
.icon-shareable:before {
  content: '\e85c'; }

/* '' */
.icon-bag:before {
  content: '\e85d'; }

/* '' */
.icon-calendar:before {
  content: '\e85e'; }

/* '' */
.icon-login:before {
  content: '\e85f'; }

/* '' */
.icon-logout:before {
  content: '\e860'; }

/* '' */
.icon-mic:before {
  content: '\e861'; }

/* '' */
.icon-mute:before {
  content: '\e862'; }

/* '' */
.icon-docs:before {
  content: '\e863'; }

/* '' */
.icon-sound:before {
  content: '\e864'; }

/* '' */
.icon-volume:before {
  content: '\e865'; }

/* '' */
.icon-clock:before {
  content: '\e866'; }

/* '' */
.icon-hourglass:before {
  content: '\e867'; }

/* '' */
.icon-down-open-mini:before {
  content: '\e868'; }

/* '' */
.icon-up-open:before {
  content: '\e869'; }

/* '' */
.icon-right-open:before {
  content: '\e86a'; }

/* '' */
.icon-left-open:before {
  content: '\e86b'; }

/* '' */
.icon-down-open:before {
  content: '\e86c'; }

/* '' */
.icon-right-circled:before {
  content: '\e86d'; }

/* '' */
.icon-down-circled:before {
  content: '\e86e'; }

/* '' */
.icon-up-circled:before {
  content: '\e86f'; }

/* '' */
.icon-arrow-combo:before {
  content: '\e870'; }

/* '' */
.icon-window:before {
  content: '\e871'; }

/* '' */
.icon-publish:before {
  content: '\e872'; }

/* '' */
.icon-popup:before {
  content: '\e873'; }

/* '' */
.icon-resize-small:before {
  content: '\e874'; }

/* '' */
.icon-resize-full:before {
  content: '\e875'; }

/* '' */
.icon-block:before {
  content: '\e876'; }

/* '' */
.icon-adjust:before {
  content: '\e877'; }

/* '' */
.icon-light-up:before {
  content: '\e878'; }

/* '' */
.icon-lamp:before {
  content: '\e879'; }

/* '' */
.icon-left-open-mini:before {
  content: '\e87a'; }

/* '' */
.icon-right-open-mini:before {
  content: '\e87b'; }

/* '' */
.icon-up-open-mini:before {
  content: '\e87c'; }

/* '' */
.icon-down-open-big:before {
  content: '\e87d'; }

/* '' */
.icon-left-open-big:before {
  content: '\e87e'; }

/* '' */
.icon-right-open-big:before {
  content: '\e87f'; }

/* '' */
.icon-arrows-ccw:before {
  content: '\e880'; }

/* '' */
.icon-cw:before {
  content: '\e881'; }

/* '' */
.icon-ccw:before {
  content: '\e882'; }

/* '' */
.icon-up-open-big:before {
  content: '\e883'; }

/* '' */
.icon-level-down:before {
  content: '\e884'; }

/* '' */
.icon-up-thin:before {
  content: '\e885'; }

/* '' */
.icon-left-thin:before {
  content: '\e886'; }

/* '' */
.icon-progress-1:before {
  content: '\e887'; }

/* '' */
.icon-progress-2:before {
  content: '\e888'; }

/* '' */
.icon-right-thin:before {
  content: '\e889'; }

/* '' */
.icon-progress-3:before {
  content: '\e88a'; }

/* '' */
.icon-target:before {
  content: '\e88b'; }

/* '' */
.icon-palette:before {
  content: '\e88c'; }

/* '' */
.icon-list:before {
  content: '\e88d'; }

/* '' */
.icon-list-add:before {
  content: '\e88e'; }

/* '' */
.icon-signal:before {
  content: '\e88f'; }

/* '' */
.icon-level-up:before {
  content: '\e890'; }

/* '' */
.icon-left:before {
  content: '\e891'; }

/* '' */
.icon-shuffle:before {
  content: '\e892'; }

/* '' */
.icon-trophy:before {
  content: '\e893'; }

/* '' */
.icon-loop:before {
  content: '\e894'; }

/* '' */
.icon-right:before {
  content: '\e895'; }

/* '' */
.icon-battery:before {
  content: '\e896'; }

/* '' */
.icon-back-in-time:before {
  content: '\e897'; }

/* '' */
.icon-switch:before {
  content: '\e898'; }

/* '' */
.icon-up:before {
  content: '\e899'; }

/* '' */
.icon-down-dir:before {
  content: '\e89a'; }

/* '' */
.icon-play:before {
  content: '\e89b'; }

/* '' */
.icon-monitor:before {
  content: '\e89c'; }

/* '' */
.icon-mobile:before {
  content: '\e89d'; }

/* '' */
.icon-stop:before {
  content: '\e89e'; }

/* '' */
.icon-right-dir:before {
  content: '\e89f'; }

/* '' */
.icon-pause:before {
  content: '\e8a0'; }

/* '' */
.icon-network:before {
  content: '\e8a1'; }

/* '' */
.icon-cd:before {
  content: '\e8a2'; }

/* '' */
.icon-record:before {
  content: '\e8a3'; }

/* '' */
.icon-up-dir:before {
  content: '\e8a4'; }

/* '' */
.icon-down-bold:before {
  content: '\e8a5'; }

/* '' */
.icon-to-end:before {
  content: '\e8a6'; }

/* '' */
.icon-inbox:before {
  content: '\e8a7'; }

/* '' */
.icon-install:before {
  content: '\e8a8'; }

/* '' */
.icon-to-start:before {
  content: '\e8a9'; }

/* '' */
.icon-left-bold:before {
  content: '\e8aa'; }

/* '' */
.icon-right-bold:before {
  content: '\e8ab'; }

/* '' */
.icon-fast-forward:before {
  content: '\e8ac'; }

/* '' */
.icon-globe:before {
  content: '\e8ad'; }

/* '' */
.icon-cloud:before {
  content: '\e8ae'; }

/* '' */
.icon-fast-backward:before {
  content: '\e8af'; }

/* '' */
.icon-down-thin:before {
  content: '\e8b0'; }

/* '' */
.icon-progress-0:before {
  content: '\e8b1'; }

/* '' */
.icon-up-bold:before {
  content: '\e8b2'; }

/* '' */
.icon-cloud-thunder:before {
  content: '\e8b3'; }

/* '' */
.icon-chart-area:before {
  content: '\e8b4'; }

/* '' */
.icon-chart-bar:before {
  content: '\e8b5'; }

/* '' */
.icon-flow-tree:before {
  content: '\e8b6'; }

/* '' */
.icon-flow-line:before {
  content: '\e8b7'; }

/* '' */
.icon-vimeo:before {
  content: '\e8b8'; }

/* '' */
.icon-flickr-circled:before {
  content: '\e8b9'; }

/* '' */
.icon-lastfm:before {
  content: '\e8ba'; }

/* '' */
.icon-lastfm-circled:before {
  content: '\e8bb'; }

/* '' */
.icon-vkontakte:before {
  content: '\e8bc'; }

/* '' */
.icon-behance:before {
  content: '\e8bd'; }

/* '' */
.icon-mixi:before {
  content: '\e8be'; }

/* '' */
.icon-stumbleupon:before {
  content: '\e8bf'; }

/* '' */
.icon-stumbleupon-circled:before {
  content: '\e8c0'; }

/* '' */
.icon-flickr:before {
  content: '\e8c1'; }

/* '' */
.icon-github-circled:before {
  content: '\e8c2'; }

/* '' */
.icon-flow-cascade:before {
  content: '\e8c3'; }

/* '' */
.icon-flow-branch:before {
  content: '\e8c4'; }

/* '' */
.icon-chart-line:before {
  content: '\e8c5'; }

/* '' */
.icon-chart-pie:before {
  content: '\e8c6'; }

/* '' */
.icon-erase:before {
  content: '\e8c7'; }

/* '' */
.icon-key:before {
  content: '\e8c8'; }

/* '' */
.icon-github:before {
  content: '\e8c9'; }

/* '' */
.icon-dribbble-circled:before {
  content: '\e8ca'; }

/* '' */
.icon-picasa:before {
  content: '\e8cb'; }

/* '' */
.icon-dribbble:before {
  content: '\e8cc'; }

/* '' */
.icon-infinity:before {
  content: '\e8cd'; }

/* '' */
.icon-magnet:before {
  content: '\e8ce'; }

/* '' */
.icon-bucket:before {
  content: '\e8cf'; }

/* '' */
.icon-linkedin-circled:before {
  content: '\e8d0'; }

/* '' */
.icon-paypal:before {
  content: '\e8d1'; }

/* '' */
.icon-sina-weibo:before {
  content: '\e8d2'; }

/* '' */
.icon-linkedin:before {
  content: '\e8d3'; }

/* '' */
.icon-cc-zero:before {
  content: '\e8d4'; }

/* '' */
.icon-drive:before {
  content: '\e8d5'; }

/* '' */
.icon-brush:before {
  content: '\e8d6'; }

/* '' */
.icon-dot-3:before {
  content: '\e8d7'; }

/* '' */
.icon-database:before {
  content: '\e8d8'; }

/* '' */
.icon-cc-pd:before {
  content: '\e8d9'; }

/* '' */
.icon-tumblr-circled:before {
  content: '\e8da'; }

/* '' */
.icon-renren:before {
  content: '\e8db'; }

/* '' */
.icon-skype-circled:before {
  content: '\e8dc'; }

/* '' */
.icon-tumblr:before {
  content: '\e8dd'; }

/* '' */
.icon-cc-nd:before {
  content: '\e8de'; }

/* '' */
.icon-megaphone:before {
  content: '\e8df'; }

/* '' */
.icon-dot-2:before {
  content: '\e8e0'; }

/* '' */
.icon-dot:before {
  content: '\e8e1'; }

/* '' */
.icon-clipboard:before {
  content: '\e8e2'; }

/* '' */
.icon-cc-sa:before {
  content: '\e8e3'; }

/* '' */
.icon-pinterest-circled:before {
  content: '\e8e4'; }

/* '' */
.icon-cc-remix:before {
  content: '\e8e5'; }

/* '' */
.icon-thermometer:before {
  content: '\e8e6'; }

/* '' */
.icon-soundcloud:before {
  content: '\e8e7'; }

/* '' */
.icon-cc-share:before {
  content: '\e8e8'; }

/* '' */
.icon-google-circles:before {
  content: '\e8e9'; }

/* '' */
.icon-skype:before {
  content: '\e8ea'; }

/* '' */
.icon-flattr:before {
  content: '\e8eb'; }

/* '' */
.icon-cc-nc-jp:before {
  content: '\e8ec'; }

/* '' */
.icon-cc-nc-eu:before {
  content: '\e8ed'; }

/* '' */
.icon-cc-nc:before {
  content: '\e8ee'; }

/* '' */
.icon-cc-by:before {
  content: '\e8ef'; }

/* '' */
.icon-cc:before {
  content: '\e8f0'; }

/* '' */
.icon-traffic-cone:before {
  content: '\e8f1'; }

/* '' */
.icon-gauge:before {
  content: '\e8f2'; }

/* '' */
.icon-rocket:before {
  content: '\e8f3'; }

/* '' */
.icon-flow-parallel:before {
  content: '\e8f4'; }

/* '' */
.icon-vimeo-circled:before {
  content: '\e8f5'; }

/* '' */
.icon-twitter:before {
  content: '\e8f6'; }

/* '' */
.icon-twitter-circled:before {
  content: '\e8f7'; }

/* '' */
.icon-facebook:before {
  content: '\e8f8'; }

/* '' */
.icon-facebook-circled:before {
  content: '\e8f9'; }

/* '' */
.icon-facebook-squared:before {
  content: '\e8fa'; }

/* '' */
.icon-gplus:before {
  content: '\e8fb'; }

/* '' */
.icon-gplus-circled:before {
  content: '\e8fc'; }

/* '' */
.icon-pinterest:before {
  content: '\e8fd'; }

/* '' */
.icon-evernote:before {
  content: '\e8fe'; }

/* '' */
.icon-dropbox:before {
  content: '\e8ff'; }

/* '' */
.icon-instagram:before {
  content: '\e900'; }

/* '' */
.icon-qq:before {
  content: '\e901'; }

/* '' */
.icon-spotify-circled:before {
  content: '\e902'; }

/* '' */
.icon-spotify:before {
  content: '\e903'; }

/* '' */
.icon-rdio-circled:before {
  content: '\e904'; }

/* '' */
.icon-rdio:before {
  content: '\e905'; }

/* '' */
.icon-smashing:before {
  content: '\e906'; }

/* '' */
.icon-sweden:before {
  content: '\e907'; }

/* '' */
.icon-db-shape:before {
  content: '\e908'; }

/* '' */
.icon-logo-db:before {
  content: '\e909'; }

/* '' */
.icon-tape:before {
  content: '\e90a'; }

/* '' */
.icon-flash:before {
  content: '\e90b'; }

/* '' */
.icon-graduation-cap:before {
  content: '\e90c'; }

/* '' */
.icon-language:before {
  content: '\e90d'; }

/* '' */
.icon-flight:before {
  content: '\e90e'; }

/* '' */
.icon-moon:before {
  content: '\e90f'; }

/* '' */
.icon-paper-plane:before {
  content: '\e910'; }

/* '' */
.icon-ticket:before {
  content: '\e911'; }

/* '' */
.icon-leaf:before {
  content: '\e912'; }

/* '' */
.icon-lifebuoy:before {
  content: '\e913'; }

/* '' */
.icon-droplet:before {
  content: '\e914'; }

/* '' */
.icon-water:before {
  content: '\e915'; }

/* '' */
.icon-air:before {
  content: '\e916'; }

/* '' */
.icon-mouse:before {
  content: '\e917'; }

/* '' */
.icon-briefcase:before {
  content: '\e918'; }

/* '' */
.icon-credit-card:before {
  content: '\e919'; }

/* '' */
.icon-floppy:before {
  content: '\e91a'; }

/* '' */
.icon-suitcase:before {
  content: '\e91b'; }

/* '' */
.icon-quote-close:before {
  content: '\e91c'; }

/* '' */
.icon-quote-open:before {
  content: '\e91d'; }

/* '' */
* {
  box-sizing: border-box; }

html {
  position: relative; }

body {
  position: relative;
  width: 100%;
  max-width: 100%; }

img {
  max-width: 100%;
  height: auto; }

.container {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto; }
  .container:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

.error {
  color: #F79020; }

.success {
  color: #00AA00; }

.sub {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  letter-spacing: .05em;
  color: #2D2A2B; }

.split-main {
  display: block;
  float: left;
  width: 56.33333%;
  margin-left: 1%;
  margin-right: 1%; }
  @media (min-width: 1200px) {
    .split-main {
      display: block;
      float: left;
      width: 56.33333%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) {
    .split-main {
      display: block;
      float: left;
      width: 56.33333%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) {
    .split-main {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 400px) {
    .split-main {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }

.split-aside {
  display: block;
  float: left;
  width: 39.66667%;
  margin-left: 1%;
  margin-right: 1%; }
  @media (min-width: 1200px) {
    .split-aside {
      display: block;
      float: left;
      width: 39.66667%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) {
    .split-aside {
      display: block;
      float: left;
      width: 39.66667%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) {
    .split-aside {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 400px) {
    .split-aside {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }

.wysiwyg {
  padding: 1rem 0em; }

.hide {
  font-size: 0;
  color: rgba(255, 255, 255, 0);
  position: absolute;
  left: 100vw; }

.screen-reader-text {
  position: absolute;
  left: 150vw;
  font-size: 0;
  color: transparent; }

.-mobile {
  display: none; }
  @media (max-width: 736px) {
    .-mobile {
      display: block; } }

@media (max-width: 736px) {
  .hide-mobile {
    display: none !important; } }

.page-template-default.page header + .basic-section {
  padding: 10vh 0; }
  @media (max-width: 736px) {
    .page-template-default.page header + .basic-section {
      padding: 2rem; } }

body.admin-bar {
  margin-top: -32px; }
  @media (max-width: 1024px) {
    body.admin-bar {
      margin-top: -46px; } }

#wpadminbar:not(override) {
  position: absolute;
  top: -32px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  left: -0.5rem;
  right: -0.5rem;
  width: calc(100% + 1rem);
  overflow: hidden;
  margin-bottom: 1em; }
  .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

button,
.button,
[class^="button"],
input[type="button"],
input[type="submit"],
input[type="reset"] {
  padding: .65em 1.5em 0.55em 1.5em;
  font-size: 1rem;
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #2D2A2B;
  border: 2px #F79020 solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #F79020;
  will-change: background-color, color; }
  @media (max-width: 1024px) {
    button,
    .button,
    [class^="button"],
    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
      font-size: 1.2rem; } }
  button:hover, button:focus,
  .button:hover,
  .button:focus,
  [class^="button"]:hover,
  [class^="button"]:focus,
  input[type="button"]:hover,
  input[type="button"]:focus,
  input[type="submit"]:hover,
  input[type="submit"]:focus,
  input[type="reset"]:hover,
  input[type="reset"]:focus {
    background: #f58409;
    border-color: #dc7608;
    color: #131112; }
  button.disabled,
  .button.disabled,
  [class^="button"].disabled,
  input[type="button"].disabled,
  input[type="submit"].disabled,
  input[type="reset"].disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #F79020;
    background-color: transparent;
    color: rgba(247, 144, 32, 0.75); }
    button.disabled:before, button.disabled:after,
    .button.disabled:before,
    .button.disabled:after,
    [class^="button"].disabled:before,
    [class^="button"].disabled:after,
    input[type="button"].disabled:before,
    input[type="button"].disabled:after,
    input[type="submit"].disabled:before,
    input[type="submit"].disabled:after,
    input[type="reset"].disabled:before,
    input[type="reset"].disabled:after {
      content: none; }
  button:not(:last-child),
  .button:not(:last-child),
  [class^="button"]:not(:last-child),
  input[type="button"]:not(:last-child),
  input[type="submit"]:not(:last-child),
  input[type="reset"]:not(:last-child) {
    margin-bottom: 1em; }
  button:not(:last-of-type),
  .button:not(:last-of-type),
  [class^="button"]:not(:last-of-type),
  input[type="button"]:not(:last-of-type),
  input[type="submit"]:not(:last-of-type),
  input[type="reset"]:not(:last-of-type) {
    margin-right: .8em; }
    @media (max-width: 736px) {
      button:not(:last-of-type),
      .button:not(:last-of-type),
      [class^="button"]:not(:last-of-type),
      input[type="button"]:not(:last-of-type),
      input[type="submit"]:not(:last-of-type),
      input[type="reset"]:not(:last-of-type) {
        margin-right: 0; } }
  button.-small,
  .button.-small,
  [class^="button"].-small,
  input[type="button"].-small,
  input[type="submit"].-small,
  input[type="reset"].-small {
    font-size: .7rem; }
  button.-large, button.huge,
  .button.-large,
  .button.huge,
  [class^="button"].-large,
  [class^="button"].huge,
  input[type="button"].-large,
  input[type="button"].huge,
  input[type="submit"].-large,
  input[type="submit"].huge,
  input[type="reset"].-large,
  input[type="reset"].huge {
    font-size: 1.25rem; }

button.-primary,
.button.-primary,
input.button-primary,
.button.wc-forward.alt {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #2D2A2B;
  border: 2px #F79020 solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #F79020;
  will-change: background-color, color; }
  button.-primary:hover, button.-primary:focus,
  .button.-primary:hover,
  .button.-primary:focus,
  input.button-primary:hover,
  input.button-primary:focus,
  .button.wc-forward.alt:hover,
  .button.wc-forward.alt:focus {
    background: #f58409;
    border-color: #dc7608;
    color: #131112; }
  button.-primary.disabled,
  .button.-primary.disabled,
  input.button-primary.disabled,
  .button.wc-forward.alt.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #F79020;
    background-color: transparent;
    color: rgba(247, 144, 32, 0.75); }
    button.-primary.disabled:before, button.-primary.disabled:after,
    .button.-primary.disabled:before,
    .button.-primary.disabled:after,
    input.button-primary.disabled:before,
    input.button-primary.disabled:after,
    .button.wc-forward.alt.disabled:before,
    .button.wc-forward.alt.disabled:after {
      content: none; }

button.-secondary,
.button.-secondary,
input.button-secondary {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #2D2A2B;
  border: 2px #2D2A2B solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #2D2A2B;
  will-change: background-color, color; }
  button.-secondary:hover, button.-secondary:focus,
  .button.-secondary:hover,
  .button.-secondary:focus,
  input.button-secondary:hover,
  input.button-secondary:focus {
    background: #201e1e;
    border-color: #131112;
    color: #131112; }
  button.-secondary.disabled,
  .button.-secondary.disabled,
  input.button-secondary.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #2D2A2B;
    background-color: transparent;
    color: rgba(45, 42, 43, 0.75); }
    button.-secondary.disabled:before, button.-secondary.disabled:after,
    .button.-secondary.disabled:before,
    .button.-secondary.disabled:after,
    input.button-secondary.disabled:before,
    input.button-secondary.disabled:after {
      content: none; }

button.-inverse,
.button.-inverse,
input.button-inverse,
.button.wc-forward {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #2D2A2B;
  border: 2px white solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: white;
  will-change: background-color, color;
  color: #2D2A2B; }
  button.-inverse:hover, button.-inverse:focus,
  .button.-inverse:hover,
  .button.-inverse:focus,
  input.button-inverse:hover,
  input.button-inverse:focus,
  .button.wc-forward:hover,
  .button.wc-forward:focus {
    background: #f2f2f2;
    border-color: #e6e6e6;
    color: #131112; }
  button.-inverse.disabled,
  .button.-inverse.disabled,
  input.button-inverse.disabled,
  .button.wc-forward.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px white;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.75); }
    button.-inverse.disabled:before, button.-inverse.disabled:after,
    .button.-inverse.disabled:before,
    .button.-inverse.disabled:after,
    input.button-inverse.disabled:before,
    input.button-inverse.disabled:after,
    .button.wc-forward.disabled:before,
    .button.wc-forward.disabled:after {
      content: none; }
  button.-inverse:hover, button.-inverse:focus,
  .button.-inverse:hover,
  .button.-inverse:focus,
  input.button-inverse:hover,
  input.button-inverse:focus,
  .button.wc-forward:hover,
  .button.wc-forward:focus {
    color: #2D2A2B; }

button.-dark,
.button.-dark,
input.button-secondary {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #F4F4F4;
  border: 2px #2D2A2B solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #2D2A2B;
  will-change: background-color, color;
  color: #F79020; }
  button.-dark:hover, button.-dark:focus,
  .button.-dark:hover,
  .button.-dark:focus,
  input.button-secondary:hover,
  input.button-secondary:focus {
    background: #201e1e;
    border-color: #131112;
    color: #dbdbdb; }
  button.-dark.disabled,
  .button.-dark.disabled,
  input.button-secondary.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #2D2A2B;
    background-color: transparent;
    color: rgba(45, 42, 43, 0.75); }
    button.-dark.disabled:before, button.-dark.disabled:after,
    .button.-dark.disabled:before,
    .button.-dark.disabled:after,
    input.button-secondary.disabled:before,
    input.button-secondary.disabled:after {
      content: none; }
  button.-dark:hover, button.-dark:focus,
  .button.-dark:hover,
  .button.-dark:focus,
  input.button-secondary:hover,
  input.button-secondary:focus {
    color: white; }

button.-action,
.button.-action,
input.button-secondary {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #2D2A2B;
  border: 2px #F79020 solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #F79020;
  will-change: background-color, color; }
  button.-action:hover, button.-action:focus,
  .button.-action:hover,
  .button.-action:focus,
  input.button-secondary:hover,
  input.button-secondary:focus {
    background: #f58409;
    border-color: #dc7608;
    color: #131112; }
  button.-action.disabled,
  .button.-action.disabled,
  input.button-secondary.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #F79020;
    background-color: transparent;
    color: rgba(247, 144, 32, 0.75); }
    button.-action.disabled:before, button.-action.disabled:after,
    .button.-action.disabled:before,
    .button.-action.disabled:after,
    input.button-secondary.disabled:before,
    input.button-secondary.disabled:after {
      content: none; }

button.-action,
.button.-action {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: #2D2A2B;
  border: 2px #F79020 solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #F79020;
  will-change: background-color, color; }
  button.-action:hover, button.-action:focus,
  .button.-action:hover,
  .button.-action:focus {
    background: #f58409;
    border-color: #dc7608;
    color: #131112; }
  button.-action.disabled,
  .button.-action.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #F79020;
    background-color: transparent;
    color: rgba(247, 144, 32, 0.75); }
    button.-action.disabled:before, button.-action.disabled:after,
    .button.-action.disabled:before,
    .button.-action.disabled:after {
      content: none; }

button.facebook,
.button-facebook,
input.button-facebook {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: white;
  border: 2px #3B5998 solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #3B5998;
  will-change: background-color, color;
  padding-left: 0.6em; }
  button.facebook:hover, button.facebook:focus,
  .button-facebook:hover,
  .button-facebook:focus,
  input.button-facebook:hover,
  input.button-facebook:focus {
    background: #344e86;
    border-color: #2d4373;
    color: #e6e6e6; }
  button.facebook.disabled,
  .button-facebook.disabled,
  input.button-facebook.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #3B5998;
    background-color: transparent;
    color: rgba(59, 89, 152, 0.75); }
    button.facebook.disabled:before, button.facebook.disabled:after,
    .button-facebook.disabled:before,
    .button-facebook.disabled:after,
    input.button-facebook.disabled:before,
    input.button-facebook.disabled:after {
      content: none; }
  button.facebook.disabled,
  .button-facebook.disabled,
  input.button-facebook.disabled {
    background: #9099ae;
    color: white;
    cursor: default; }

button.twitter,
.button-twitter,
input.button-twitter {
  display: inline-block;
  border: none;
  appearance: none;
  outline: none;
  letter-spacing: 0.025em;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  position: relative;
  will-change: background-color, color;
  color: white;
  border: 2px #00ACED solid;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
  background: #00ACED;
  will-change: background-color, color; }
  button.twitter:hover, button.twitter:focus,
  .button-twitter:hover,
  .button-twitter:focus,
  input.button-twitter:hover,
  input.button-twitter:focus {
    background: #0099d4;
    border-color: #0087ba;
    color: #e6e6e6; }
  button.twitter.disabled,
  .button-twitter.disabled,
  input.button-twitter.disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 0 2px #00ACED;
    background-color: transparent;
    color: rgba(0, 172, 237, 0.75); }
    button.twitter.disabled:before, button.twitter.disabled:after,
    .button-twitter.disabled:before,
    .button-twitter.disabled:after,
    input.button-twitter.disabled:before,
    input.button-twitter.disabled:after {
      content: none; }
  button.twitter.disabled,
  .button-twitter.disabled,
  input.button-twitter.disabled {
    background-color: #aebcc7;
    color: white;
    cursor: default; }

.search-icon {
  appearance: none;
  width: 26px;
  height: 26px;
  position: relative;
  padding: 0;
  box-sizing: content-box;
  border: none;
  cursor: pointer;
  color: #2D2A2B;
  background: transparent; }
  .search-icon:hover, .search-icon:focus, .search-icon.-active {
    color: #2D2A2B; }
  .search-icon:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 17.16px;
    height: 17.16px;
    border-radius: 20em;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
    transform-origin: top left; }
  .search-icon span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .search-icon span:before, .search-icon span:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 3px;
      background: currentColor;
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1); }
    .search-icon span:before {
      transform: translate(0, -50%) rotate(45deg);
      transform: translate3d(20%, 150%, 0) rotate(45deg) scale3d(0.5, 1, 1); }
    .search-icon span:after {
      opacity: 0;
      transform: translate(0, -50%) rotate(-45deg) scale(0.4, 1);
      transform: translate3d(0, -50%, 0) rotate(-45deg) scale3d(0.4, 1, 1); }
  .search-icon.-active:before {
    opacity: 0;
    transform: scale(0.7, 0.8);
    transform: scale3d(0.7, 0.8, 1); }
  .search-icon.-active span:before {
    transform: translate(0, -50%) rotate(45deg);
    transform: translate3d(0, -50%, 0) rotate(45deg); }
  .search-icon.-active span:after {
    opacity: 1;
    transform: translate(0, -50%) rotate(-45deg);
    transform: translate3d(0, -50%, 0) rotate(-45deg); }

.-content .card-action:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0.75rem;
  left: 0.75rem;
  height: 2rem;
  z-index: 1;
  opacity: 1;
  background: linear-gradient(to top, currentColor, transparent); }
  @media (max-width: 736px) {
    .-content .card-action:before {
      right: 0.75rem;
      left: 0.75rem; } }

.-content .card-action .action-content {
  top: 0.75rem;
  transform: translate(0, 30vh);
  transform: translate3d(0, 30vh, 0);
  transition: 0.6s cubic-bezier(0.5, 0, 0, 1);
  height: 100%; }
  .-content .card-action .action-content:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: white;
    z-index: -1;
    transition: 0.6s cubic-bezier(0.5, 0, 0, 1); }
  @media (max-width: 1024px) {
    .-content .card-action .action-content {
      left: auto;
      right: auto; } }
  @media (max-width: 736px) {
    .-content .card-action .action-content {
      left: auto;
      right: auto;
      margin: 0 0.75rem;
      position: relative;
      transform: translate(0, calc(100% - 9rem - 1.5rem));
      transform: translate3d(0, calc(100% - 9rem - 1.5rem), 0); } }

.-content .card-action h4 {
  height: auto; }
  @media (max-width: 1024px) {
    .-content .card-action h4 {
      font-size: 18px;
      font-size: 1.125/pxrem; } }

@media (max-width: 736px) {
  .-content .card-action .card-content {
    opacity: 0; } }

.card-action {
  height: 40vw;
  min-height: 300px;
  max-height: 500px;
  background: #F4F4F4;
  position: relative;
  overflow: hidden;
  cursor: pointer; }
  .card-action .action-content {
    position: absolute;
    right: 0.75rem;
    bottom: 0;
    left: 0.75rem;
    padding: 0.75rem;
    transform: translate(0, 4em);
    transform: translate3d(0, 4em, 0);
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1); }
    @media (max-width: 1024px) {
      .card-action .action-content {
        width: 90%; } }
    @media (max-width: 736px) {
      .card-action .action-content {
        padding: 0.75rem;
        left: 0.75rem;
        padding: 0.75rem;
        transform: none; } }
    .card-action .action-content h4, .card-action .action-content p {
      position: relative; }
    .card-action .action-content p {
      font-size: 16px;
      font-size: 1/pxrem; }
    .card-action .action-content h4 {
      margin-bottom: .8rem; }
      @media (max-width: 736px) {
        .card-action .action-content h4 {
          height: auto; } }
    .card-action .action-content:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .96;
      transition: 0.6s cubic-bezier(0.5, 0, 0, 1);
      background: linear-gradient(40deg, currentColor 60%, transparent) no-repeat bottom/300% 100%; }
  .card-action .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .card-action .card-content {
    opacity: 0;
    height: 3em;
    transition: 0.6s cubic-bezier(0.5, 0, 0, 1);
    transform: translate(0, 0.5em);
    transform: translate3d(0, 0.5em, 0); }
    .card-action .card-content p:first-of-type {
      display: none; }
    @media (max-width: 736px) {
      .card-action .card-content {
        height: auto;
        transform: none;
        opacity: 1; } }
  .card-action:hover .action-content, .card-action:focus .action-content {
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0); }
    .card-action:hover .action-content:before, .card-action:focus .action-content:before {
      opacity: 1; }
      .-content .card-action:hover .action-content:before, .-content .card-action:focus .action-content:before {
        transform: scale(1.2, 1.2);
        transform: scale3d(1.2, 1.2, 1); }
    .card-action:hover .action-content:after, .card-action:focus .action-content:after {
      opacity: .3;
      transform: scale(1.2, 1.2);
      transform: scale3d(1.2, 1.2, 1); }
  .card-action:hover .card-content, .card-action:focus .card-content {
    opacity: 1;
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0); }
  .card-action.action-1 .action-content {
    width: calc(50% - 0.75rem); }
  .card-action.action-2 {
    width: 50%;
    float: left; }
    @media (max-width: 736px) {
      .card-action.action-2 {
        width: 100%;
        min-height: auto;
        height: 40vh; } }
  .card-action.action-3 {
    width: 33.333%;
    float: left; }
    @media (max-width: 736px) {
      .card-action.action-3 {
        width: 100%;
        min-height: auto;
        height: 35vh; } }
    .card-action.action-3 h4 {
      font-size: 24px;
      font-size: 1.5/pxrem;
      height: 3.5em; }
      @media (max-width: 736px) {
        .card-action.action-3 h4 {
          height: auto; } }
      .-content .card-action.action-3 h4 {
        font-size: 19px;
        font-size: 1.1875/pxrem;
        height: auto;
        max-height: 3.5em; }
  @media (max-width: 736px) {
    .-content .card-action {
      height: auto; } }
  .card-action.-default {
    color: white; }
    .card-action.-default .action-content:before, .card-action.-default:before {
      color: white; }
    .card-action.-default .action-content {
      color: #2D2A2B; }
  .card-action.-light {
    color: white; }
    .card-action.-light .action-content:before, .card-action.-light:before {
      color: white; }
    .card-action.-light .action-content {
      color: #2D2A2B; }
  .card-action.-dark {
    color: #2D2A2B; }
    .card-action.-dark .action-content:before, .card-action.-dark:before {
      color: #2D2A2B; }
    .card-action.-dark .action-content {
      color: #F79020; }
      .card-action.-dark .action-content:after {
        background: #2D2A2B; }
      .card-action.-dark .action-content p,
      .card-action.-dark .action-content .button,
      .card-action.-dark .action-content a {
        color: white; }
  .card-action.-primary {
    color: #F79020; }
    .card-action.-primary .action-content:before, .card-action.-primary:before {
      color: #F79020; }
    .card-action.-primary .action-content {
      color: #2D2A2B; }
      .card-action.-primary .action-content:after {
        background: #F79020; }
      .card-action.-primary .action-content .button {
        display: inline-block;
        border: none;
        appearance: none;
        outline: none;
        letter-spacing: 0.025em;
        line-height: 100%;
        vertical-align: middle;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        font-weight: bold;
        transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
        position: relative;
        will-change: background-color, color;
        color: #2D2A2B;
        border: 2px white solid;
        transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
        background: white;
        will-change: background-color, color; }
        .card-action.-primary .action-content .button:hover, .card-action.-primary .action-content .button:focus {
          background: #f2f2f2;
          border-color: #e6e6e6;
          color: #131112; }
        .card-action.-primary .action-content .button.disabled {
          cursor: not-allowed;
          box-shadow: inset 0 0 0 2px white;
          background-color: transparent;
          color: rgba(255, 255, 255, 0.75); }
          .card-action.-primary .action-content .button.disabled:before, .card-action.-primary .action-content .button.disabled:after {
            content: none; }

.card-featured {
  background: #F4F4F4;
  color: #2D2A2B; }
  .card-featured:after {
    content: "";
    display: table;
    clear: both; }
  .card-featured .feature-content {
    padding: 2rem 2rem 5rem 0;
    min-height: 15vw;
    width: 55%;
    position: relative;
    left: 45%; }
    @media (max-width: 736px) {
      .card-featured .feature-content {
        float: left;
        padding: 1rem 1rem calc(1rem + 4em);
        width: 100%;
        left: 0; } }
    .card-featured .feature-content h6 {
      font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-weight: 600;
      line-height: 1.1;
      font-size: 30px;
      font-size: 1.875/pxrem;
      margin-bottom: .25rem; }
      @media (max-width: 1024px) {
        .card-featured .feature-content h6 {
          font-size: 19.8px;
          font-size: 1.2375/pxrem; } }
      @media (max-width: 736px) {
        .card-featured .feature-content h6 {
          font-size: 18px;
          font-size: 1.125/pxrem; } }
    .card-featured .feature-content .meta {
      color: rgba(45, 42, 43, 0.6);
      text-transform: uppercase; }
  .card-featured .button {
    position: absolute;
    bottom: 2rem;
    left: calc(42% + 2rem);
    margin-bottom: 0;
    z-index: 1; }
    @media (max-width: 736px) {
      .card-featured .button {
        width: calc(100% - 2rem);
        left: 1rem;
        bottom: 1rem;
        text-align: center; } }
    .card-featured .button:hover ~ .image span, .card-featured .button:focus ~ .image span {
      transform: none; }
  .card-featured .image {
    position: absolute;
    top: -0.25rem;
    right: 0;
    bottom: -0.25rem;
    left: 0;
    width: 42%;
    overflow: hidden; }
    @media (max-width: 736px) {
      .card-featured .image {
        width: 100%;
        height: 30vh;
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto; } }
    .card-featured .image > span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1);
      transition: 3s; }
    .card-featured .image > iframe {
      width: 100%;
      height: auto; }
    .card-featured .image.-video {
      overflow: visible; }
      @media (max-width: 1024px) {
        .card-featured .image.-video {
          top: 0;
          left: 0; } }
      @media (max-width: 736px) {
        .card-featured .image.-video {
          height: 0;
          padding-bottom: 66%; } }

.card {
  position: relative;
  display: inline-block;
  text-align: center;
  float: left;
  background: #F4F4F4;
  color: #2D2A2B;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  outline: none; }
  .card > a {
    width: 100%;
    overflow: hidden;
    display: block;
    padding-bottom: 2em;
    background: white;
    box-shadow: 0 2px 5px rgba(45, 42, 43, 0.1); }
    @media (max-width: 736px) {
      .card > a {
        padding-bottom: 1rem; } }
    .card > a > .flex-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; }
  .card .date,
  .card .topic {
    display: block;
    width: 100%;
    padding: .75rem 1rem 0;
    margin-bottom: -1em;
    position: relative;
    z-index: 1;
    color: rgba(45, 42, 43, 0.6);
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
    text-align: left;
    font-size: .85rem; }
  .card .heading-container {
    flex-grow: 1; }
  .card .title {
    padding: 1.5rem 1rem .5rem 1rem;
    position: relative;
    z-index: 1;
    text-align: left;
    color: #2D2A2B;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
    font-weight: bold;
    will-change: color;
    margin: 0; }
  .card .excerpt {
    margin: 0;
    padding: 0 1rem 1rem;
    text-align: left;
    font-size: .85rem; }
  .card .caption {
    padding: .75em;
    background: rgba(247, 144, 32, 0.3);
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #2D2A2B;
    text-align: left;
    font-weight: 600;
    position: relative;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
    z-index: 1;
    margin: 0 1px; }
    .card .caption em {
      font-style: normal;
      font-weight: 400; }
  .card .image {
    display: block;
    width: 100%;
    padding-bottom: 66.5%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 120px; }
    .card .image:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: currentColor;
      opacity: .4;
      z-index: -1; }
    .card .image > span:not(.onsale) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: 2s cubic-bezier(0.5, 0, 0, 1);
      transform: scale(1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1); }
  .card .action {
    padding: 0 1rem;
    text-align: left; }
  .card .read-more {
    margin-top: auto;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    margin-right: 0;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    color: #F79020; }
    .card .read-more:after {
      content: "›";
      display: inline;
      margin: 0 0 0 .5em;
      font-size: 1.4em;
      position: relative;
      top: -.2em;
      line-height: 1em; }
    @media (max-width: 736px) {
      .card .read-more {
        margin-top: 2vh; } }
  .card .background {
    z-index: 0; }
    .card .background:before {
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1); }
    .card .background:after {
      background-position: 0%;
      opacity: 0;
      transition: 1s cubic-bezier(0.5, 0, 0, 1);
      will-change: background-position, opacity; }
  .card header.progress {
    opacity: 0;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
    padding: 1.25em; }
    .card header.progress .percent {
      display: none; }
    .card header.progress i {
      transform: translate(calc(-100% + 1em), 0);
      transform: translate3d(calc(-100% + 1em), 0, 0);
      animation: none;
      opacity: 0;
      transition: 0.2s cubic-bezier(0.5, 0, 0, 1), transform 0s 0.2s; }
    .card header.progress .progress > span {
      opacity: 0;
      transition: 0.2s cubic-bezier(0.5, 0, 0, 1);
      transform: translate(-0.4em, 0);
      transform: translate3d(-0.4em, 0, 0); }
    .card header.progress .milestone.-right {
      left: auto !important;
      right: .75em;
      transform-origin: right; }
    .card header.progress .milestone,
    .card header.progress .progress > span,
    .card header.progress .progress:before {
      animation: none;
      transition: 0s .4s; }
  @supports not (background: linear-gradient(0deg, white, white) no-repeat center/cover) {
    .card a:hover,
    .card a:focus {
      background: #F79020; } }
  .card a:hover h6,
  .card a:focus h6 {
    color: #2D2A2B; }
  .card a:hover .image > span,
  .card a:focus .image > span {
    transform: scale(1, 1);
    transform: scale3d(1, 1, 1); }
  .card a:hover .caption,
  .card a:focus .caption {
    background: rgba(45, 42, 43, 0.05);
    color: #2D2A2B; }
  .card a:hover .background:before,
  .card a:focus .background:before {
    opacity: 0;
    transition-delay: .3s; }
  .card a:hover .background:after,
  .card a:focus .background:after {
    opacity: 1;
    background-position: 0% 100%; }
  .card a:hover header.progress,
  .card a:focus header.progress {
    opacity: 1; }
    .card a:hover header.progress .progress:before,
    .card a:focus header.progress .progress:before {
      transform: none;
      transition: 1s cubic-bezier(0.5, 0, 0, 1); }
    .card a:hover header.progress .progress > span,
    .card a:focus header.progress .progress > span {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 1s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
    .card a:hover header.progress i,
    .card a:focus header.progress i {
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition: 0.6s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
    .card a:hover header.progress .milestone,
    .card a:focus header.progress .milestone {
      transform: none;
      opacity: 1;
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
  .card a:hover .button,
  .card a:focus .button {
    background: white; }
  .card .meta {
    position: absolute;
    bottom: 100%;
    margin-bottom: 1em;
    color: #2D2A2B;
    left: 0;
    right: 0; }
    .card .meta .sub {
      float: left;
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1); }
      .card .meta .sub:hover, .card .meta .sub:focus {
        box-shadow: inset 0 -0.15em 0 #F79020, 0 0.1em 0 #F79020; }
    .card .meta .-cat {
      float: right; }
    @media (max-width: 736px) {
      .card .meta {
        padding: 4em 4em 0; } }
  .card.-search .title {
    font-size: 1.4rem; }

.filter-dropdown {
  position: relative;
  z-index: 2;
  text-align: left;
  min-width: 15em;
  height: 3rem;
  float: right;
  flex: 0 0 27vw;
  align-self: flex-end;
  margin-left: auto;
  outline: none; }
  @supports not (display: flex) {
    .filter-dropdown {
      max-width: 27vw; } }
  @media (max-width: 736px) {
    .filter-dropdown {
      flex: auto;
      margin-left: 0;
      width: 100%; } }
  .filter-dropdown + .filter-dropdown {
    margin-left: 1em; }
    @media (max-width: 736px) {
      .filter-dropdown + .filter-dropdown {
        margin-top: 1em; } }
  .filter-dropdown ul,
  .filter-dropdown li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .filter-dropdown .active {
    padding: .9em;
    border: 2px solid #F79020;
    background: white;
    display: inline-block;
    width: 100%;
    outline: none;
    cursor: pointer;
    height: 3rem; }
  .filter-dropdown ul {
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    top: calc(100% - 2px);
    background: white;
    border: 2px solid #F79020;
    border-top-color: #fab56a;
    transition: .3s; }
    .filter-dropdown ul:hover {
      opacity: 1;
      pointer-events: all;
      z-index: 3; }
    .filter-dropdown ul li {
      cursor: pointer; }
      .filter-dropdown ul li:not(:last-of-type) {
        border-bottom: 2px solid #fab56a; }
      .filter-dropdown ul li a {
        padding: .75em 1em;
        display: block;
        transition: 0.3s cubic-bezier(0.5, 0, 0, 1);
        font-size: .8em;
        text-transform: uppercase;
        background: #fbc891; }
        .filter-dropdown ul li a.-filtered {
          background: #F79020;
          color: white;
          font-size: 1em;
          text-transform: none; }
          .filter-dropdown ul li a.-filtered:hover, .filter-dropdown ul li a.-filtered:focus {
            background: #e67c08; }
        .filter-dropdown ul li a:hover, .filter-dropdown ul li a:focus {
          background: #fab56a; }
      .filter-dropdown ul li ul {
        margin: 0;
        padding: 0;
        position: relative;
        border-left: none;
        border-right: none;
        border-bottom: none; }
        .filter-dropdown ul li ul li a {
          padding: .75em 1em;
          display: block;
          transition: 0.3s cubic-bezier(0.5, 0, 0, 1);
          font-size: 1em;
          text-transform: none;
          background: transparent; }
          .filter-dropdown ul li ul li a.-filtered {
            background: #fbc891; }
          .filter-dropdown ul li ul li a:hover, .filter-dropdown ul li ul li a:focus {
            background: #fab56a; }
  .filter-dropdown .active {
    position: relative;
    overflow: hidden; }
    .filter-dropdown .active.active:after {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      right: calc(1.4em - 6px);
      width: 8px;
      height: 8px;
      border-left: 2px solid #2D2A2B;
      border-bottom: 2px solid #2D2A2B;
      z-index: 3;
      transform: rotate(-45deg);
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1); }
  .filter-dropdown.-active {
    z-index: 3; }
    .filter-dropdown.-active .active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .filter-dropdown.-active .active:after {
        transform: translate(0, 30%) rotate(135deg);
        transform: translate3d(0, 30%, 0) rotate(135deg); }
    .filter-dropdown.-active ul {
      opacity: 1;
      pointer-events: all;
      z-index: 3;
      max-height: 20em;
      overflow-y: auto; }

form {
  display: block; }

fieldset {
  display: block;
  margin: 1.25em 0;
  padding: 0; }

legend {
  display: block;
  width: 100%;
  margin: 0 0 1em 0;
  font-weight: bold; }

label {
  display: block;
  margin: 0 0 0.25em;
  font-weight: bold; }

.wForm:not(.this) .oneField input,
textarea,
select,
.select2-selection,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="image"],
input[type="color"] {
  padding: .4em .65em .6em;
  margin: 0 1em .5em 0;
  vertical-align: middle;
  border: 2px solid currentColor;
  line-height: 1.4;
  font-weight: 400;
  font-family: inherit;
  font-size: 19px;
  font-size: 1.1875/pxrem;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #2D2A2B;
  background: white;
  width: 100%;
  max-width: 100%;
  display: block;
  transition: 0.3s cubic-bezier(0.5, 0, 0, 1); }
  @media (max-width: 736px) {
    .wForm:not(.this) .oneField input,
    textarea,
    select,
    .select2-selection,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="image"],
    input[type="color"] {
      font-size: 1em;
      padding: .5em 0 .5em .65em; } }
  .wForm:not(.this) .oneField input::placeholder,
  textarea::placeholder,
  select::placeholder,
  .select2-selection::placeholder,
  input[type="text"]::placeholder,
  input[type="password"]::placeholder,
  input[type="datetime"]::placeholder,
  input[type="datetime-local"]::placeholder,
  input[type="date"]::placeholder,
  input[type="month"]::placeholder,
  input[type="time"]::placeholder,
  input[type="week"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="url"]::placeholder,
  input[type="search"]::placeholder,
  input[type="tel"]::placeholder,
  input[type="image"]::placeholder,
  input[type="color"]::placeholder {
    color: rgba(45, 42, 43, 0.7); }
  .wForm:not(.this) .oneField input:focus,
  textarea:focus,
  select:focus,
  .select2-selection:focus,
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="time"]:focus,
  input[type="week"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  input[type="image"]:focus,
  input[type="color"]:focus {
    border-color: #F79020; }

input[type="submit"],
input[type="button"] {
  display: block;
  margin: 0 auto; }

input[type="file"] {
  padding: 0.125em;
  margin: 0 0 0.625em;
  font-family: inherit;
  font-size: 1rem;
  -webkit-appearance: none;
  border-radius: 0;
  line-height: 100%; }

.wForm:not(.this) .oneField input[type="radio"],
.wForm:not(.this) .oneField input[type="checkbox"],
input[type="radio"],
input[type="checkbox"] {
  background: rgba(247, 144, 32, 0.3);
  vertical-align: middle !important;
  display: inline-block;
  margin: 0 .75em 0 0;
  width: 25px;
  height: 25px;
  padding: 0 !important;
  display: inline-block;
  border: 0;
  border: 2px solid #2D2A2B;
  transition: 0.4s cubic-bezier(0.5, 0, 0, 1), background 0s !important;
  box-sizing: border-box;
  cursor: pointer; }
  .wForm:not(.this) .oneField input[type="radio"][type="radio"],
  .wForm:not(.this) .oneField input[type="checkbox"][type="radio"],
  input[type="radio"][type="radio"],
  input[type="checkbox"][type="radio"] {
    border-radius: 49%; }
  .wForm:not(.this) .oneField input[type="radio"][type="checkbox"],
  .wForm:not(.this) .oneField input[type="checkbox"][type="checkbox"],
  input[type="radio"][type="checkbox"],
  input[type="checkbox"][type="checkbox"] {
    border-radius: 2px; }
  .wForm:not(.this) .oneField input[type="radio"]:hover, .wForm:not(.this) .oneField input[type="radio"]:focus,
  .wForm:not(.this) .oneField input[type="checkbox"]:hover,
  .wForm:not(.this) .oneField input[type="checkbox"]:focus,
  input[type="radio"]:hover,
  input[type="radio"]:focus,
  input[type="checkbox"]:hover,
  input[type="checkbox"]:focus {
    background: #fbc182; }
  .woocommerce-invalid .wForm:not(.this) .oneField input[type="radio"], .woocommerce-invalid
  .wForm:not(.this) .oneField input[type="checkbox"], .woocommerce-invalid
  input[type="radio"], .woocommerce-invalid
  input[type="checkbox"] {
    border-color: #F79020; }

.wForm:not(.this) .oneField input[type="checkbox"],
.wForm:not(.this) .oneChoice input[type="checkbox"],
input[type="checkbox"] {
  background: white !important;
  float: left; }
  .wForm:not(.this) .oneField input[type="checkbox"]:checked,
  .wForm:not(.this) .oneChoice input[type="checkbox"]:checked,
  input[type="checkbox"]:checked {
    background: white url("/wp-content/themes/wire/assets/dist/img/tick.png") no-repeat center/60% !important; }
  .wForm:not(.this) .oneField input[type="checkbox"] + label,
  .wForm:not(.this) .oneChoice input[type="checkbox"] + label,
  input[type="checkbox"] + label {
    display: inline-block;
    float: left;
    max-width: calc(100% - 25px - 1em); }

.wForm:not(.this) .oneField input[type="radio"],
.wForm:not(.this) .oneChoice input[type="radio"],
input[type="radio"] {
  box-shadow: inset 0 0 0 .9em white; }
  .wForm:not(.this) .oneField input[type="radio"]:checked,
  .wForm:not(.this) .oneChoice input[type="radio"]:checked,
  input[type="radio"]:checked {
    box-shadow: inset 0 0 0 3px white;
    border: 2px solid #2d2a2b;
    background: #2D2A2B; }
  .wForm:not(.this) .oneField input[type="radio"] + label,
  .wForm:not(.this) .oneChoice input[type="radio"] + label,
  input[type="radio"] + label {
    display: inline-block;
    float: left;
    max-width: calc(100% - 25px - 1em); }

textarea {
  padding: 0.55em .7em; }

.select2-selection,
select {
  width: 100%;
  padding-right: 2em;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='#001F5C'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background: url("/wp-content/themes/wire/assets/dist/img/dropdown.png") no-repeat;
  background-size: 12px;
  background-position: calc(100% - .9em) 53%;
  background-repeat: no-repeat;
  background-color: white; }
  .select2-selection::-ms-expand,
  select::-ms-expand {
    display: none; }

.select2-container--default:not(.override) .select2-selection--single {
  color: #2D2A2B;
  height: auto;
  border: 2px solid #2D2A2B; }
  .select2-container--default:not(.override) .select2-selection--single .select2-selection__rendered {
    padding: 0;
    min-height: 1.66em; }
  .select2-container--default:not(.override) .select2-selection--single .select2-selection__arrow {
    display: none; }

.select2-container--below.select2-container--open .select2-selection {
  border-color: #F79020; }

.select2-container--open.select2-container--default:not(.override) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .select2-container--open.select2-container--default:not(.override) .select2-results__option {
    padding: .75em; }
    .select2-container--open.select2-container--default:not(.override) .select2-results__option:not(:last-of-type) {
      border-bottom: 1px solid rgba(45, 42, 43, 0.1); }
  .select2-container--open.select2-container--default:not(.override) .select2-dropdown {
    border: 2px solid #F79020;
    border-right-width: 1px;
    box-shadow: 1px 0 0 #F79020;
    border-top: none; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

.wp-block-gallery > .blocks-gallery-item {
  list-style: none inside none;
  padding: 0; }
  .wp-block-gallery > .blocks-gallery-item:before {
    display: none; }

.gform_wrapper {
  background: #F79020;
  color: white;
  padding: 2rem; }
  @media (max-width: 736px) {
    .gform_wrapper {
      padding: 1.5rem; } }
  .gform_wrapper form .gform_heading .gform_title {
    margin-top: 0; }
  .gform_wrapper .gform_fields .gfield {
    margin: 0 0 1rem;
    padding-left: 0; }
    .gform_wrapper .gform_fields .gfield ul.gfield_checkbox li input[type=checkbox] {
      width: 25px !important;
      height: 25px;
      margin-top: 0;
      border: 2px white solid; }
    .gform_wrapper .gform_fields .gfield ul.gfield_radio li label {
      float: none; }
    .gform_wrapper .gform_fields .gfield ul.gfield_radio li input[type=radio] {
      width: 25px !important;
      height: 25px;
      margin-top: 0; }
    .gform_wrapper .gform_fields .gfield textarea, .gform_wrapper .gform_fields .gfield select,
    .gform_wrapper .gform_fields .gfield input[type="text"], .gform_wrapper .gform_fields .gfield input[type="password"], .gform_wrapper .gform_fields .gfield input[type="datetime"], .gform_wrapper .gform_fields .gfield input[type="datetime-local"], .gform_wrapper .gform_fields .gfield input[type="date"], .gform_wrapper .gform_fields .gfield input[type="month"], .gform_wrapper .gform_fields .gfield input[type="time"], .gform_wrapper .gform_fields .gfield input[type="week"], .gform_wrapper .gform_fields .gfield input[type="number"], .gform_wrapper .gform_fields .gfield input[type="email"], .gform_wrapper .gform_fields .gfield input[type="url"], .gform_wrapper .gform_fields .gfield input[type="search"], .gform_wrapper .gform_fields .gfield input[type="tel"], .gform_wrapper .gform_fields .gfield input[type="image"], .gform_wrapper .gform_fields .gfield input[type="color"] {
      margin-bottom: .25rem;
      border: 2px white solid; }
    .gform_wrapper .gform_fields .gfield .ginput_container {
      margin-top: 0;
      margin-bottom: 1rem; }
    .gform_wrapper .gform_fields .gfield.field_description_below .ginput_container {
      margin-bottom: .5rem; }
    .gform_wrapper .gform_fields .gfield.field_description_below .gfield_description {
      padding: 0 0 1rem; }
    .gform_wrapper .gform_fields .gfield.half {
      float: left;
      width: 50%;
      clear: none; }
      .gform_wrapper .gform_fields .gfield.half input {
        width: 100%; }
    .gform_wrapper .gform_fields .gfield.third {
      float: left;
      width: 33.3%;
      clear: none; }
      .gform_wrapper .gform_fields .gfield.third input {
        width: 100%; }
  .gform_wrapper form .gform_footer {
    padding-bottom: 0;
    margin-bottom: 0; }
    .gform_wrapper form .gform_footer input[type=submit] {
      margin-bottom: 0;
      display: inline-block;
      border: none;
      appearance: none;
      outline: none;
      letter-spacing: 0.025em;
      line-height: 100%;
      vertical-align: middle;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-weight: bold;
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
      position: relative;
      will-change: background-color, color;
      color: white;
      border: 2px white solid;
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
      background: white;
      will-change: background-color, color; }
      .gform_wrapper form .gform_footer input[type=submit]:hover, .gform_wrapper form .gform_footer input[type=submit]:focus {
        background: #f2f2f2;
        border-color: #e6e6e6;
        color: #F79020; }
      .gform_wrapper form .gform_footer input[type=submit].disabled {
        cursor: not-allowed;
        box-shadow: inset 0 0 0 2px white;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.75); }
        .gform_wrapper form .gform_footer input[type=submit].disabled:before, .gform_wrapper form .gform_footer input[type=submit].disabled:after {
          content: none; }
      .gform_wrapper form .gform_footer input[type=submit]:last-of-type {
        margin-top: 0;
        margin-bottom: 0; }

#ui-datepicker-div {
  background: #FFF;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
  padding: 20px;
  width: 300px; }
  #ui-datepicker-div .ui-datepicker-title select {
    font-weight: bold;
    font-size: .7rem;
    width: auto;
    display: inline-block;
    margin: .1rem .25rem .25rem;
    border: none; }
  #ui-datepicker-div .ui-datepicker-today {
    background: #2D2A2B;
    color: white;
    border-radius: 0;
    border: none; }
    #ui-datepicker-div .ui-datepicker-today a {
      color: white;
      width: auto;
      height: auto;
      text-shadow: none; }
  #ui-datepicker-div .ui-state-active {
    border: none;
    background-color: #F79020;
    color: white; }
  #ui-datepicker-div table {
    width: 100%;
    margin: 0;
    border: none;
    display: table; }
    #ui-datepicker-div table td, #ui-datepicker-div table th {
      display: table-cell;
      text-align: center;
      border: none;
      width: auto !important;
      margin: 0; }
    #ui-datepicker-div table td a {
      display: block;
      margin: 0;
      width: auto;
      height: auto; }

.svg-icon {
  width: 100%;
  position: relative; }
  .svg-icon:before {
    display: block;
    content: "";
    width: 100%; }
  .svg-icon svg {
    position: absolute;
    top: 50%;
    left: 50%;
    right: -50%;
    height: 100%;
    bottom: -50%;
    transform: translateX(-50%) translateY(-50%);
    transform: translate3d(-50%, -50%, 0); }

.join-map {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden; }
  .join-map svg {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 150%;
    left: -25%; }
  .join-map #states a {
    fill: #a8a8a8;
    cursor: pointer; }
    .join-map #states a text {
      fill: #2D2A2B;
      font-weight: bold; }
    .join-map #states a:hover, .join-map #states a:focus {
      fill: #F79020; }

.-donate + .marquee,
.-appeal + .marquee {
  color: #5079AB;
  background: linear-gradient(83.48deg, #2D2A2B 0%, currentColor 100%) no-repeat 0/100% 100%; }
  .-donate + .marquee:before,
  .-appeal + .marquee:before {
    background: linear-gradient(to left, #5079AB, rgba(80, 121, 171, 0)); }

.marquee {
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin: 0;
  overflow: hidden;
  box-sizing: content-box;
  position: relative;
  color: #14417e;
  background: linear-gradient(83.48deg, #2D2A2B 0%, #14417e 100%) no-repeat 0/100% 100%; }
  .marquee:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 736px) {
    .marquee.-still {
      padding-left: 1rem;
      overflow-x: auto;
      box-sizing: border-box; }
      .marquee.-still:before {
        content: none; } }
  .marquee.-still ul:not(.-sub) {
    animation: none; }
  .marquee.-still .-sub {
    display: none; }
  .marquee:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4em;
    z-index: 1;
    background: linear-gradient(to left, currentColor, rgba(20, 65, 126, 0)); }
    @media (max-width: 736px) {
      .marquee:before {
        content: none; } }
  .marquee h4 {
    color: #F79020;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1.4;
    font-size: 16px;
    font-size: 1/pxrem;
    font-weight: 600;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin: 3em 4vw -2em; }
  .marquee ul {
    display: inline-block;
    margin: 0;
    padding: 3em 0;
    white-space: nowrap;
    box-sizing: content-box;
    color: #fff; }
    @media (max-width: 736px) {
      .marquee ul {
        padding: 5vh 0; } }
    .marquee ul:not(.-sub) {
      animation: ticker 40s linear infinite; }

@keyframes ticker {
  to {
    transform: translate(-100%, 0);
    transform: translate3d(-100%, 0, 0); } }
    .marquee ul ul {
      position: absolute;
      top: 0;
      left: 100%;
      animation: none; }
  .marquee li {
    list-style: none;
    display: inline-block;
    padding: 0 4vw; }
  .marquee .name {
    color: #F79020;
    font-weight: 600; }
  .marquee p {
    font-size: 20px;
    font-size: 1.25/pxrem;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin-bottom: 0.25em; }
    @media (max-width: 1024px) {
      .marquee p {
        font-size: 13.2px;
        font-size: 0.825/pxrem; } }
    @media (max-width: 736px) {
      .marquee p {
        font-size: 12px;
        font-size: 0.75/pxrem; } }
  .marquee small {
    font-size: 12px; }

ul[class*="menu-"] {
  margin: 0;
  padding: 0; }
  ul[class*="menu-"] li {
    position: relative;
    list-style: none; }
    ul[class*="menu-"] li a {
      display: inline-block; }

ul.menu-secondary li {
  display: block; }
  ul.menu-secondary li a {
    display: block; }

.onsale {
  font-weight: 600;
  position: absolute;
  top: 1.5em;
  right: 0;
  bottom: auto;
  left: auto;
  z-index: 3;
  background: #F79020;
  text-transform: uppercase;
  letter-spacing: .1em;
  padding: .5em .8em;
  font-size: 1.1em;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

.woocommerce-pagination,
.pagination {
  text-align: center;
  margin: 2rem 0; }
  .woocommerce-pagination ul,
  .pagination ul {
    display: block;
    text-align: center; }
    .woocommerce-pagination ul li,
    .pagination ul li {
      display: inline-block;
      vertical-align: middle; }
  .woocommerce-pagination li > *,
  .woocommerce-pagination .page-numbers:not(ul),
  .pagination li > *,
  .pagination .page-numbers:not(ul) {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    font-size: 1.25/pxrem;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #2D2A2B;
    font-weight: 600;
    width: 2.2em;
    height: 2.2em;
    padding: .6em;
    position: relative; }
    .woocommerce-pagination li > *:before,
    .woocommerce-pagination .page-numbers:not(ul):before,
    .pagination li > *:before,
    .pagination .page-numbers:not(ul):before {
      content: '';
      position: absolute;
      top: 15%;
      right: 15%;
      bottom: 15%;
      left: 15%;
      border-radius: 50%;
      box-shadow: 0 0 0 2px #F4F4F4;
      opacity: 0;
      transition: 0.4s cubic-bezier(0.5, 0, 0, 1); }
    .woocommerce-pagination li > *.current:before,
    .woocommerce-pagination .page-numbers:not(ul).current:before,
    .pagination li > *.current:before,
    .pagination .page-numbers:not(ul).current:before {
      opacity: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 0 0 2px #F79020; }
    .woocommerce-pagination li > *:hover:before, .woocommerce-pagination li > *:focus:before,
    .woocommerce-pagination .page-numbers:not(ul):hover:before,
    .woocommerce-pagination .page-numbers:not(ul):focus:before,
    .pagination li > *:hover:before,
    .pagination li > *:focus:before,
    .pagination .page-numbers:not(ul):hover:before,
    .pagination .page-numbers:not(ul):focus:before {
      opacity: 1; }
    .woocommerce-pagination li > *.prev, .woocommerce-pagination li > *.next,
    .woocommerce-pagination .page-numbers:not(ul).prev,
    .woocommerce-pagination .page-numbers:not(ul).next,
    .pagination li > *.prev,
    .pagination li > *.next,
    .pagination .page-numbers:not(ul).prev,
    .pagination .page-numbers:not(ul).next {
      width: 1.5em;
      height: 1.5em;
      margin: 0 .75em;
      color: transparent;
      border-radius: 50%; }
      .woocommerce-pagination li > *.prev:after, .woocommerce-pagination li > *.next:after,
      .woocommerce-pagination .page-numbers:not(ul).prev:after,
      .woocommerce-pagination .page-numbers:not(ul).next:after,
      .pagination li > *.prev:after,
      .pagination li > *.next:after,
      .pagination .page-numbers:not(ul).prev:after,
      .pagination .page-numbers:not(ul).next:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        opacity: 1;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
        border-top: 2px solid #2D2A2B;
        box-sizing: border-box; }
      .woocommerce-pagination li > *.prev.prev:after, .woocommerce-pagination li > *.next.prev:after,
      .woocommerce-pagination .page-numbers:not(ul).prev.prev:after,
      .woocommerce-pagination .page-numbers:not(ul).next.prev:after,
      .pagination li > *.prev.prev:after,
      .pagination li > *.next.prev:after,
      .pagination .page-numbers:not(ul).prev.prev:after,
      .pagination .page-numbers:not(ul).next.prev:after {
        border-left: 2px solid #2D2A2B;
        transform: translate3d(15%, 0, 0) rotate(-45deg); }
      .woocommerce-pagination li > *.prev.next:after, .woocommerce-pagination li > *.next.next:after,
      .woocommerce-pagination .page-numbers:not(ul).prev.next:after,
      .woocommerce-pagination .page-numbers:not(ul).next.next:after,
      .pagination li > *.prev.next:after,
      .pagination li > *.next.next:after,
      .pagination .page-numbers:not(ul).prev.next:after,
      .pagination .page-numbers:not(ul).next.next:after {
        transform: translate3d(-25%, 0, 0) rotate(45deg);
        border-right: 2px solid #2D2A2B; }
  .woocommerce-pagination a.page-numbers,
  .pagination a.page-numbers {
    width: 2em;
    height: 2em;
    padding: .6em;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: .9;
    transition: 0.3s cubic-bezier(0.5, 0, 0, 1);
    z-index: 1;
    border-radius: 50%; }

.post-pagination {
  display: block;
  float: left;
  width: 83.33333%;
  margin-left: 8.33333%;
  margin-right: 0;
  margin-top: -5vh;
  margin-bottom: 10vh;
  text-align: center; }
  @media (min-width: 1200px) {
    .post-pagination {
      display: block;
      float: left;
      width: 83.33333%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (max-width: 1024px) {
    .post-pagination {
      display: block;
      float: left;
      width: 83.33333%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (max-width: 736px) {
    .post-pagination {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  @media (max-width: 400px) {
    .post-pagination {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  .post-pagination .button:first-of-type:not(:last-of-type) {
    float: left; }
  .post-pagination .button:last-of-type:not(:first-of-type) {
    float: right; }

.card.-press a {
  background: white;
  box-shadow: 0 2px 5px rgba(45, 42, 43, 0.1); }

.card.-press .image {
  display: none; }

.card.-press .action {
  display: none; }

.card.-press .title {
  padding: .5rem;
  font-weight: bold; }

.card.-press .date {
  text-transform: none;
  letter-spacing: 0;
  padding: 0 .5rem; }

.feed-section .feed {
  margin-bottom: 2rem; }
  .feed-section .feed .card.-press,
  .feed-section .feed .card.has-meta.-press,
  .feed-section .feed .card.has-meta.-press ~ .card {
    width: calc(50% - 2.9vw);
    margin-top: 0;
    margin-bottom: 1rem; }
    @media (max-width: 736px) {
      .feed-section .feed .card.-press,
      .feed-section .feed .card.has-meta.-press,
      .feed-section .feed .card.has-meta.-press ~ .card {
        width: 100%; } }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  display: flex;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

[data-tooltip] {
  position: relative;
  height: 100%;
  cursor: pointer;
  display: inline-block; }
  [data-tooltip]:before, [data-tooltip]:after {
    transition: all .2s ease-out;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none; }
  [data-tooltip]:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #2D2A2B;
    content: '';
    top: 0;
    left: 50%;
    margin-left: -3.25px;
    z-index: 10001;
    bottom: -10px; }
  [data-tooltip]:after {
    box-sizing: border-box;
    content: attr(data-tooltip);
    padding: 0.5em 0.75em;
    font-size: 0.95em;
    z-index: 10000;
    left: 50%;
    bottom: 100%;
    margin-left: -7.5em;
    margin-top: 10px;
    width: 15em;
    line-height: 120%;
    background: #2D2A2B;
    color: #F4F4F4; }
  [data-tooltip]:hover:before, [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1; }

.feed-actions {
  position: relative;
  z-index: 2;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem; }
  .feed-actions:after {
    content: "";
    display: table;
    clear: both; }
  .feed-actions:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 1200px) {
    .feed-actions {
      max-width: 1200px; } }
  @media (max-width: 736px) {
    .feed-actions {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) and (min-width: 1200px) {
    .feed-actions {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) and (max-width: 1024px) {
    .feed-actions {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) and (max-width: 736px) {
    .feed-actions {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 736px) and (max-width: 400px) {
    .feed-actions {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  .feed-actions.-shop {
    margin-bottom: 0; }
  .feed-actions .search-form {
    width: calc(66% - 1.7vw); }
    @media (max-width: 1024px) {
      .feed-actions .search-form {
        width: calc(50% - 1.7vw); } }
    @media (max-width: 736px) {
      .feed-actions .search-form {
        width: 100%;
        margin-bottom: 5vw; } }
    .feed-actions .search-form .aws-container {
      overflow: hidden;
      position: relative;
      height: 3rem; }
      .feed-actions .search-form .aws-container:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1em;
        border: 2px solid #F79020;
        border-right-color: transparent;
        left: 0;
        z-index: 3;
        pointer-events: none; }
    .feed-actions .search-form form {
      margin: 0; }
      .feed-actions .search-form form:after {
        right: calc(-100% + 1em); }
    .feed-actions .search-form .search-submit {
      margin: 0; }
  .feed-actions .search-form {
    position: relative;
    overflow: hidden;
    float: left; }
    .feed-actions .search-form.active {
      box-shadow: inset -2px 0 0 #F79020; }
      .feed-actions .search-form.active:after {
        content: '';
        position: absolute;
        top: calc(50% - 6px);
        right: calc(1.4em - 6px);
        width: 8px;
        height: 8px;
        border-left: 2px solid #2D2A2B;
        border-bottom: 2px solid #2D2A2B;
        z-index: 3;
        transform: rotate(-45deg); }
  .feed-actions .search-form:before {
    right: 50%;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1) 0.2s;
    will-change: right; }
    @media (max-width: 1024px) {
      .feed-actions .search-form:before {
        right: 0; } }
  .feed-actions .search-form .search-icon {
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1) 0.2s;
    will-change: right;
    position: absolute;
    top: .75em;
    right: calc(50% + .5em);
    z-index: 3;
    transform: scale(0.8, 0.8);
    transform: scale3d(0.8, 0.8, 1); }
    @media (max-width: 1024px) {
      .feed-actions .search-form .search-icon {
        right: .5em; } }
  .feed-actions .search-form.-focus .search-icon {
    right: .5em; }
  .feed-actions .search-form.-focus:before {
    right: 0; }
  .feed-actions .aws-search-form {
    transform: translate(-50%, 0);
    transform: translate3d(-50%, 0, 0);
    height: 100%; }
    @media (max-width: 1024px) {
      .feed-actions .aws-search-form {
        transform: none; } }
  .feed-actions input[type="text"][name="s"] {
    border-color: #F79020;
    padding: .4em .65em .4em;
    background: white;
    line-height: normal;
    height: auto;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-size: 1.125/pxrem;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1) 0.2s;
    margin-bottom: 0; }
    .feed-actions input[type="text"][name="s"]::placeholder {
      color: rgba(45, 42, 43, 0.5);
      opacity: 1;
      padding-left: calc(50% + .8em);
      transition: 0.3s cubic-bezier(0.5, 0, 0, 1) 0.6s; }
      @media (max-width: 1024px) {
        .feed-actions input[type="text"][name="s"]::placeholder {
          padding-left: 0; } }
    .feed-actions input[type="text"][name="s"]:focus {
      transform: translate(50%, 0);
      transform: translate3d(50%, 0, 0);
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important; }
      @media (max-width: 1024px) {
        .feed-actions input[type="text"][name="s"]:focus {
          transform: none; } }
      .feed-actions input[type="text"][name="s"]:focus::placeholder {
        color: transparent;
        opacity: 1;
        transition: 0.2s cubic-bezier(0.5, 0, 0, 1); }

.aws-search-result:not(override) {
  border: 2px solid #F79020;
  margin-top: -3px;
  border-top-color: #fab56a; }
  .aws-search-result:not(override) .aws_result_sale {
    height: auto;
    width: auto; }
  .aws-search-result:not(override) .aws_result_item:hover,
  .aws-search-result:not(override) .aws_result_item.hovered {
    background: #fbcd9b; }
  .aws-search-result:not(override) .aws_result_link {
    padding: 1em; }
  .aws-search-result:not(override) .aws_result_title {
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #2D2A2B;
    font-size: 1.2em;
    margin-bottom: .5em; }
  .aws-search-result:not(override) .aws_onsale {
    transform: none;
    background: #F79020;
    color: #2D2A2B;
    font-weight: 600;
    height: auto;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    padding: .4em .6em;
    right: 0;
    top: 0; }

.feed-actions.-shop {
  margin-top: 2vh; }
  .feed-actions.-shop .filter-dropdown {
    margin-left: auto; }

footer {
  position: relative;
  float: left;
  width: 100%;
  background-color: #2D2A2B;
  color: #F4F4F4;
  padding: 4rem 0;
  z-index: 1;
  margin-top: 10rem; }
  footer:after {
    content: "";
    display: table;
    clear: both; }
  footer .brand {
    display: block;
    float: left;
    width: 23%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2rem; }
    @media (min-width: 1200px) {
      footer .brand {
        display: block;
        float: left;
        width: 23%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      footer .brand {
        display: block;
        float: left;
        width: 23%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      footer .brand {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      footer .brand {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    footer .brand img {
      width: 100%;
      border-radius: 0;
      max-width: 12rem; }
  footer .copyright {
    display: block;
    float: left;
    width: 73%;
    margin-left: 1%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      footer .copyright {
        display: block;
        float: left;
        width: 73%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      footer .copyright {
        display: block;
        float: left;
        width: 73%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      footer .copyright {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      footer .copyright {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    footer .copyright p {
      font-size: 1rem; }
      footer .copyright p a {
        color: white; }
  footer .footer-navigation {
    display: block;
    float: left;
    width: 73%;
    margin-left: 1%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      footer .footer-navigation {
        display: block;
        float: left;
        width: 73%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      footer .footer-navigation {
        display: block;
        float: left;
        width: 73%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      footer .footer-navigation {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      footer .footer-navigation {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
  footer ul.menu-footer {
    width: 100%;
    padding: 0 0 1rem 0; }
    footer ul.menu-footer:after {
      content: "";
      display: table;
      clear: both; }
    footer ul.menu-footer li {
      display: block;
      float: left;
      width: 23%;
      margin-left: 1%;
      margin-right: 1%;
      font-weight: bold; }
      @media (min-width: 1200px) {
        footer ul.menu-footer li {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        footer ul.menu-footer li {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        footer ul.menu-footer li {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        footer ul.menu-footer li {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      footer ul.menu-footer li a {
        display: block;
        padding: 0.5rem 0;
        color: #F79020;
        text-transform: uppercase; }
      footer ul.menu-footer li ul.sub-menu {
        margin: 0;
        padding: 0; }
        footer ul.menu-footer li ul.sub-menu li {
          display: block;
          width: auto;
          float: none;
          font-weight: normal;
          margin: 0;
          padding: 0; }
          footer ul.menu-footer li ul.sub-menu li a {
            color: white;
            text-transform: none; }
      footer ul.menu-footer li.extended {
        display: block;
        float: left;
        width: 31.33333%;
        margin-left: 1%;
        margin-right: 1%; }
        @media (min-width: 1200px) {
          footer ul.menu-footer li.extended {
            display: block;
            float: left;
            width: 31.33333%;
            margin-left: 1%;
            margin-right: 1%; } }
        @media (max-width: 1024px) {
          footer ul.menu-footer li.extended {
            display: block;
            float: left;
            width: 31.33333%;
            margin-left: 1%;
            margin-right: 1%; } }
        @media (max-width: 736px) {
          footer ul.menu-footer li.extended {
            display: block;
            float: left;
            width: 90%;
            margin-left: 5%;
            margin-right: 5%; } }
        @media (max-width: 400px) {
          footer ul.menu-footer li.extended {
            display: block;
            float: left;
            width: 90%;
            margin-left: 5%;
            margin-right: 5%; } }
        footer ul.menu-footer li.extended ul.sub-menu li {
          width: 50%;
          float: left; }
  footer .actions {
    position: relative; }
    footer .actions .button:before, footer .actions .button:after {
      color: white; }
    footer .actions .button i {
      margin-right: 0.5em; }
    footer .actions .button:first-of-type {
      color: white; }
    footer .actions .social {
      display: block;
      float: left;
      width: 23%;
      margin-left: 1%;
      margin-right: 1%;
      box-shadow: none;
      font-size: 1.5rem; }
      @media (min-width: 1200px) {
        footer .actions .social {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        footer .actions .social {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        footer .actions .social {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        footer .actions .social {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 736px) {
        footer .actions .social {
          padding-top: 0;
          box-shadow: none; } }
      footer .actions .social .social-button {
        line-height: 1; }
    @media (max-width: 736px) {
      footer .actions {
        text-align: center; }
        footer .actions .button,
        footer .actions .social {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0; } }
  footer .copyright {
    display: block;
    float: left;
    width: 64.66667%;
    margin-left: 1%;
    margin-right: 1%;
    color: #a8a8a8; }
    @media (min-width: 1200px) {
      footer .copyright {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      footer .copyright {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      footer .copyright {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      footer .copyright {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 736px) {
      footer .copyright {
        padding: 2rem 0; } }
    footer .copyright span {
      display: inline-block;
      position: relative;
      margin-right: .1em; }
  footer:before {
    content: "";
    display: block;
    position: absolute;
    top: -5rem;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 15rem;
    background: #2D2A2B;
    border-top: #F79020 10px solid;
    -ms-transform: skewY(-5deg);
    z-index: -1;
    transform: skewY(-5deg); }
    @media (min-width: 1200px) {
      footer:before {
        -ms-transform: skewY(-3deg);
        transform: skewY(-3deg); } }
    @media (max-width: 736px) {
      footer:before {
        height: 10rem;
        bottom: -8rem; } }

article.grid-examples {
  display: block;
  float: left;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%; }
  @media (min-width: 1200px) {
    article.grid-examples {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) {
    article.grid-examples {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) {
    article.grid-examples {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 400px) {
    article.grid-examples {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  article.grid-examples .container {
    text-align: center;
    background: #F4F4F4; }
    article.grid-examples .container code {
      display: block;
      font-size: 0.94rem;
      background: none;
      border: none; }
    article.grid-examples .container > code {
      padding: 20px; }
  article.grid-examples .box {
    position: relative;
    padding: 10px;
    height: 120px;
    margin-bottom: 2%;
    background: #e7e7e7;
    border: 1px solid #e5e5e5; }
    @media (max-width: 736px) {
      article.grid-examples .box {
        height: auto; } }
    article.grid-examples .box.-b1 {
      display: block;
      float: left;
      width: 23%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b1 {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b1 {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b1 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b1 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b2 {
      display: block;
      float: left;
      width: 31.33333%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b2 {
          display: block;
          float: left;
          width: 31.33333%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b2 {
          display: block;
          float: left;
          width: 31.33333%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b2 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b2 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b3 {
      display: block;
      float: left;
      width: 39.66667%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b3 {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b3 {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b3 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b3 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b4 {
      display: block;
      float: left;
      width: 39.66667%;
      margin-left: 17.66667%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b4 {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 17.66667%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b4 {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 17.66667%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b4 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b4 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b5 {
      display: block;
      float: left;
      width: 39.66667%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b5 {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b5 {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b5 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b5 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b6 {
      display: block;
      float: left;
      width: 73%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b6 {
          display: block;
          float: left;
          width: 73%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b6 {
          display: block;
          float: left;
          width: 73%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b6 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b6 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      article.grid-examples .box.-b6 code {
        padding-bottom: 10px; }
      article.grid-examples .box.-b6 .-b6-1, article.grid-examples .box.-b6 .-b6-2 {
        display: block;
        float: left;
        width: 48%;
        margin-left: 1%;
        margin-right: 1%;
        height: 50px;
        background: #dddddd;
        border: 1px solid #dbdbdb; }
        @media (min-width: 1200px) {
          article.grid-examples .box.-b6 .-b6-1, article.grid-examples .box.-b6 .-b6-2 {
            display: block;
            float: left;
            width: 48%;
            margin-left: 1%;
            margin-right: 1%; } }
        @media (max-width: 1024px) {
          article.grid-examples .box.-b6 .-b6-1, article.grid-examples .box.-b6 .-b6-2 {
            display: block;
            float: left;
            width: 48%;
            margin-left: 1%;
            margin-right: 1%; } }
        @media (max-width: 736px) {
          article.grid-examples .box.-b6 .-b6-1, article.grid-examples .box.-b6 .-b6-2 {
            display: block;
            float: left;
            width: 90%;
            margin-left: 5%;
            margin-right: 5%; } }
        @media (max-width: 400px) {
          article.grid-examples .box.-b6 .-b6-1, article.grid-examples .box.-b6 .-b6-2 {
            display: block;
            float: left;
            width: 90%;
            margin-left: 5%;
            margin-right: 5%; } }
    article.grid-examples .box.-b7 {
      display: block;
      float: left;
      width: 23%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b7 {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b7 {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b7 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b7 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b8 {
      display: block;
      float: left;
      width: 55%;
      margin-left: 10%;
      margin-right: 10%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b8 {
          display: block;
          float: left;
          width: 55%;
          margin-left: 10%;
          margin-right: 10%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b8 {
          display: block;
          float: left;
          width: 55%;
          margin-left: 10%;
          margin-right: 10%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b8 {
          display: block;
          float: left;
          width: 80%;
          margin-left: 10%;
          margin-right: 10%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b8 {
          display: block;
          float: left;
          width: 80%;
          margin-left: 10%;
          margin-right: 10%; } }
    article.grid-examples .box.-b9 {
      display: block;
      float: left;
      width: 23%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b9 {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b9 {
          display: block;
          float: left;
          width: 23%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b9 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b9 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b10, article.grid-examples .box.-b11 {
      display: block;
      float: left;
      width: 48%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b10, article.grid-examples .box.-b11 {
          display: block;
          float: left;
          width: 48%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b10, article.grid-examples .box.-b11 {
          display: block;
          float: left;
          width: 48%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b10, article.grid-examples .box.-b11 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b10, article.grid-examples .box.-b11 {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b10, article.grid-examples .box.-b11 {
          display: block;
          float: left;
          width: 40%;
          margin-left: 5%;
          margin-right: 5%; } }
    article.grid-examples .box.-b12 {
      display: block;
      float: left;
      width: 41.66667%;
      margin-left: 0%;
      margin-right: 0; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b12 {
          display: block;
          float: left;
          width: 41.66667%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b12 {
          display: block;
          float: left;
          width: 41.66667%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b12 {
          display: block;
          float: left;
          width: 100%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b12 {
          display: block;
          float: left;
          width: 100%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b12 {
          display: block;
          float: left;
          width: 40%;
          margin-left: 0%;
          margin-right: 0; } }
    article.grid-examples .box.-b13 {
      display: block;
      float: left;
      width: 58.33333%;
      margin-left: 0%;
      margin-right: 0; }
      @media (min-width: 1200px) {
        article.grid-examples .box.-b13 {
          display: block;
          float: left;
          width: 58.33333%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 1024px) {
        article.grid-examples .box.-b13 {
          display: block;
          float: left;
          width: 58.33333%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b13 {
          display: block;
          float: left;
          width: 100%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 400px) {
        article.grid-examples .box.-b13 {
          display: block;
          float: left;
          width: 100%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 736px) {
        article.grid-examples .box.-b13 {
          display: block;
          float: left;
          width: 60%;
          margin-left: 0%;
          margin-right: 0; } }
    @media (max-width: 736px) {
      article.grid-examples .box.-tall {
        height: 200px; } }
    @media (max-width: 736px) {
      article.grid-examples .box.-tall code {
        font-size: 0.8rem; } }

.header-search {
  border: 2px #2D2A2B solid;
  padding: 1rem 1rem 1rem 3.5rem;
  position: relative; }
  .header-search i {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    font-size: 1.5rem; }
  .header-search input {
    border: none;
    margin: 0; }

.header-search-more a {
  font-weight: bold;
  color: #F79020; }

.header-search-none,
.header-search-loading {
  margin: 1rem 0;
  color: #F79020;
  font-weight: bold; }

.feed-section .feed.header-search-results {
  margin: 0 0 1rem; }

header {
  position: relative;
  color: #2D2A2B;
  padding: 22rem 0 10rem; }
  header:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 736px) {
    header {
      padding: 18rem 0 5rem; } }
  header.-imageless {
    clip: none; }
  header .content {
    position: relative;
    z-index: 1;
    display: block;
    float: left;
    width: 56.33333%;
    margin-left: 1%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      header .content {
        display: block;
        float: left;
        width: 56.33333%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      header .content {
        display: block;
        float: left;
        width: 56.33333%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      header .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      header .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    header .content .header-metas {
      display: block;
      margin: 0 0 .5em; }
      header .content .header-metas .header-meta {
        display: inline-block;
        background: white;
        padding: .2em .5em;
        border-radius: .2em;
        margin: 0 .5em .5em 0;
        font-size: 1rem;
        font-weight: bold; }
    header .content .header-title {
      display: inline-block;
      opacity: 0;
      animation: appear 1s forwards 1s;
      color: #F79020;
      margin-bottom: 0.5rem;
      color: #2D2A2B;
      font-weight: bold;
      line-height: 1.35; }
      header .content .header-title span {
        display: inline;
        background: #F79020;
        color: #2D2A2B;
        padding: 0.25rem 0.5rem;
        /* Needs prefixing */
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone; }
      header .content .header-title.-long {
        font-size: 35px;
        font-size: 2.1875/pxrem; }
    header .content .header-action {
      padding: .25em; }
  header:after {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    bottom: -15rem;
    left: 0;
    width: 100%;
    height: 20rem;
    background: linear-gradient(#dbdbdb, white);
    -ms-transform: skewY(-5deg);
    transform: skewY(-5deg); }
    @media (min-width: 1200px) {
      header:after {
        -ms-transform: skewY(-3deg);
        transform: skewY(-3deg); } }
    @media (max-width: 736px) {
      header:after {
        height: 10rem;
        bottom: -8rem; } }

.header-introduction {
  position: relative; }
  .header-introduction .content {
    display: block;
    float: left;
    width: 56.33333%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 4rem 0 0; }
    @media (min-width: 1200px) {
      .header-introduction .content {
        display: block;
        float: left;
        width: 56.33333%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .header-introduction .content {
        display: block;
        float: left;
        width: 56.33333%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .header-introduction .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .header-introduction .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 736px) {
      .header-introduction .content {
        padding: 2rem 0 0; } }
    .header-introduction .content p {
      font-size: 1.3rem;
      line-height: 1.4;
      max-width: 48rem; }
  .header-introduction .aside {
    display: block;
    float: left;
    width: 39.66667%;
    margin-left: 1%;
    margin-right: 1%;
    background: #F4F4F4;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    position: relative;
    top: -9rem;
    margin-bottom: -7rem; }
    @media (min-width: 1200px) {
      .header-introduction .aside {
        display: block;
        float: left;
        width: 39.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .header-introduction .aside {
        display: block;
        float: left;
        width: 39.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .header-introduction .aside {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .header-introduction .aside {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 736px) {
      .header-introduction .aside {
        top: auto;
        margin-bottom: 2rem; } }

.wp-caption {
  position: relative;
  overflow: hidden;
  margin: 2em 0 0;
  max-width: 100%; }
  .wp-caption img {
    max-width: 100%;
    height: auto;
    width: 100%; }
  .wp-caption-text {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    padding: 1em 0;
    color: #2D2A2B;
    line-height: 1.18; }
    .wp-caption-text:before {
      content: "";
      position: absolute;
      width: 4em;
      height: 4em;
      right: 0;
      top: -4em;
      background: white; }

.alignnone {
  width: 100%;
  height: auto; }
  .alignnone img {
    width: 100%; }

.alignright,
.alignleft {
  margin-top: 1em; }

.alignright {
  float: right;
  margin-left: 0.75rem;
  margin-right: -0.75rem; }

.alignleft {
  float: left;
  margin-right: 0.75rem;
  margin-left: -0.75rem; }

.aligncenter {
  display: block;
  margin: 0 auto; }

.single .basic-section {
  text-align: left;
  color: #000; }
  .single .basic-section .meta {
    display: inline-block;
    color: #2D2A2B;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
    text-align: left;
    font-size: .85em;
    margin-bottom: 1em;
    vertical-align: middle; }
    .single .basic-section .meta.-date {
      color: rgba(45, 42, 43, 0.6); }
    .single .basic-section .meta + .meta:before {
      content: '';
      width: 4px;
      height: 4px;
      margin: 0 1vw 0 .5vw;
      display: inline-block;
      position: relative;
      border-radius: 50%;
      border: 2px solid #F79020;
      opacity: 0.25; }
  .single .basic-section h1, .single .basic-section h2, .single .basic-section h3, .single .basic-section h4, .single .basic-section h5 {
    text-align: center;
    margin-bottom: 1em; }
  .single .basic-section h1, .single .basic-section h2, .single .basic-section h3, .single .basic-section h4, .single .basic-section h5, .single .basic-section blockquote {
    color: #2D2A2B; }

.single p > img.alignnone {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  max-width: calc(100% + 1.5rem);
  width: calc(100% + 1.5rem); }

.single img {
  max-width: 100%;
  height: auto;
  border-radius: 0; }

.section.related-content .introduction {
  text-align: center; }

@media (max-width: 1024px) {
  body {
    padding-top: 3rem; } }

.primary-navigation {
  width: 100%;
  position: absolute;
  z-index: 9;
  height: 4rem;
  margin: 2rem 0; }
  @media (max-width: 1024px) {
    .primary-navigation {
      background: white;
      margin: 0;
      position: fixed;
      z-index: 10;
      height: 3.5rem;
      border-bottom: 1px #F4F4F4 solid;
      transition: height 500ms cubic-bezier(0.5, 0, 0, 1);
      top: 0;
      width: 100%;
      padding: 0; } }
  .primary-navigation .container {
    position: relative; }
  .primary-navigation .content {
    background: #F4F4F4;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 4rem;
    padding-left: 10rem;
    position: relative;
    z-index: 9; }
    @media (max-width: 1024px) {
      .primary-navigation .content {
        padding-left: 0;
        width: 100%; } }
  .primary-navigation .logo {
    display: block;
    float: left;
    position: absolute;
    top: -1rem;
    left: 0rem;
    background: #F4F4F4;
    width: 11rem;
    height: 6rem;
    background-size: auto 5rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2); }
    .primary-navigation .logo.-circular {
      top: -1.5rem;
      left: -1rem;
      background: #F4F4F4;
      width: 8rem;
      height: 8rem;
      background-size: auto 7rem;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 5rem;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2); }
      @media (max-width: 1024px) {
        .primary-navigation .logo.-circular {
          top: 0;
          left: 0;
          width: 7rem;
          height: 7rem;
          background-size: auto 6rem; } }
    @media (max-width: 1024px) {
      .primary-navigation .logo {
        left: 0;
        top: 0;
        width: 8rem;
        height: 4rem;
        background-size: auto 4rem;
        background-color: transparent;
        box-shadow: none; } }
    .primary-navigation .logo span {
      display: none; }
  .primary-navigation .menu {
    display: block;
    float: left;
    width: 64.66667%;
    margin-left: 1%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .primary-navigation .menu {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .primary-navigation .menu {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .primary-navigation .menu {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .primary-navigation .menu {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 1024px) {
      .primary-navigation .menu {
        width: 100%;
        margin: 0;
        max-height: 0px;
        overflow: hidden;
        display: block;
        position: fixed;
        transform: none;
        top: 0;
        left: 0;
        z-index: 5; } }
    @media (max-width: 1024px) {
      .primary-navigation .menu.-active {
        display: block;
        max-height: 15000px; }
        .primary-navigation .menu.-active .social {
          pointer-events: all;
          opacity: 1; }
        .primary-navigation .menu.-active div:last-of-type {
          pointer-events: all;
          max-height: 15000px; }
          .primary-navigation .menu.-active div:last-of-type:before {
            opacity: 1;
            transition-delay: .2s; } }
    .primary-navigation .menu.-active + .mobile-nav-action {
      width: calc(45px + 4em);
      overflow: hidden;
      opacity: 0;
      transform: translate(0, 0);
      transform: translate3d(45%, 0, 0); }
    @media (max-width: 1024px) {
      .primary-navigation .menu.-active ul[class*="-header"] > li {
        opacity: 1;
        transform: translate(0, 0);
        transform: translate3d(0, 0, 0);
        transition: 0.6s cubic-bezier(0.5, 0, 0, 1); }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(1) {
          transition-delay: 0.05s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(2) {
          transition-delay: 0.1s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(3) {
          transition-delay: 0.15s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(4) {
          transition-delay: 0.2s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(5) {
          transition-delay: 0.25s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(6) {
          transition-delay: 0.3s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(7) {
          transition-delay: 0.35s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(8) {
          transition-delay: 0.4s; }
        .primary-navigation .menu.-active ul[class*="-header"] > li:nth-of-type(9) {
          transition-delay: 0.45s; } }
    .primary-navigation .menu ul[class*="-header"] {
      width: 100%;
      padding: 0 0.6rem;
      justify-content: space-between;
      text-align: left; }
      @media (max-width: 1024px) {
        .primary-navigation .menu ul[class*="-header"] {
          padding: 0;
          flex-direction: column-reverse;
          border-radius: 0 0 1em 1em;
          overflow: hidden; } }
      .primary-navigation .menu ul[class*="-header"] > li {
        display: inline-block;
        font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        font-size: .9rem;
        font-weight: bold;
        margin: 0;
        height: 2.5rem; }
        @media (max-width: 1024px) {
          .primary-navigation .menu ul[class*="-header"] > li {
            height: auto; } }
        .primary-navigation .menu ul[class*="-header"] > li > a {
          padding: 1.35rem 0.5rem;
          line-height: 1.35;
          display: block;
          position: relative;
          text-transform: uppercase; }
          .primary-navigation .menu ul[class*="-header"] > li > a:hover, .primary-navigation .menu ul[class*="-header"] > li > a:focus {
            border-bottom: 2px #F79020 solid; }
            @media (max-width: 1024px) {
              .primary-navigation .menu ul[class*="-header"] > li > a:hover, .primary-navigation .menu ul[class*="-header"] > li > a:focus {
                border-bottom: none; } }
          @media (max-width: 1024px) {
            .primary-navigation .menu ul[class*="-header"] > li > a {
              padding: 1rem 0; } }
        .primary-navigation .menu ul[class*="-header"] > li.button {
          font-size: 18px;
          font-size: 1.125/pxrem;
          min-width: 8em;
          text-align: center;
          margin: -.1rem 0 0 .5em;
          padding: 0;
          flex-grow: 1;
          transition: none; }
          .primary-navigation .menu ul[class*="-header"] > li.button + .button {
            margin-left: 1vw; }
            @media (max-width: 1024px) {
              .primary-navigation .menu ul[class*="-header"] > li.button + .button {
                margin-left: 0; } }
          .primary-navigation .menu ul[class*="-header"] > li.button:hover, .primary-navigation .menu ul[class*="-header"] > li.button:focus {
            background: transparent; }
          @media (max-width: 1024px) {
            .primary-navigation .menu ul[class*="-header"] > li.button {
              border-radius: 0;
              box-shadow: none;
              text-align: left;
              text-transform: uppercase;
              padding: 0 1rem;
              font-size: 18px;
              font-size: 1.125/pxrem; }
              .primary-navigation .menu ul[class*="-header"] > li.button .submenu-toggle {
                top: 1rem; } }
          .primary-navigation .menu ul[class*="-header"] > li.button.-action {
            border: none; }
          .primary-navigation .menu ul[class*="-header"] > li.button > a {
            padding: .65em 1.5em 0.65em 1.5em;
            display: inline-block;
            border: none;
            appearance: none;
            outline: none;
            letter-spacing: 0.025em;
            line-height: 100%;
            vertical-align: middle;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            text-transform: uppercase;
            font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
            font-weight: bold;
            transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
            position: relative;
            will-change: background-color, color;
            color: #F79020;
            border: 2px #F79020 solid;
            transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
            background: #F79020;
            will-change: background-color, color; }
            .primary-navigation .menu ul[class*="-header"] > li.button > a:hover, .primary-navigation .menu ul[class*="-header"] > li.button > a:focus {
              background: #f58409;
              border-color: #dc7608;
              color: #dc7608; }
            .primary-navigation .menu ul[class*="-header"] > li.button > a.disabled {
              cursor: not-allowed;
              box-shadow: inset 0 0 0 2px #F79020;
              background-color: transparent;
              color: rgba(247, 144, 32, 0.75); }
              .primary-navigation .menu ul[class*="-header"] > li.button > a.disabled:before, .primary-navigation .menu ul[class*="-header"] > li.button > a.disabled:after {
                content: none; }
            .primary-navigation .menu ul[class*="-header"] > li.button > a i {
              margin-right: 0.5em; }
              .primary-navigation .menu ul[class*="-header"] > li.button > a i.icon-turtle {
                margin-right: .75em;
                margin-left: -.25em; }
            @media (max-width: 1024px) {
              .primary-navigation .menu ul[class*="-header"] > li.button > a {
                padding: 1rem 0; } }
        .primary-navigation .menu ul[class*="-header"] > li.current_page_item a:before,
        .primary-navigation .menu ul[class*="-header"] > li:not(.button) a:hover:before,
        .primary-navigation .menu ul[class*="-header"] > li:not(.button) a:focus:before {
          opacity: .5; }
        .primary-navigation .menu ul[class*="-header"] > li:hover ul, .primary-navigation .menu ul[class*="-header"] > li:focus ul {
          height: auto;
          max-height: 100vh;
          padding: 1.5rem 0 .5em; }
          @media (max-width: 1024px) {
            .primary-navigation .menu ul[class*="-header"] > li:hover ul, .primary-navigation .menu ul[class*="-header"] > li:focus ul {
              padding: 0; } }
          .primary-navigation .menu ul[class*="-header"] > li:hover ul li, .primary-navigation .menu ul[class*="-header"] > li:focus ul li {
            opacity: 1; }
      .primary-navigation .menu ul[class*="-header"] > li > ul {
        position: absolute;
        z-index: 1;
        width: 300px;
        left: 0;
        color: #F4F4F4;
        border-radius: 0;
        padding: 0;
        margin: 0;
        text-align: left;
        top: 4rem;
        background: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        height: 0;
        max-height: 0;
        transition: 200ms; }
        @media (max-width: 1024px) {
          .primary-navigation .menu ul[class*="-header"] > li > ul {
            position: relative;
            width: 100%;
            left: auto;
            -ms-transform: none;
            transform: none;
            padding: 0;
            display: block;
            top: auto;
            text-align: center;
            height: auto;
            max-height: 100%;
            overflow: visible;
            box-shadow: none;
            background: transparent; } }
        .primary-navigation .menu ul[class*="-header"] > li > ul:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          top: 1em;
          transform-origin: top;
          background: currentColor;
          border-radius: 0;
          z-index: -1;
          opacity: 0;
          transition: 0.4s cubic-bezier(0.5, 0, 0, 1);
          transform: scale(0.5, 0.5);
          transform: scale3d(0.5, 0.5, 1); }
        .primary-navigation .menu ul[class*="-header"] > li > ul:before {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 0;
          top: calc(1em + -6.95652px);
          left: calc(50% - 8px);
          background: currentColor;
          opacity: 0;
          transition: 0.3s cubic-bezier(0.5, 0, 0, 1);
          transform: scale(0.4, 0.4) rotate(45deg);
          transform: scale3d(0.4, 0.4, 1) rotate(45deg); }
        .primary-navigation .menu ul[class*="-header"] > li > ul:hover li a {
          opacity: .6; }
        .primary-navigation .menu ul[class*="-header"] > li > ul li {
          display: block;
          padding: .5em 1em;
          opacity: 0;
          transform: translate(0, -0.25em);
          transform: translate3d(0, -0.25em, 0);
          transition: 0.3s cubic-bezier(0.5, 0, 0, 1); }
          @media (max-width: 1024px) {
            .primary-navigation .menu ul[class*="-header"] > li > ul li {
              opacity: 1;
              transform: none;
              padding: .5em .5em; } }
          .primary-navigation .menu ul[class*="-header"] > li > ul li a {
            font-weight: 500;
            display: block;
            color: #2D2A2B;
            transition: 0.3s cubic-bezier(0.5, 0, 0, 1); }
            @media (max-width: 1024px) {
              .primary-navigation .menu ul[class*="-header"] > li > ul li a {
                font-weight: 400; } }
            .primary-navigation .menu ul[class*="-header"] > li > ul li a:hover {
              opacity: 1; }
          .primary-navigation .menu ul[class*="-header"] > li > ul li ul {
            display: none; }
      .primary-navigation .menu ul[class*="-header"] > li.-open ul {
        transition: 0s;
        pointer-events: all;
        transform: none;
        max-height: 10000px; }
        @media (max-width: 1024px) {
          .primary-navigation .menu ul[class*="-header"] > li.-open ul {
            padding-bottom: 1rem;
            display: block; } }
        .primary-navigation .menu ul[class*="-header"] > li.-open ul li {
          opacity: 1;
          transform: translate(0, 0);
          transform: translate3d(0, 0, 0);
          transition: 0.6s cubic-bezier(0.5, 0, 0, 1); }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(1) {
            transition-delay: 0.05s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(2) {
            transition-delay: 0.1s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(3) {
            transition-delay: 0.15s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(4) {
            transition-delay: 0.2s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(5) {
            transition-delay: 0.25s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(6) {
            transition-delay: 0.3s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(7) {
            transition-delay: 0.35s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(8) {
            transition-delay: 0.4s; }
          .primary-navigation .menu ul[class*="-header"] > li.-open ul li:nth-of-type(9) {
            transition-delay: 0.45s; }
      @media (max-width: 1024px) {
        .primary-navigation .menu ul[class*="-header"] a {
          font-size: 18px; } }
  .primary-navigation .actions {
    padding: .75rem 1rem .75rem .75rem;
    text-align: right; }
    .primary-navigation .actions .search {
      width: 7em;
      margin: 0 1rem;
      display: inline-block;
      position: relative; }
      @media (min-width: 1200px) {
        .primary-navigation .actions .search {
          width: 12em; } }
      .primary-navigation .actions .search .submit {
        position: absolute;
        top: .6rem;
        left: .5rem;
        padding: 0;
        background: none;
        border: none; }
      .primary-navigation .actions .search input {
        border: none;
        padding: .5rem .5rem .5rem 2rem;
        font-size: 1rem; }
    @media (max-width: 1024px) {
      .primary-navigation .actions {
        display: none;
        color: #2D2A2B;
        text-align: center; }
        .primary-navigation .actions .search {
          opacity: 1;
          margin: 1rem 0 0;
          width: 20rem;
          position: relative; }
          .primary-navigation .actions .search .submit {
            margin: 0;
            left: 0; }
          .primary-navigation .actions .search input {
            border: 1px #a8a8a8 solid; }
        .primary-navigation .actions .button {
          display: block;
          clear: both;
          margin: 1em auto;
          max-width: 20rem; } }

button.ham {
  width: 100%;
  max-width: 52px;
  height: 3.5rem;
  min-width: auto !important;
  display: block;
  margin: 0;
  padding: 1.4rem 0;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1.5rem;
  z-index: 20;
  background: transparent;
  display: none; }
  button.ham:focus, button.ham:active {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent; }
  @media (max-width: 1024px) {
    button.ham {
      display: block; } }
  button.ham span {
    width: 1.8rem;
    height: 2px;
    position: absolute;
    left: 0;
    left: 15px;
    right: 0;
    top: calc(50% - (5px * 0.5 / 2)); }
    button.ham span:nth-of-type(1):before, button.ham span:nth-of-type(1):after, button.ham span:nth-of-type(3):before, button.ham span:nth-of-type(3):after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #2D2A2B; }
    button.ham span:nth-of-type(1):before, button.ham span:nth-of-type(3):before {
      transition: transform 0.4s cubic-bezier(0.5, 0, 0, 1) 0.4s; }
    button.ham span:nth-of-type(1):after, button.ham span:nth-of-type(3):after {
      transition: transform 0.32s cubic-bezier(0.5, 0, 0, 1) 0.4s; }
    button.ham span:nth-of-type(1) {
      transition: transform 0.44s cubic-bezier(0.5, 0, 0, 1); }
      button.ham span:nth-of-type(1):before, button.ham span:nth-of-type(1):after {
        transform: translate3d(0, -350%, 0); }
    button.ham span:nth-of-type(2) {
      background: #2D2A2B;
      transition: opacity 0s 0.4s; }
    button.ham span:nth-of-type(3) {
      transition: transform 0.4s cubic-bezier(0.5, 0, 0, 1); }
      button.ham span:nth-of-type(3):before, button.ham span:nth-of-type(3):after {
        transform: translate3d(0, 350%, 0); }
  button.ham.-active span:nth-of-type(1) {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0, 1);
    transform: rotate(45deg) translate3d(0, 0, 0); }
    button.ham.-active span:nth-of-type(1):after, button.ham.-active span:nth-of-type(1):before {
      transform: translate3d(0, 0, 0); }
  button.ham.-active span:nth-of-type(2) {
    opacity: 0; }
  button.ham.-active span:nth-of-type(1),
  button.ham.-active span:nth-of-type(3) {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0, 1) 0.4s; }
    button.ham.-active span:nth-of-type(1):before,
    button.ham.-active span:nth-of-type(3):before {
      transition: transform 0.4s cubic-bezier(0.8, 0, 0.5, 1); }
    button.ham.-active span:nth-of-type(1):after,
    button.ham.-active span:nth-of-type(3):after {
      transition: transform 0.32s cubic-bezier(0.8, 0, 0.5, 1); }
  button.ham.-active span:nth-of-type(3) {
    transition: transform 0.48s cubic-bezier(0.5, 0, 0, 1) 0.44s;
    transform: rotate(-45deg) translate3d(0, 0, 0); }
    button.ham.-active span:nth-of-type(3):after, button.ham.-active span:nth-of-type(3):before {
      transform: translate3d(0, 0, 0); }
  button.ham.-active ~ .menu-primary-navigation-container {
    left: 0;
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1), left 0s; }
    button.ham.-active ~ .menu-primary-navigation-container .menu-primary {
      transform: translate3d(0, 0, 0);
      transition: transform 0.8s cubic-bezier(0.5, 0, 0, 1); }
  @media (max-width: 400px) {
    button.ham.active span:nth-of-type(1) {
      transform: rotate(45deg) scale3d(0.8, 1, 1) translate3d(0, 0, 0); }
    button.ham.active span:nth-of-type(3) {
      transform: rotate(-45deg) scale3d(0.8, 1, 1) translate3d(0, 0, 0); } }

.mobile-nav-action {
  width: auto;
  height: 45px;
  background-color: #F79020;
  line-height: 45px;
  float: right;
  display: inline-block;
  padding: 0 1.5em;
  position: absolute;
  z-index: 20;
  padding-left: 20px;
  right: 4rem;
  top: 2rem;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  display: none;
  transition: 0.3s cubic-bezier(0.5, 0, 0, 1);
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-nav-action i {
    margin-right: 0.5em; }
    .mobile-nav-action i:before {
      margin-left: 0; }
  @media (max-width: 1024px) {
    .mobile-nav-action {
      display: block;
      right: 4rem; } }

.submenu-toggle {
  width: 1em;
  height: 1em;
  position: relative;
  float: right;
  margin-left: 14px;
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: none; }
  @media (max-width: 1024px) {
    .submenu-toggle {
      display: inline-block; } }
  .submenu-toggle:before {
    content: '\e86c';
    font-family: "fontello";
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 24px;
    color: #2D2A2B; }
  li.menu-item-has-children.-open .submenu-toggle:before {
    transform: rotate(180deg); }

body.menu-active {
  overflow: hidden;
  height: 100vh; }
  body.menu-active .primary-navigation {
    top: 0;
    height: 100vh;
    overflow: auto;
    width: 100%;
    display: block;
    float: none;
    margin: 0;
    z-index: 9;
    padding: 0 0 15rem; }
    body.menu-active .primary-navigation:after, body.menu-active .primary-navigation:before {
      display: none; }
    body.menu-active .primary-navigation .container {
      position: relative; }
    body.menu-active .primary-navigation .menu,
    body.menu-active .primary-navigation .menu-primary-container {
      position: relative;
      max-height: none;
      padding-top: 2rem;
      background: white; }
      body.menu-active .primary-navigation .menu:after, body.menu-active .primary-navigation .menu:before,
      body.menu-active .primary-navigation .menu-primary-container:after,
      body.menu-active .primary-navigation .menu-primary-container:before {
        display: none; }
    body.menu-active .primary-navigation .actions {
      display: block;
      position: relative; }
  body.menu-active .primary-navigation #menu-primary {
    position: relative;
    border-radius: none;
    max-height: none;
    display: block;
    pointer-events: all;
    overflow: visible;
    height: auto;
    z-index: 9; }
    body.menu-active .primary-navigation #menu-primary > li {
      width: 100%;
      position: relative;
      display: block;
      opacity: 1;
      text-align: center;
      max-width: 20em;
      margin: 0 auto; }
      body.menu-active .primary-navigation #menu-primary > li a {
        display: block;
        position: relative; }
        body.menu-active .primary-navigation #menu-primary > li a:hover, body.menu-active .primary-navigation #menu-primary > li a:focus {
          background: orange; }
      body.menu-active .primary-navigation #menu-primary > li.button {
        margin-top: .5em; }
        body.menu-active .primary-navigation #menu-primary > li.button a:hover, body.menu-active .primary-navigation #menu-primary > li.button a:focus {
          background: transparent; }

.basic-section.-centered {
  text-align: center; }

.basic-section .content {
  display: block;
  float: left;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%; }
  @media (min-width: 1200px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 400px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 1024px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) and (min-width: 1200px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) and (max-width: 1024px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) and (max-width: 736px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 1024px) and (max-width: 400px) {
    .basic-section .content {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 736px) {
    .basic-section .content {
      width: 90vw; } }
  .basic-section .content table {
    margin: 0 0 1rem;
    vertical-align: top; }
    .basic-section .content table tr {
      padding: 0; }
      .basic-section .content table tr td {
        padding: 0 0 2rem 0;
        line-height: 1.5; }

.basic-section img {
  border-radius: 0; }

.section.calltoaction-section {
  padding: 4rem 0;
  background: #F79020;
  color: white; }
  .section.calltoaction-section.-centered {
    text-align: center; }
  .section.calltoaction-section .content {
    display: block;
    float: left;
    width: 73%;
    margin-left: 9.33333%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .section.calltoaction-section .content {
        display: block;
        float: left;
        width: 73%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.calltoaction-section .content {
        display: block;
        float: left;
        width: 73%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.calltoaction-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.calltoaction-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 736px) {
      .section.calltoaction-section .content {
        width: 80vw; } }
    .section.calltoaction-section .content h1, .section.calltoaction-section .content h2, .section.calltoaction-section .content h3 {
      color: white; }
  .section.calltoaction-section img {
    border-radius: 0; }

.section.featured-panels {
  z-index: 2;
  clear: both; }
  @media (max-width: 736px) {
    .section.featured-panels {
      margin-bottom: -4rem; } }
  .section.featured-panels .section-title {
    padding: 0 1rem 1rem;
    text-align: center; }
  .section.featured-panels.-footer {
    padding-bottom: 0;
    margin-top: -5vh;
    bottom: -10vh; }
    @media (max-width: 736px) {
      .section.featured-panels.-footer {
        bottom: 0; } }
  .section.featured-panels .content {
    display: block;
    float: left;
    width: 108.33333%;
    margin-left: 4.16667%;
    margin-right: 0;
    border-radius: 0;
    overflow: hidden; }
    @media (min-width: 1200px) {
      .section.featured-panels .content {
        display: block;
        float: left;
        width: 100%;
        margin-left: 0%;
        margin-right: 0; } }
    @media (max-width: 1024px) {
      .section.featured-panels .content {
        display: block;
        float: left;
        width: 100%;
        margin-left: 0%;
        margin-right: 0; } }
    @media (max-width: 736px) {
      .section.featured-panels .content {
        display: block;
        float: left;
        width: 100%;
        margin-left: 0%;
        margin-right: 0; } }
    @media (max-width: 400px) {
      .section.featured-panels .content {
        display: block;
        float: left;
        width: 100%;
        margin-left: 0%;
        margin-right: 0; } }
    @media (max-width: 736px) {
      .section.featured-panels .content {
        padding: 1rem 0; } }
  .section.featured-panels ~ footer {
    padding-top: 18vh; }
    @media (max-width: 736px) {
      .section.featured-panels ~ footer {
        padding: 4.5rem 1rem 1rem; } }

.section.featured-post-section {
  position: relative;
  z-index: 1; }
  @media (max-width: 736px) {
    .section.featured-post-section {
      padding: 1rem; } }
  .section.featured-post-section .content {
    display: block;
    float: left;
    width: 89.66667%;
    margin-left: 5.16667%;
    margin-right: 1%;
    background: white;
    border-radius: 0; }
    @media (min-width: 1200px) {
      .section.featured-post-section .content {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 5.16667%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.featured-post-section .content {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 5.16667%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.featured-post-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.featured-post-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 736px) {
      .section.featured-post-section .content {
        display: block;
        float: left;
        width: 100%;
        margin-left: 0%;
        margin-right: 0; } }
    @media (max-width: 736px) {
      .section.featured-post-section .content {
        margin-top: 3em; } }

.feed-section {
  text-align: center; }
  @media (max-width: 736px) {
    .feed-section {
      padding: 5vw; } }
  .feed-section.section .introduction {
    display: block;
    float: left;
    width: 89.66667%;
    margin-left: 5.16667%;
    margin-right: 1%;
    text-align: center; }
    @media (min-width: 1200px) {
      .feed-section.section .introduction {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 5.16667%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .feed-section.section .introduction {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 5.16667%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .feed-section.section .introduction {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .feed-section.section .introduction {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
  .feed-section.-press {
    background: #F4F4F4;
    padding: 4rem 0; }
  .feed-section.-post .feed .card {
    background: white;
    box-shadow: 0 2px 5px rgba(45, 42, 43, 0.1);
    min-height: 22rem;
    padding-bottom: 1rem; }
    .feed-section.-post .feed .card > a {
      box-shadow: none;
      background: transparent;
      padding-bottom: 0; }
  .feed-section .feed {
    display: block;
    float: left;
    width: 89.66667%;
    margin-left: 5.16667%;
    margin-right: 1%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1; }
    @media (min-width: 1200px) {
      .feed-section .feed {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 5.16667%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .feed-section .feed {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 5.16667%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .feed-section .feed {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .feed-section .feed {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    .feed-section .feed:after {
      content: "";
      display: table;
      clear: both; }
    @media (max-width: 736px) {
      .feed-section .feed {
        display: block;
        flex-wrap: none;
        padding: 0 1rem;
        overflow: visible; } }
    .feed-section .feed .slick-list {
      overflow: visible; }
    .feed-section .feed .card {
      margin-right: 2.9vw;
      margin-bottom: 2.9vw;
      margin-top: 2%;
      width: calc(25% - 2.9vw); }
      @media (max-width: 1024px) {
        .feed-section .feed .card {
          width: calc(50% - 1.7vw); } }
      @media (max-width: 736px) {
        .feed-section .feed .card {
          width: 100%;
          margin-bottom: 5vw; } }
      .feed-section .feed .card.-search {
        margin-top: 0;
        width: calc(50% - 2.9vw); }
        .feed-section .feed .card.-search:nth-child(2n+1) {
          clear: both; }
        @media (max-width: 736px) {
          .feed-section .feed .card.-search {
            width: 100%; } }
      .feed-section .feed .card:nth-child(4n+1) {
        clear: both; }
      .feed-section .feed .card.has-meta ~ .card, .feed-section .feed .card.has-meta {
        margin-top: 2.3em; }
      @media (max-width: 736px) {
        .feed-section .feed .card.slick-slide {
          width: calc(100% - 1rem);
          margin-right: 0.5rem;
          margin-left: 0.5rem;
          padding-bottom: 4vh; }
          .feed-section .feed .card.slick-slide:nth-of-type(2n) {
            margin-right: 0.5rem; } }
      @media (max-width: 736px) {
        .feed-section .feed .card.slick-slide h6 {
          text-align: center;
          padding-top: 4vh; } }
      @media (max-width: 1024px) {
        .feed-section .feed .card:nth-of-type(2n) {
          margin-right: 0; } }
    .feed-section .feed ~ .button {
      margin-top: 5vh; }
  .feed-section .feed-footer {
    display: block;
    float: left;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center; }
    @media (min-width: 1200px) {
      .feed-section .feed-footer {
        display: block;
        float: left;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .feed-section .feed-footer {
        display: block;
        float: left;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .feed-section .feed-footer {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .feed-section .feed-footer {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    .feed-section .feed-footer .button {
      margin-right: 0; }
  .feed-section .feed-more {
    display: block;
    float: left;
    width: 89.66667%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: right; }
    @media (min-width: 1200px) {
      .feed-section .feed-more {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .feed-section .feed-more {
        display: block;
        float: left;
        width: 89.66667%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .feed-section .feed-more {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .feed-section .feed-more {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    .feed-section .feed-more a {
      font-weight: bold;
      color: #F79020; }
  .feed-section.-secondary {
    background: #F4F4F4; }

.feed-section-empty {
  text-align: center; }
  .feed-section-empty .content {
    display: block;
    float: left;
    width: 81.33333%;
    margin-left: 9.33333%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .feed-section-empty .content {
        display: block;
        float: left;
        width: 81.33333%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .feed-section-empty .content {
        display: block;
        float: left;
        width: 81.33333%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .feed-section-empty .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .feed-section-empty .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }

.section.images-section {
  background: white;
  color: #2D2A2B;
  float: left;
  clear: both; }
  .section.images-section .title {
    text-align: center;
    margin: 0 0 2em; }
  .section.images-section .content {
    text-align: center; }
  .section.images-section a.image {
    cursor: pointer; }
  .section.images-section .image-single {
    width: 10rem;
    border-radius: 0;
    overflow: hidden;
    display: inline-block;
    position: relative;
    margin-bottom: 1rem;
    margin-right: 1em;
    margin-left: 1em;
    outline: none; }
    @media (max-width: 736px) {
      .section.images-section .image-single {
        width: 15rem;
        height: 8rem; } }
    .section.images-section .image-single > .image {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      opacity: 1;
      transition: all 1s cubic-bezier(0.5, 0, 0, 1); }
      @media (max-width: 736px) {
        .section.images-section .image-single > .image {
          width: 100%;
          height: 100%;
          padding: 0;
          background-position: center center; } }
    .section.images-section .image-single:focus .image-content, .section.images-section .image-single:hover .image-content {
      top: 0; }

.section.intro-section {
  padding: 0 0 2rem;
  border-bottom: 1px #F4F4F4 solid; }
  .section.intro-section:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 736px) {
    .section.intro-section {
      padding: 0.66667rem 1.5rem 1rem; } }
  .section.intro-section .content {
    display: block;
    float: left;
    width: 64.66667%;
    margin-left: 9.33333%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .section.intro-section .content {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.intro-section .content {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.intro-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.intro-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
  .section.intro-section .feed-actions {
    display: block;
    float: left;
    width: 81.33333%;
    margin-left: 9.33333%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .section.intro-section .feed-actions {
        display: block;
        float: left;
        width: 81.33333%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.intro-section .feed-actions {
        display: block;
        float: left;
        width: 81.33333%;
        margin-left: 9.33333%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.intro-section .feed-actions {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.intro-section .feed-actions {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
  .section.intro-section path {
    fill: #F4F4F4; }
  .woocommerce .section.intro-section {
    margin-bottom: -15vh; }

.section.members-section {
  background: #2D2A2B;
  color: white; }
  .section.members-section .title {
    text-align: center;
    margin: 0 0 2em; }
  .section.members-section .content {
    display: block;
    float: left;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    display: flex !important;
    flex-wrap: wrap; }
    @media (min-width: 1200px) {
      .section.members-section .content {
        display: block;
        float: left;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.members-section .content {
        display: block;
        float: left;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.members-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.members-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (min-width: 1200px) {
      .section.members-section .content {
        display: block;
        float: left;
        width: 83.33333%;
        margin-left: 8.33333%;
        margin-right: 0; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .section.members-section .content {
      display: block;
      float: left;
      width: 83.33333%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (min-width: 1200px) and (max-width: 1024px) {
    .section.members-section .content {
      display: block;
      float: left;
      width: 83.33333%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (min-width: 1200px) and (max-width: 736px) {
    .section.members-section .content {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  @media (min-width: 1200px) and (max-width: 400px) {
    .section.members-section .content {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
    @media (max-width: 736px) {
      .section.members-section .content {
        padding: 0 1rem 1rem; } }
  .section.members-section a.member {
    cursor: pointer; }
  .section.members-section .member {
    width: 25%;
    border-radius: 0;
    overflow: hidden;
    background: currentColor;
    display: block;
    float: left;
    position: relative;
    outline: none;
    margin-bottom: 0; }
    @media (max-width: 1024px) {
      .section.members-section .member {
        width: 50%; } }
    @media (min-width: 1200px) {
      .section.members-section .member {
        width: calc(25% - 1rem);
        margin-bottom: 1rem;
        margin-left: 0.5rem;
        margin-left: 0.5rem; } }
    .section.members-section .member:nth-of-type(1), .section.members-section .member:nth-of-type(4n + 1) {
      margin-left: 0; }
    @media (max-width: 736px) {
      .section.members-section .member.-without-quote .image {
        width: 100%; } }
    @media (max-width: 736px) {
      .section.members-section .member {
        width: 100%;
        height: 20em;
        margin-left: 0;
        margin-right: 0; }
        .section.members-section .member:not(:last-of-type) {
          margin-bottom: 1rem; }
        .section.members-section .member:nth-of-type(2n) {
          margin-left: 0; } }
    .section.members-section .member .member-content {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 1;
      padding: 1rem;
      min-height: 6.75rem;
      transition: all 0.8s cubic-bezier(0.5, 0, 0, 1);
      width: 100%;
      height: 100%;
      top: 100%;
      background: rgba(247, 144, 32, 0.9);
      color: white; }
      @media (max-width: 736px) {
        .section.members-section .member .member-content {
          position: absolute;
          width: 50%;
          height: 100%;
          left: 50%;
          top: 0;
          padding: 1rem;
          margin: 0; } }
      .section.members-section .member .member-content .quote {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.0;
        margin-bottom: .25rem;
        text-align: center; }
        @media (min-width: 1200px) {
          .section.members-section .member .member-content .quote {
            font-size: 1.1rem;
            line-height: 1.2; } }
        @media (max-width: 736px) {
          .section.members-section .member .member-content .quote {
            text-align: left;
            font-size: 1rem;
            line-height: 1.0; } }
      .section.members-section .member .member-content .name {
        font-size: 0.9rem;
        font-weight: bold;
        line-height: 1;
        margin-bottom: .5em;
        text-align: center;
        color: #2D2A2B; }
        @media (min-width: 1200px) {
          .section.members-section .member .member-content .name {
            font-size: 1.0rem;
            line-height: 1.2; } }
        @media (max-width: 736px) {
          .section.members-section .member .member-content .name {
            text-align: left; } }
      .section.members-section .member .member-content.-long .title {
        min-height: 1.2em; }
    .section.members-section .member .image {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      opacity: 1;
      transition: all 1s cubic-bezier(0.5, 0, 0, 1); }
      @media (max-width: 736px) {
        .section.members-section .member .image {
          width: 50%;
          height: 100%;
          padding: 0;
          background-position: center center; } }
    .section.members-section .member:focus .member-content, .section.members-section .member:hover .member-content, .section.members-section .member:active .member-content {
      top: 0; }
  .section.members-section ~ .featured-panels.-footer {
    padding-top: 5vh; }
  .section.members-section.-count-1 {
    background: white; }
    .section.members-section.-count-1 .content {
      display: block;
      float: left;
      width: 66.66667%;
      margin-left: 16.66667%;
      margin-right: 0; }
      @media (min-width: 1200px) {
        .section.members-section.-count-1 .content {
          display: block;
          float: left;
          width: 66.66667%;
          margin-left: 16.66667%;
          margin-right: 0; } }
      @media (max-width: 1024px) {
        .section.members-section.-count-1 .content {
          display: block;
          float: left;
          width: 66.66667%;
          margin-left: 16.66667%;
          margin-right: 0; } }
      @media (max-width: 736px) {
        .section.members-section.-count-1 .content {
          display: block;
          float: left;
          width: 100%;
          margin-left: 0%;
          margin-right: 0; } }
      @media (max-width: 400px) {
        .section.members-section.-count-1 .content {
          display: block;
          float: left;
          width: 100%;
          margin-left: 0%;
          margin-right: 0; } }
    .section.members-section.-count-1 .member {
      width: 100%;
      height: 16rem;
      background: #F79020;
      color: white;
      margin-bottom: 4rem;
      overflow: visible; }
      @media (max-width: 736px) {
        .section.members-section.-count-1 .member {
          padding-top: 16rem;
          height: auto; } }
      .section.members-section.-count-1 .member .image {
        width: 16rem;
        height: 16rem;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0; }
        @media (max-width: 736px) {
          .section.members-section.-count-1 .member .image {
            width: 100%;
            height: 16rem; } }
      .section.members-section.-count-1 .member .member-content {
        background: transparent;
        position: relative;
        top: 0;
        width: calc(100% - 20rem);
        padding: 2em; }
        @media (max-width: 736px) {
          .section.members-section.-count-1 .member .member-content {
            width: 100%;
            padding: 1em;
            margin: 0;
            text-align: center;
            left: auto; } }
        .section.members-section.-count-1 .member .member-content .quote {
          font-size: 1.6rem;
          line-height: 1.2;
          text-align: left;
          margin: 0 0 1rem 0; }
          @media (max-width: 736px) {
            .section.members-section.-count-1 .member .member-content .quote {
              font-size: 1.3rem;
              text-align: center; } }
        .section.members-section.-count-1 .member .member-content .name {
          text-align: left;
          text-transform: uppercase; }
          @media (max-width: 736px) {
            .section.members-section.-count-1 .member .member-content .name {
              text-align: center; } }
      .section.members-section.-count-1 .member:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -3rem;
        left: 0;
        border-width: 1.5rem;
        border-style: solid;
        border-color: #2D2A2B transparent transparent #2D2A2B; }
      .section.members-section.-count-1 .member:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -2rem;
        left: 0;
        border-width: 1rem;
        border-style: solid;
        border-color: #F79020 transparent transparent #F79020; }

.section.panel-section .title {
  text-align: center;
  margin: 0 0 2em;
  font-size: 1.8rem; }

.section.panel-section .content {
  display: block;
  float: left;
  width: 83.33333%;
  margin-left: 8.33333%;
  margin-right: 0;
  display: flex !important;
  flex-wrap: wrap; }
  @media (min-width: 1200px) {
    .section.panel-section .content {
      display: block;
      float: left;
      width: 83.33333%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (max-width: 1024px) {
    .section.panel-section .content {
      display: block;
      float: left;
      width: 83.33333%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (max-width: 736px) {
    .section.panel-section .content {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  @media (max-width: 400px) {
    .section.panel-section .content {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  @media (max-width: 736px) {
    .section.panel-section .content {
      padding: 0 1rem 1rem; } }

.section.panel-section .panel {
  width: calc(50% - 0.5rem);
  border-radius: 0;
  overflow: hidden;
  background: currentColor;
  display: inline-block;
  float: left;
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;
  outline: none; }
  .section.panel-section .panel:nth-of-type(2n) {
    margin-left: 1rem; }
  @media (max-width: 736px) {
    .section.panel-section .panel {
      width: 100%; }
      .section.panel-section .panel:not(:last-of-type) {
        margin-bottom: 1rem; }
      .section.panel-section .panel:nth-of-type(2n) {
        margin-left: 0; } }
  .section.panel-section .panel .panel-content {
    position: absolute;
    bottom: 0;
    z-index: 1;
    padding: 2rem 1rem 1rem;
    transition: all 0.8s cubic-bezier(0.5, 0, 0, 1);
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    color: white; }
    @media (max-width: 736px) {
      .section.panel-section .panel .panel-content {
        padding: 1rem;
        margin: 0.75rem 0.75rem 0;
        width: calc(100% - 1.5rem); } }
    .section.panel-section .panel .panel-content .title {
      line-height: 1;
      margin-bottom: .5em;
      text-align: left;
      color: white; }
    .section.panel-section .panel .panel-content.-long .title {
      min-height: 1.2em; }
  .section.panel-section .panel .image {
    position: relative;
    width: 100%;
    padding-bottom: 56%;
    opacity: .9;
    transition: all 1s cubic-bezier(0.5, 0, 0, 1); }
  .section.panel-section .panel:focus .image, .section.panel-section .panel:hover .image {
    opacity: 1; }
  .section.panel-section .panel.-with-image .panel-content .title {
    margin-top: auto; }

.section.panel-section ~ .featured-panels.-footer {
  padding-top: 5vh; }

.section.join-section {
  padding: 2rem 0; }
  .section.join-section .content {
    display: block;
    float: left;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 3rem 45% 2rem 2rem;
    background: #F79020;
    color: #2D2A2B;
    text-align: center; }
    @media (min-width: 1200px) {
      .section.join-section .content {
        display: block;
        float: left;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.join-section .content {
        display: block;
        float: left;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.join-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.join-section .content {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    .section.join-section .content h1, .section.join-section .content h2, .section.join-section .content h3, .section.join-section .content h4, .section.join-section .content h5, .section.join-section .content h6 {
      color: #2D2A2B; }
    @media (max-width: 736px) {
      .section.join-section .content {
        padding: 1rem; } }
  .section.join-section.-narrow {
    display: block;
    float: left;
    width: 64.66667%;
    margin-left: 17.66667%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .section.join-section.-narrow {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 17.66667%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.join-section.-narrow {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 17.66667%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.join-section.-narrow {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.join-section.-narrow {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
  .section.join-section .image {
    position: absolute;
    top: -0.25rem;
    right: 0;
    bottom: -0.25rem;
    width: 42%;
    overflow: hidden; }
    @media (max-width: 736px) {
      .section.join-section .image {
        width: 100%;
        height: 30vh;
        margin-bottom: 2rem;
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto; } }
    .section.join-section .image > span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1);
      transition: 3s;
      background-size: cover;
      background-position: center center; }

.tab-section .content {
  display: block;
  float: left;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  text-align: left;
  border-radius: 0;
  overflow: hidden;
  background-image: linear-gradient(35deg, #FFFFFF 67%, rgba(255, 255, 255, 0) 100%);
  background-size: 120% 100%; }
  @media (min-width: 1200px) {
    .tab-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 1024px) {
    .tab-section .content {
      display: block;
      float: left;
      width: 98%;
      margin-left: 1%;
      margin-right: 1%; } }
  @media (max-width: 736px) {
    .tab-section .content {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 400px) {
    .tab-section .content {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 736px) {
    .tab-section .content {
      margin: 0 1rem;
      width: 90vw; } }

.tab-section .tab-nav {
  box-shadow: 0 1px 0 currentColor;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .tab-section .tab-nav:after {
    content: "";
    display: table;
    clear: both; }

.tab-section .-secondary .nav-item,
.tab-section .-secondary .tab-nav {
  color: #2D2A2B; }

.tab-section .-dark .nav-item,
.tab-section .-dark .tab-nav {
  color: #2D2A2B; }

.tab-section.-secondary h3, .tab-section.-dark h3 {
  color: white; }

.tab-section.-secondary .content, .tab-section.-dark .content {
  background-size: 150% 100%; }

.tab-section.-primary .nav-item {
  color: #2D2A2B; }
  .tab-section.-primary .nav-item:not(:first-of-type) {
    box-shadow: -1px 0 0 #F79020; }

.tab-section.-primary .tab-nav {
  color: #F79020; }

.tab-section .nav-item {
  float: left;
  border-radius: none;
  border: none;
  appearance: none;
  background: transparent;
  color: #2D2A2B;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  pointer-events: all;
  padding: 3.3vw 1vw; }
  .tab-section .nav-item.-long {
    padding: 2vw 0vw; }
  .tab-section .nav-item.-active {
    background: #2D2A2B;
    color: white; }
    @media (max-width: 736px) {
      .tab-section .nav-item.-active {
        pointer-events: all; } }
    .tab-section .nav-item.-active h5 {
      color: white; }
  .tab-section .nav-item h5 {
    position: relative;
    z-index: 1;
    display: inline-block;
    transition: 0.6s cubic-bezier(0.5, 0, 0, 1);
    will-change: color, transform;
    vertical-align: middle;
    margin: 0;
    max-width: calc(100% - calc(26px + 1em)); }
    .tab-section .nav-item h5.-long {
      margin-bottom: .2em; }
  .tab-section .nav-item:not(:first-of-type) {
    box-shadow: -1px 0 0 currentColor; }

.tab-section .tab {
  color: #2D2A2B;
  position: relative;
  overflow: hidden;
  float: left;
  visibility: hidden;
  height: 0;
  display: block;
  width: 100%; }
  .tab-section .tab .tab-content {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    min-height: 30vw;
    width: 55%;
    flex-direction: column;
    flex-basis: 0;
    align-items: flex-start;
    transition: 0.4s cubic-bezier(0.5, 0, 0, 1) 0.2s; }
    .tab-section .tab .tab-content p {
      width: 100%; }
    @media (max-width: 736px) {
      .tab-section .tab .tab-content {
        width: 100%;
        padding: 1rem; } }
  .tab-section .tab .image {
    background-color: grey;
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 60%;
    transition: 0.5s cubic-bezier(0.5, 0, 0, 1);
    border-radius: 0 0 0 0; }
    @media (max-width: 736px) {
      .tab-section .tab .image {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        height: 40vh;
        border-radius: 0 0 0 0; } }
  .tab-section .tab .button {
    margin-top: auto; }

@media (max-width: 736px) {
  .tab-section .tab-nav {
    display: none; } }

.tab-section .tab-nav[class*="active-"] ~ .tabs .tab {
  visibility: hidden;
  height: 0; }

@media only screen and (min-width: 736px) {
  .tab-section .tab-nav.tabs-1 .nav-item {
    width: calc(100% / 1);
    float: left; }
  .tab-section .tab-nav.active-1 ~ .tabs .tab .tab-content {
    opacity: 0;
    transform: translate(1vw, 0);
    transform: translate3d(1vw, 0, 0);
    will-change: transform, opacity; }
  .tab-section .tab-nav.active-1 ~ .tabs .tab .image {
    transform: translate(1vw, 0, 0);
    transform: translate3d(1vw, 0, 0);
    opacity: 0; }
  .tab-section .tab-nav.active-1 ~ .tabs .tab:nth-of-type(1) {
    visibility: visible;
    height: auto; }
    .tab-section .tab-nav.active-1 ~ .tabs .tab:nth-of-type(1) .tab-content {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0); }
    .tab-section .tab-nav.active-1 ~ .tabs .tab:nth-of-type(1) .image {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 0.5s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
  .tab-section .tab-nav.tabs-2 .nav-item {
    width: calc(100% / 2);
    float: left; }
  .tab-section .tab-nav.active-2 ~ .tabs .tab .tab-content {
    opacity: 0;
    transform: translate(1vw, 0);
    transform: translate3d(1vw, 0, 0);
    will-change: transform, opacity; }
  .tab-section .tab-nav.active-2 ~ .tabs .tab .image {
    transform: translate(1vw, 0, 0);
    transform: translate3d(1vw, 0, 0);
    opacity: 0; }
  .tab-section .tab-nav.active-2 ~ .tabs .tab:nth-of-type(2) {
    visibility: visible;
    height: auto; }
    .tab-section .tab-nav.active-2 ~ .tabs .tab:nth-of-type(2) .tab-content {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0); }
    .tab-section .tab-nav.active-2 ~ .tabs .tab:nth-of-type(2) .image {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 0.5s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
  .tab-section .tab-nav.tabs-3 .nav-item {
    width: calc(100% / 3);
    float: left; }
  .tab-section .tab-nav.active-3 ~ .tabs .tab .tab-content {
    opacity: 0;
    transform: translate(1vw, 0);
    transform: translate3d(1vw, 0, 0);
    will-change: transform, opacity; }
  .tab-section .tab-nav.active-3 ~ .tabs .tab .image {
    transform: translate(1vw, 0, 0);
    transform: translate3d(1vw, 0, 0);
    opacity: 0; }
  .tab-section .tab-nav.active-3 ~ .tabs .tab:nth-of-type(3) {
    visibility: visible;
    height: auto; }
    .tab-section .tab-nav.active-3 ~ .tabs .tab:nth-of-type(3) .tab-content {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0); }
    .tab-section .tab-nav.active-3 ~ .tabs .tab:nth-of-type(3) .image {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 0.5s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
  .tab-section .tab-nav.tabs-4 .nav-item {
    width: calc(100% / 4);
    float: left; }
  .tab-section .tab-nav.active-4 ~ .tabs .tab .tab-content {
    opacity: 0;
    transform: translate(1vw, 0);
    transform: translate3d(1vw, 0, 0);
    will-change: transform, opacity; }
  .tab-section .tab-nav.active-4 ~ .tabs .tab .image {
    transform: translate(1vw, 0, 0);
    transform: translate3d(1vw, 0, 0);
    opacity: 0; }
  .tab-section .tab-nav.active-4 ~ .tabs .tab:nth-of-type(4) {
    visibility: visible;
    height: auto; }
    .tab-section .tab-nav.active-4 ~ .tabs .tab:nth-of-type(4) .tab-content {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0); }
    .tab-section .tab-nav.active-4 ~ .tabs .tab:nth-of-type(4) .image {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 0.5s cubic-bezier(0.5, 0, 0, 1) 0.3s; }
  .tab-section .tab-nav.tabs-5 .nav-item {
    width: calc(100% / 5);
    float: left; }
  .tab-section .tab-nav.active-5 ~ .tabs .tab .tab-content {
    opacity: 0;
    transform: translate(1vw, 0);
    transform: translate3d(1vw, 0, 0);
    will-change: transform, opacity; }
  .tab-section .tab-nav.active-5 ~ .tabs .tab .image {
    transform: translate(1vw, 0, 0);
    transform: translate3d(1vw, 0, 0);
    opacity: 0; }
  .tab-section .tab-nav.active-5 ~ .tabs .tab:nth-of-type(5) {
    visibility: visible;
    height: auto; }
    .tab-section .tab-nav.active-5 ~ .tabs .tab:nth-of-type(5) .tab-content {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0); }
    .tab-section .tab-nav.active-5 ~ .tabs .tab:nth-of-type(5) .image {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 0.5s cubic-bezier(0.5, 0, 0, 1) 0.3s; } }

.tab-section .tabs .nav-item {
  display: none;
  width: 100%; }
  @media (max-width: 736px) {
    .tab-section .tabs .nav-item {
      display: block; } }
  .tab-section .tabs .nav-item:not(:last-of-type) {
    box-shadow: inset 0 -1px 0 rgba(45, 42, 43, 0.2); }
  .tab-section .tabs .nav-item.-active + .tab {
    visibility: visible;
    height: auto; }
    .tab-section .tabs .nav-item.-active + .tab .tab-content {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0); }
    .tab-section .tabs .nav-item.-active + .tab .image {
      opacity: 1;
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      transition: 0.5s cubic-bezier(0.5, 0, 0, 1) 0.3s; }

.section.topics-section {
  padding: 4rem 0; }
  .section.topics-section .content {
    text-align: center;
    padding: 0 0 2rem 0; }
  .section.topics-section .topics .topic {
    display: block;
    float: left;
    width: 18%;
    margin-left: 1%;
    margin-right: 1%;
    display: block;
    text-align: center;
    padding: 0 0.5rem;
    min-height: 20rem;
    font-size: .9rem;
    line-height: 1.1rem; }
    @media (min-width: 1200px) {
      .section.topics-section .topics .topic {
        display: block;
        float: left;
        width: 18%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section.topics-section .topics .topic {
        display: block;
        float: left;
        width: 18%;
        margin-left: 1%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section.topics-section .topics .topic {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section.topics-section .topics .topic {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 736px) {
      .section.topics-section .topics .topic {
        width: 50%;
        margin: 0;
        min-height: 18rem;
        font-size: .8rem;
        line-height: 1rem; } }
    .section.topics-section .topics .topic h3 {
      margin: 0 0 1rem;
      text-transform: none;
      color: #2D2A2B; }
    .section.topics-section .topics .topic img {
      display: inline-block;
      max-width: 3rem;
      height: auto;
      margin: 0 0 1em; }

.section {
  width: 100%;
  padding: 2rem 0;
  position: relative; }
  .section:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 736px) {
    .section {
      padding: 1.5rem 0; } }
  .section[id*="tfa"] {
    padding: 0; }
  .section.section-split {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    padding-top: 0; }
    .section.section-split:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 1200px) {
      .section.section-split {
        max-width: 1200px; } }
    .section.section-split .split-main {
      display: block;
      float: left;
      width: 48%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        .section.section-split .split-main {
          display: block;
          float: left;
          width: 48%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        .section.section-split .split-main {
          display: block;
          float: left;
          width: 48%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        .section.section-split .split-main {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        .section.section-split .split-main {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    .section.section-split .split-aside {
      display: block;
      float: left;
      width: 31.33333%;
      margin-left: 9.33333%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        .section.section-split .split-aside {
          display: block;
          float: left;
          width: 31.33333%;
          margin-left: 9.33333%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        .section.section-split .split-aside {
          display: block;
          float: left;
          width: 31.33333%;
          margin-left: 9.33333%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        .section.section-split .split-aside {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        .section.section-split .split-aside {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    .section.section-split.-donate .split-main {
      display: block;
      float: left;
      width: 48%;
      margin-left: 1%;
      margin-right: 1%; }
      @media (min-width: 1200px) {
        .section.section-split.-donate .split-main {
          display: block;
          float: left;
          width: 48%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        .section.section-split.-donate .split-main {
          display: block;
          float: left;
          width: 48%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        .section.section-split.-donate .split-main {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        .section.section-split.-donate .split-main {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
    .section.section-split.-donate .split-aside {
      display: block;
      float: left;
      width: 39.66667%;
      margin-left: 1%;
      margin-right: 1%;
      float: right; }
      @media (min-width: 1200px) {
        .section.section-split.-donate .split-aside {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 1024px) {
        .section.section-split.-donate .split-aside {
          display: block;
          float: left;
          width: 39.66667%;
          margin-left: 1%;
          margin-right: 1%; } }
      @media (max-width: 736px) {
        .section.section-split.-donate .split-aside {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 400px) {
        .section.section-split.-donate .split-aside {
          display: block;
          float: left;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media (max-width: 1024px) {
        .section.section-split.-donate .split-aside {
          float: right; } }
      @media (min-width: 1200px) {
        .section.section-split.-donate .split-aside {
          float: right; } }
  .section .introduction {
    display: block;
    float: left;
    width: 48%;
    margin-left: 26%;
    margin-right: 1%;
    text-align: left; }
    @media (min-width: 1200px) {
      .section .introduction {
        display: block;
        float: left;
        width: 48%;
        margin-left: 26%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section .introduction {
        display: block;
        float: left;
        width: 48%;
        margin-left: 26%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section .introduction {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section .introduction {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
  .section .content {
    position: relative; }
    .section .content table:not([class*="woocommerce"]),
    .section .content table:not([class*="woocommerce"]) td {
      border: none; }
  .section .section-default {
    display: block;
    float: left;
    width: 64.66667%;
    margin-left: 17.66667%;
    margin-right: 1%; }
    @media (min-width: 1200px) {
      .section .section-default {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 17.66667%;
        margin-right: 1%; } }
    @media (max-width: 1024px) {
      .section .section-default {
        display: block;
        float: left;
        width: 64.66667%;
        margin-left: 17.66667%;
        margin-right: 1%; } }
    @media (max-width: 736px) {
      .section .section-default {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    @media (max-width: 400px) {
      .section .section-default {
        display: block;
        float: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%; } }
    .section .section-default .section-text {
      margin-top: 1em; }
    .section .section-default .section-buttons {
      margin-top: 1em; }
  .section.-default.featured-post-section .card-featured {
    background-color: #F4F4F4; }
  .section.-light {
    background-color: #e7e7e7; }
  .section.-primary .introduction {
    color: white; }
    .section.-primary .introduction h1, .section.-primary .introduction h2, .section.-primary .introduction h3, .section.-primary .introduction h4, .section.-primary .introduction h5, .section.-primary .introduction h6 {
      color: white; }
  .section.-primary .card {
    color: inherit; }
  .section.-primary.tab-section, .section.-primary.feed-section, .section.-primary.featured-post-section {
    padding: 4rem 0;
    background: #F79020;
    color: white; }
    .section.-primary.tab-section .card-featured .meta .caption,
    .section.-primary.tab-section .card-featured .meta .sub, .section.-primary.feed-section .card-featured .meta .caption,
    .section.-primary.feed-section .card-featured .meta .sub, .section.-primary.featured-post-section .card-featured .meta .caption,
    .section.-primary.featured-post-section .card-featured .meta .sub {
      color: white; }
  .section.-dark.tab-section, .section.-dark.feed-section, .section.-dark.featured-post-section {
    background: #2D2A2B;
    color: white; }
    .section.-dark.tab-section .card-featured .meta .sub, .section.-dark.feed-section .card-featured .meta .sub, .section.-dark.featured-post-section .card-featured .meta .sub {
      color: white; }

.lightbg {
  background-color: #fff; }

.darkbg {
  background-color: #F4F4F4; }

.lightbg + .lightbg {
  padding-top: 0; }

.darkbg + .darkbg {
  padding-top: 0; }

.rep-results {
  background: rgba(45, 42, 43, 0.1);
  display: none;
  margin-top: -.75rem;
  padding: 0.5rem;
  border-radius: 0 0 0 0;
  z-index: 0; }
  .rep-results img {
    border-radius: 0; }
  .rep-results h6 {
    font-size: 16px;
    font-size: 1/pxrem;
    margin-bottom: .5em; }

.can_embed {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }
  .can_embed #can_embed_form, .can_embed #donate_auto_modal {
    padding: 0 !important;
    border: none !important;
    background: transparent !important; }
    .can_embed #can_embed_form #can_thank_you, .can_embed #donate_auto_modal #can_thank_you {
      padding: 2rem;
      background: transparent !important;
      color: #2D2A2B !important; }
  .can_embed #can_embed_form h1, .can_embed #can_embed_form h2, .can_embed #can_embed_form h3, .can_embed #can_embed_form h4, .can_embed #can_embed_form h5, .can_embed #can_embed_form h6,
  .can_embed #can_embed_form input, .can_embed #can_embed_form textarea, .can_embed #can_embed_form select {
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important; }
  .can_embed #can_embed_form h1 {
    font-size: 2rem; }
  .can_embed .can_button,
  .can_embed #can_embed_form input[type=submit],
  .can_embed #can_embed_form .button,
  .can_embed #donate_auto_modal input[type=submit],
  .can_embed #donate_auto_modal .button {
    background: #F79020 !important;
    color: #2D2A2B !important;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
    font-weight: bold !important;
    padding: .65em 1.5em 0.55em 1.5em !important; }
    .can_embed .can_button:hover, .can_embed .can_button:focus,
    .can_embed #can_embed_form input[type=submit]:hover,
    .can_embed #can_embed_form input[type=submit]:focus,
    .can_embed #can_embed_form .button:hover,
    .can_embed #can_embed_form .button:focus,
    .can_embed #donate_auto_modal input[type=submit]:hover,
    .can_embed #donate_auto_modal input[type=submit]:focus,
    .can_embed #donate_auto_modal .button:hover,
    .can_embed #donate_auto_modal .button:focus {
      background: #dc7608 !important;
      color: #131112 !important; }
  .can_embed #can_embed_form_inner > h2:first-child {
    display: none; }
    .can_embed #can_embed_form_inner > h2:first-child + h4 {
      display: none; }
  .can_embed #logo_wrap,
  .can_embed #action_info,
  .can_embed .can_thank_you-block {
    display: none; }
  .can_embed .can_thank_you-block:first-child {
    display: block; }
  .can_embed #can_embed_form.can_float #form_col1,
  .can_embed #can_embed_form.can_float #form_col2 {
    width: 100% !important; }
  .can_embed #can_embed_form #d_sharing input,
  .can_embed #can_embed_form .check_radio_field label input {
    -webkit-appearance: checkbox !important;
    top: 0px !important; }
    @media (max-width: 736px) {
      .can_embed #can_embed_form #d_sharing input,
      .can_embed #can_embed_form .check_radio_field label input {
        top: 5px !important;
        width: 20px; } }
  .can_embed #can_embed_form form li {
    width: 48% !important;
    margin: 0 2% 15px 0 !important;
    float: left !important; }
    .can_embed #can_embed_form form li.form_builder_output {
      width: 100% !important; }
    @media (max-width: 736px) {
      .can_embed #can_embed_form form li {
        margin-right: 0 !important;
        width: 100% !important; } }

.action-wrap {
  overflow: hidden;
  margin-top: -10vh;
  position: relative;
  z-index: 1; }

.action-box iframe {
  width: 100%; }

.action-box form {
  position: relative;
  margin: 3.5vw; }

.action-box:not(.-fa) input:not([type="radio"]):not([type="checkbox"]),
.action-box:not(.-fa) select,
.action-box:not(.-fa) textarea {
  margin-bottom: 1em; }

.action-box .form-header {
  margin: 3.5vw 3.5vw -3.5vw;
  padding: 0 0 1px; }
  .action-box .form-header h6 {
    margin-bottom: .2em; }

.progress + .form-header {
  margin-bottom: -2vw; }

.action-box .message-form:after {
  content: "";
  display: table;
  clear: both; }

.action-box .message-form input:not([type="checkbox"]):not([type="radio"]),
.action-box .message-form textarea {
  width: 100%;
  transition: all 300ms cubic-bezier(0.8, 0, 0.5, 1); }
  .action-box .message-form input:not([type="checkbox"]):not([type="radio"]):focus,
  .action-box .message-form textarea:focus {
    border-color: #2D2A2B; }

.action-box .form-submit {
  margin-top: 1vw;
  float: left;
  text-align: center;
  clear: both;
  width: 100%; }

.action-box .field:not(.fancy-checkbox) {
  width: 100%;
  float: left;
  clear: both;
  position: relative; }
  .action-box .field:not(.fancy-checkbox):after {
    content: "";
    display: table;
    clear: both; }
  .action-box .field:not(.fancy-checkbox) label {
    display: none; }

.action-box .field.fancy-checkbox {
  text-align: left;
  clear: both; }
  .action-box .field.fancy-checkbox:after {
    content: "";
    display: table;
    clear: both; }
  .action-box .field.fancy-checkbox > div {
    float: left;
    width: 35px; }
    .action-box .field.fancy-checkbox > div input[type=checkbox] {
      margin-right: 10px; }
  .action-box .field.fancy-checkbox label {
    width: calc(100% - 35px);
    float: left;
    margin-top: .3em;
    cursor: pointer; }

.action-box .rep-results {
  width: 100%;
  float: left;
  margin-bottom: 1em; }
  .action-box .rep-results .mp {
    width: 100%;
    float: left;
    position: relative;
    border: 2px solid #021945;
    margin-bottom: 1em;
    overflow: hidden;
    background-color: #fff; }
    .action-box .rep-results .mp.active {
      background-color: #021945;
      color: #fff; }
      .action-box .rep-results .mp.active .icon-check {
        transform: translateY(-50%) scale(1, 1);
        transform: translateY(-50%) scale3d(1, 1, 1); }
    .action-box .rep-results .mp:last-of-type {
      margin-bottom: 0; }
  .action-box .rep-results input[type=radio] {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
    position: absolute;
    left: -10000px; }
  .action-box .rep-results .headshot {
    width: 80px;
    height: 80px;
    float: left; }
    .action-box .rep-results .headshot img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%; }
  .action-box .rep-results .content {
    width: calc(100% - 80px);
    float: left;
    padding: .4em .65em .6em;
    text-align: left; }
    .action-box .rep-results .content .center {
      padding-top: .5em; }
    .action-box .rep-results .content .electorate {
      font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      text-transform: uppercase;
      display: block; }
    .action-box .rep-results .content .name {
      display: block;
      font-weight: normal; }
    .action-box .rep-results .content .icon-check {
      position: absolute;
      right: .6em;
      top: 50%;
      transition: 0.3s cubic-bezier(0.5, 0, 0, 1);
      transform: translateY(-50%) scale(1, 1);
      transform: translateY(-50%) scale3d(1, 1, 0); }

.action-box .submit-element + p {
  margin: 1em 0 0; }

section.section-split .container-fluid {
  max-width: 100%;
  display: block;
  float: left;
  width: 85.33333%;
  margin-left: 7.33333%;
  margin-right: -1%; }
  @media (min-width: 1200px) {
    section.section-split .container-fluid {
      display: block;
      float: left;
      width: 85.33333%;
      margin-left: 7.33333%;
      margin-right: -1%; } }
  @media (max-width: 1024px) {
    section.section-split .container-fluid {
      display: block;
      float: left;
      width: 85.33333%;
      margin-left: 7.33333%;
      margin-right: -1%; } }
  @media (max-width: 736px) {
    section.section-split .container-fluid {
      display: block;
      float: left;
      width: 102%;
      margin-left: -1%;
      margin-right: -1%; } }
  @media (max-width: 400px) {
    section.section-split .container-fluid {
      display: block;
      float: left;
      width: 102%;
      margin-left: -1%;
      margin-right: -1%; } }

section.section-split article.split-half > p {
  padding-left: 2vw;
  padding-right: 4vw; }

section.section-split aside.split-half {
  display: block;
  float: left;
  width: 50%;
  margin-left: 8.33333%;
  margin-right: 0;
  float: right !important; }
  @media (min-width: 1200px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 50%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (max-width: 1024px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 50%;
      margin-left: 8.33333%;
      margin-right: 0; } }
  @media (max-width: 736px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  @media (max-width: 400px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 100%;
      margin-left: 0%;
      margin-right: 0; } }
  @media (max-width: 736px) {
    section.section-split aside.split-half {
      float: left !important;
      display: block;
      float: left;
      width: 81.33333%;
      margin-left: 9.33333%;
      margin-right: 1%;
      margin-bottom: 3vh; } }
  @media (max-width: 736px) and (min-width: 1200px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 81.33333%;
      margin-left: 9.33333%;
      margin-right: 1%; } }
  @media (max-width: 736px) and (max-width: 1024px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 81.33333%;
      margin-left: 9.33333%;
      margin-right: 1%; } }
  @media (max-width: 736px) and (max-width: 736px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }
  @media (max-width: 736px) and (max-width: 400px) {
    section.section-split aside.split-half {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; } }

.action-complete {
  text-align: center;
  padding: 3.5vw;
  position: relative; }
  .action-complete:after {
    content: "";
    display: table;
    clear: both; }
  .progress + .action-complete {
    padding-top: 1vw; }
  .action-complete .text {
    margin-bottom: 2em; }
  .action-complete .social {
    font-size: 1.1rem; }
    .action-complete .social a {
      display: inline-block;
      padding: .5em .8em .5em .5em;
      margin: 0 .6em;
      font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-weight: bold;
      letter-spacing: 0.025em; }
  .action-complete .next-action {
    padding: 1rem;
    background: #2D2A2B;
    color: white;
    position: relative;
    overflow: hidden;
    margin: 1rem -3.5vw -3.5vw; }
    .action-complete .next-action .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
      .action-complete .next-action .image:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: black;
        opacity: .3; }
    .action-complete .next-action .title {
      text-shadow: 0 0 20px black; }
    .action-complete .next-action .button {
      color: white;
      background: rgba(0, 0, 0, 0.1); }
    .action-complete .next-action .header-meta {
      color: white;
      text-shadow: 0 0 20px black;
      margin-bottom: 1vh;
      font-weight: 600; }

.fancy-checkbox {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em; }
  .fancy-checkbox > div,
  .fancy-checkbox > div + label {
    display: inline-block; }
  .fancy-checkbox label {
    font-weight: 500; }

.field:not(.fancy-checkbox) + .fancy-checkbox {
  padding-top: 1em; }

.action-box {
  position: relative;
  top: -5vh;
  z-index: 1;
  float: none;
  display: inline-block; }
  @supports (position: sticky) {
    .action-box {
      position: sticky;
      top: 2em; }
      @media (max-width: 736px) {
        .action-box {
          top: auto;
          position: relative; } } }
  .action-box .milestone:not(.-right) {
    animation: milestoneRight 1s cubic-bezier(0.5, 0, 0, 1) forwards 2.5s;
    transform-origin: right;
    transform: translate(-100%, -20%) rotate(6deg);
    transform: translate3d(-100%, -20%, 0) rotate(6deg); }

@keyframes milestoneRight {
  to {
    transform: translate(-100%, 0);
    transform: translate3d(-100%, 0, 0);
    opacity: 1; } }
    .action-box .milestone:not(.-right):after {
      left: auto;
      right: -8px;
      transform: scale(-1, 1) rotate(45deg);
      transform: scale3d(-1, 1, 1) rotate(45deg); }

header.progress {
  padding: 3.5vw 3.5vw 0;
  margin-bottom: -1.5vw; }

.progress-bar {
  width: 100%;
  background: white;
  position: relative;
  height: 2em;
  z-index: 1; }
  .progress-bar:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid white;
    z-index: 1; }
  .progress-bar .percent {
    position: absolute;
    right: 0;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    top: calc(50% - .5em);
    right: .75em;
    color: #2D2A2B;
    letter-spacing: 0.1em; }
  .progress-bar .progress {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    overflow: hidden;
    padding: 0;
    z-index: 1;
    max-width: calc(100% - 4px); }
    .progress-bar .progress:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right, #F79020 20%, #fab56a);
      transform: translate(calc(-100% + 1em), 0);
      transform: translate3d(calc(-100% + 1em), 0, 0);
      animation: appear 2s cubic-bezier(0.5, 0, 0, 1) forwards 1s; }
    .progress-bar .progress span {
      position: absolute;
      top: 0.75em;
      right: 1.5em;
      opacity: 0;
      animation: appear 2s cubic-bezier(0.5, 0, 0, 1) forwards 3.25s; }
      .progress-bar .progress span:before, .progress-bar .progress span:after {
        content: '';
        position: absolute;
        border: 1px solid currentColor;
        box-sizing: content-box;
        border-radius: 50%;
        opacity: 1;
        transform: translate3d(0, 0.15em, 0); }
      .progress-bar .progress span:before {
        top: -2.4px;
        right: 19.2px;
        width: 3px;
        height: 3px;
        transition: box-shadow 1s linear;
        transform: translate3d(0, 0.2em, 0);
        box-shadow: -6px -3px 0 -3px white, -6px -3px 0 -1px currentColor; }
      .progress-bar .progress span:after {
        top: -9.75px;
        right: 12px;
        width: 4.2px;
        height: 4.2px;
        transform: translate3d(0, 0.3em, 0); }
    .progress-bar .progress i {
      position: absolute;
      right: 0.75em;
      top: .35em;
      opacity: 0;
      transform: translate(calc(-100% + 1em), 0);
      transform: translate3d(calc(-100% + 1em), 0, 0);
      animation: appear 2s cubic-bezier(0.5, 0, 0, 1) forwards 3s; }
  .progress-bar .milestone {
    display: inline-table;
    background: #F4F4F4;
    bottom: calc(100% + 2vw);
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: absolute;
    font-weight: 600;
    letter-spacing: .02em;
    font-size: 18px;
    font-size: 1.125/pxrem;
    z-index: 1;
    padding: .55em .75em;
    opacity: 0;
    animation: appear 1s cubic-bezier(0.5, 0, 0, 1) forwards 2.5s;
    transform-origin: bottom left;
    transform: translate(0, 20%) rotate(6deg);
    transform: translate3d(0, 20%, 0) rotate(6deg); }
    @media (max-width: 736px) {
      .progress-bar .milestone {
        display: none;
        padding: .35em .55em; } }
    .progress-bar .milestone:after {
      content: '';
      position: absolute;
      border: 8px solid transparent;
      border-right-color: #F4F4F4;
      bottom: -6px;
      left: -8px;
      transform: rotate(45deg); }
  .progress-bar + .caption {
    display: block;
    font-weight: 600;
    color: #2D2A2B;
    font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    letter-spacing: .05em;
    font-size: 18px;
    font-size: 1.125/pxrem;
    margin: .5em 0 2em; }
    @media (max-width: 736px) {
      .progress-bar + .caption {
        margin-bottom: 1em; } }
    .progress-bar + .caption em {
      font-style: normal;
      float: right; }

.card .progress,
.card-featured .progress {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  padding: 0.25rem 0.3rem; }
  @media (max-width: 736px) {
    .card .progress,
    .card-featured .progress {
      padding: 0.75rem; } }
  .card .progress .progress-bar,
  .card-featured .progress .progress-bar {
    overflow: hidden;
    height: 2.25em; }
    .card .progress .progress-bar:before,
    .card-featured .progress .progress-bar:before {
      content: none; }
  .card .progress .progress,
  .card-featured .progress .progress {
    top: 0;
    bottom: 0;
    left: 0; }
    .card .progress .progress i,
    .card-featured .progress .progress i {
      right: 1em;
      top: .5em;
      color: #2D2A2B; }
    .card .progress .progress span,
    .card-featured .progress .progress span {
      top: 1em;
      right: 1.75em;
      color: #2D2A2B; }
  .card .progress .milestone,
  .card-featured .progress .milestone {
    background: transparent;
    left: .75em !important;
    top: calc(50% - .5em);
    padding: 0;
    color: #2D2A2B; }
    .card .progress .milestone:after,
    .card-featured .progress .milestone:after {
      content: none; }
  .card .progress .caption,
  .card-featured .progress .caption {
    display: none; }

.action-tweet {
  position: relative; }
  .action-tweet:after {
    content: "";
    display: table;
    clear: both; }
  .action-tweet .action-tweet .icon-twitter {
    color: #F79020;
    font-size: 1.5em;
    margin-top: -.2em;
    display: inline-block;
    margin-right: .4em;
    margin-left: -.4em; }
    @supports not (display: flex) {
      .action-tweet .action-tweet .icon-twitter {
        margin-left: 0; } }
  .action-tweet .message {
    background: white;
    box-shadow: 0 0 5px rgba(45, 42, 43, 0.4);
    padding: 1rem 1rem 0.75rem; }
    .action-tweet .message small {
      color: #F79020;
      font-weight: 600;
      font-size: .9em;
      padding: 1em;
      background: #2D2A2B;
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      margin-bottom: 1em;
      display: inline-block; }
    .action-tweet .message p {
      display: flex;
      margin-bottom: 0;
      margin-top: 1.5em; }
      @media (max-width: 736px) {
        .action-tweet .message p {
          margin-top: 3em; } }
  .action-tweet .message .button {
    margin-top: 1em;
    float: right;
    margin-right: -0.25rem; }

.tweet-thanks {
  padding: 1rem 1rem 0;
  margin-bottom: -0.5rem; }
