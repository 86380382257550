.section {
	@include clearfix();
	// float:left;
	width:100%;
	// clear:both;
	padding: $spaceY * 2 0;
	position: relative;
	@include respond-to('mobile') { padding: $MBgutter*1.5 0; };

	&[id*="tfa"] {
		padding: 0;
	}

	&.section-split {
		@include container();
		float:none;
		padding-top: 0;

		.split-main {
			@include columns(6);
		}
		.split-aside {
			@include columns(4,1);
		}

		&.-donate {
			.split-main {
				@include columns(6);
			}
			.split-aside {
				@include columns(5);
				float:right;
				@include respond-to('tablet') {
					float:right;
				}
				@include respond-to('desktop') {
					float:right;
				}
			}
		}
	}

	.introduction {
		@include columns(6,3);
		text-align:left;
	}

	.content {
		position: relative;

		table:not([class*="woocommerce"]),
		table:not([class*="woocommerce"]) td {
			border: none;
		}
	}

	// ------------------------------------
	// Defaults
	// ------------------------------------

	.section-default {

		@include columns(8,2);

		.section-title { }

		.section-text { margin-top: 1em; }

		.section-buttons { margin-top: 1em; }

	}


	// Colour Options
	// ------------------------------------

	&.-default {
		&.featured-post-section {
			.card-featured {
				background-color:$baseColor;
			}
		}
	}

	&.-light {
		background-color:darken($baseColor,5%);
	}

	&.-primary {
		
		.introduction {
			color:white;
			h1,h2,h3,h4,h5,h6 {
				color:white;
			}
		}

		.card {
			color:inherit;
		}

		&.tab-section,
		&.feed-section,
		&.featured-post-section {
			padding:$spaceY * 4 0;
			background:$primaryColor;
			color: white;
			.card-featured .meta .caption,
			.card-featured .meta .sub {
				color:white;
			}
		}
	}

	&.-dark {

		&.tab-section,
		&.feed-section,
		&.featured-post-section {
			background:$textColor;
			color: white;
			.card-featured .meta .sub {
				color:white;
			}
		}
	}

}

.lightbg { background-color: #fff; }
.darkbg  { background-color: $baseColor; }

.lightbg + .lightbg { padding-top: 0; }
.darkbg + .darkbg { padding-top: 0; }
