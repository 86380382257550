.action-tweet {
    position: relative;
    @include clearfix;

    .action-tweet .icon-twitter {
        color: $primaryColor;
        font-size: 1.5em;
        margin-top: -.2em;
        display: inline-block;
        margin-right: .4em;
        margin-left: -.4em;
        @supports not (display:flex) { margin-left: 0 }
    }

    .message {
        // border-radius: $br;
        // overflow: hidden;
        background: white;
        box-shadow: 0 0 5px rgba($textColor,.4);
        padding: $gutter $gutter $gutter*.75;

        small {
            color: $primaryColor;
            font-weight: 600;
            font-size: .9em;
            padding: 1em;
            background: $textColor;
            position: absolute;
            // border-radius: $br $br 0 0;
            top: -1px;
            left: 0;
            right: 0;
            // text-transform: uppercase;
            margin-bottom: 1em;
            display: inline-block;
        }

        p {
            display: flex;
            // padding: $gutter*.25;
            margin-bottom: 0;
            margin-top: 1.5em;
            @include respond-to('mobile') { margin-top: 3em }
        }
    }

    .message .button {
        margin-top: 1em;
        float: right;
        margin-right: -$gutter*.25;
    }
}

.tweet-thanks {
    padding: $gutter $gutter 0;
    margin-bottom: -$gutter*.5;
}
