.gform_wrapper {
	background:$primaryColor;
	color:white;
	padding:2rem;
	@include respond-to('mobile') {
		padding:1.5rem;
	}
	
	form .gform_heading {
		.gform_title {
			margin-top:0;
		}
	}
	
	.gform_fields {
		.gfield {
			margin:0 0 1rem;
			padding-left:0;
			ul.gfield_checkbox li input[type=checkbox] {
				width:25px !important;
				height:25px;
				margin-top:0;
				border:2px white solid;
			}
			ul.gfield_radio li {
				label {
					float:none;
				}
				input[type=radio] {
					width:25px !important;
					height:25px;
					margin-top:0;
				}
			}

			textarea, select,
			input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="image"], input[type="color"] {
				margin-bottom:.25rem;
				
				border:2px white solid;
			}

			.ginput_container {
				margin-top:0;
				margin-bottom:1rem;
			}

			&.field_description_below {
				.ginput_container {
					margin-bottom:.5rem;
				}
				.gfield_description {
					padding: 0 0 1rem;
				}
			}

			&.half {
				float:left;
				width:50%;
				clear:none;
				input {
					width:100%;
				}
			}	

			&.third {
				float:left;
				width:33.3%;
				clear:none;
				input {
					width:100%;
				}
			}
		}
	}
	
	form .gform_footer {
		padding-bottom:0;
		margin-bottom:0;
		input[type=submit] {
			margin-bottom: 0;
			@include button-style(white,  white, $primaryColor);
			&:last-of-type {
				margin-top: 0;
				margin-bottom:0;
			}
		}
	}
	
}

// GF Datepickers

// .ui-datepicker-title 
	#ui-datepicker-div { 
		background: #FFF; 
		border: 1px solid #ddd; 
		border-radius:4px; 
		box-shadow:0 1px 1em rgba(0,0,0,0.2); 
		display:none; 
		padding:20px; 
		width:300px; 
		
		
		.ui-datepicker-title { 
			select {
				font-weight:bold;
				font-size: .7rem;
				width: auto;
				display: inline-block;
				margin: .1rem .25rem .25rem;
				border:none;
			}
		}
		
		.ui-datepicker-today { 
			background: $textColor; 
			color:white;
			border-radius: 0;
			border:none;
			a { 
				// color: #FFF; 
				// background-color: $primaryColor; 
				color:white;
				width:auto;
				height:auto;
				text-shadow:none;
			}
		}

		.ui-state-active {
			border:none; 
			background-color: $primaryColor;
			color:white;
		}
		
		table { 
			width: 100%; 
			margin:0;
			border:none;
			display:table;
			td, th { 
				display:table-cell;
				text-align: center; 
				border:none;
				width:auto !important;
				margin:0;
			}
			td {
				a {
					display: block;
					margin:0;
					width:auto;
					height:auto;
					// padding: 5px; 
				}
			}
		} 
		
	}
	