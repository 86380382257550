// Custom Buttons
// -------------------

@mixin button-style($customColor, $customTextColor, $hoverColor: darken($customTextColor, 10%)){
	
	display: inline-block;
	
	border: none;
	appearance: none;
	outline: none;
	letter-spacing: 0.025em;
	line-height: 100%;
	vertical-align: middle;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	font-family: $headingFont;
	font-weight:bold;
	transition: .4s $easeOut;
	position: relative;
	will-change: background-color, color;

	color: $customTextColor;

	border: 2px $customColor solid;
	transition: .4s $easeOut;
	background: $customColor;
	will-change: background-color, color;

	&:hover,
	&:focus {
		background: darken($customColor,5%);
		border-color: darken($customColor,10%);
		color: $hoverColor;
	}

	&.disabled {
		cursor: not-allowed;
		box-shadow: inset 0 0 0 2px $customColor;
		background-color: transparent;
		color: rgba($customColor, .75);

		&:before,
		&:after {
			content: none;
		}
	}
}

// Breakpoints
// --------------------------------------------------

@mixin respond-to($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media #{inspect(map-get($breakpoints, $breakpoint))} {
			@content;
		}
	}

	@else {
		@error 'No value found for `#{$breakpoint}`. '
				+ 'Please make sure it is defined in `$breakpoints` map.';
	}
}

// Shortcuts
// -------------------

@mixin size($height, $width) {
	width: $width;
	height: $height;
}

@mixin hide-text() {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
}

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin reset-center() {
	position: relative;
	top: auto;
	left: auto;
	transform: none;
}

@mixin center-vertically() {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin reset-center-vertically() {
	position: relative;
	top: auto;
	transform: none;
}

@mixin clearfix-float(){
    float: left;
    width: 100%;
}

@mixin iconify($content){
    content: $content;
    font-family: "entypo";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
}

@mixin placeholder {
	::placeholder               {@content}
	::-webkit-input-placeholder {@content}
	:-moz-placeholder           {@content}
	::-moz-placeholder          {@content}
	:-ms-input-placeholder      {@content}
}

@mixin font-size($targetSize: 16) {
    font-size: $targetSize + px;
    font-size: ($targetSize / $baseFontSize) + rem;
}

@mixin responsive-font-size($targetSize: 16, $ratio: .6) {
    font-size: $targetSize + px;
    font-size: ($targetSize / $baseFontSize) + rem;

	@include respond-to('tablet') {
		font-size: ($targetSize  * ($ratio * 1.1)) + px;
		font-size: ($targetSize / $baseFontSize * ($ratio * 1.1) ) + rem;
	}

	@include respond-to('mobile') {
		font-size: ($targetSize * $ratio) + px;
		font-size: ($targetSize / $baseFontSize * $ratio) + rem;
	}
}


@mixin line-height($targetSize: 16) {
    line-height: $targetSize + px;
    line-height: ($targetSize / $baseFontSize) + rem;
}

// Useful Visual Elements
// -------------------

@mixin arrow-up($size: 5px, $color: #000) {
	width: 0;
	height: 0;
	border-left: $size solid transparent;
	border-right: $size solid transparent;
	border-bottom: $size solid $color;
}
@mixin arrow-down($size: 5px, $color: #000) {
	width: 0;
	height: 0;
	border-left: $size solid transparent;
	border-right: $size solid transparent;
	border-top: $size solid $color;
}
@mixin arrow-left($size: 5px, $color: #000) {
	width: 0;
	height: 0;
	border-top: $size solid transparent;
	border-bottom: $size solid transparent;
	border-right: $size solid $color;
}
@mixin arrow-right($size: 5px, $color: #000) {
	width: 0;
	height: 0;
	border-top: $size solid transparent;
	border-bottom: $size solid transparent;
	border-left: $size solid $color;
}

@mixin full($pos:absolute, $unit:0){
	position: $pos;
	top: $unit;
	right: $unit;
	bottom: $unit;
	left: $unit;
}

@mixin seperator($color) {

	&:before {
		content: '';
		width: 4px;
		height: 4px;
		margin: 0 1vw 0 .5vw;
		// vertical-align: middle;
		display: inline-block;
		position: relative;
		border-radius: 50%;
		border: 2px solid $color;
		opacity: 0.25;
	}
}

@mixin clearList {
	padding: 0;
	margin: 0;

	li {
		padding-left: 0;
		&:before { display: none }
	}
}
