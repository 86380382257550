
.filter-dropdown {
	position: relative;
	z-index: 2;
	text-align: left;
	min-width: 15em;
	height: 3rem;
	float: right;
	flex: 0 0 27vw;
	align-self: flex-end;
	margin-left: auto;
	outline: none;
	@supports not (display : flex) { max-width: 27vw }
	@include respond-to('mobile') { 
		flex: auto; 
		margin-left: 0; 
		width: 100%; 
	}
	
	+ .filter-dropdown {
		margin-left: 1em;
		@include respond-to('mobile') { 
			// margin-left: 0; 
			margin-top: 1em; 
		}
	}
	
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	
	.active {
		padding: .9em;
		border: 2px solid $primaryColor;
		background: white;
		display: inline-block;
		width: 100%;
		outline: none;
		cursor: pointer;
		height: 3rem;
	}
	
	ul {
		position: absolute;
		left: 0;
		right: 0;
		pointer-events: none;
		opacity: 0;
		top: calc(100% - 2px);
		background: white;
		border: 2px solid $primaryColor;
		border-top-color: lighten($primaryColor,15);
		transition: .3s;
		
		&:hover {
			opacity: 1;
			pointer-events: all;
			z-index: 3;
		}
		
		li {
			cursor: pointer;
			
			&:not(:last-of-type) {
				border-bottom: 2px solid lighten($primaryColor,15);
			}
			
			a {
				padding: .75em 1em;
				display: block;
				transition: .3s $easeOut;
				font-size:.8em;
				text-transform:uppercase;
				background:lighten($primaryColor,23);
				&.-filtered {
					background: $primaryColor;
					color:white;
					font-size:1em;
					text-transform: none;
					&:hover,
					&:focus {
						background: darken($primaryColor,8);
					}
				}
				
				&:hover,
				&:focus {
					background: lighten($primaryColor,15);
				}
			}
			
			ul {
				margin:0;
				padding:0;
				position:relative;
				border-left:none;
				border-right:none;
				border-bottom:none;
				li {
					a {
						padding: .75em 1em;
						display: block;
						transition: .3s $easeOut;
						font-size:1em;
						text-transform:none;
						background:transparent;
						
						&.-filtered {
							background: lighten($primaryColor,23);
						}
						
						&:hover,
						&:focus {
							background: lighten($primaryColor,15);
						}
					}
				}
			}
			
		}
	}
	
	.active {
		position: relative;
		overflow: hidden;
		
		&.active {
			
			&:after {
				content: '';
				position: absolute;
				top: calc(50% - 6px);
				right: calc(1.4em - 6px);
				width: 8px;
				height: 8px;
				border-left: 2px solid  $textColor;
				border-bottom: 2px solid  $textColor;
				z-index: 3;
				transform: rotate(-45deg);
				transition: .4s $easeOut;
			}
		}
	}
	
	&.-active {
		z-index: 3;
		
		.active {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			
			&:after {
				transform: translate(0,30%) rotate(135deg);
				transform: translate3d(0,30%,0) rotate(135deg);
			}
		}
		
		ul {
			opacity: 1;
			pointer-events: all;
			z-index: 3;
			max-height: 20em;
			overflow-y: auto;
		}
	}
}
