.feed-section {
	text-align: center;
	@include respond-to('mobile') { padding: 5vw; }

	&.section .introduction {
		@include columns(11,.5);
		text-align:center;
	}

	&.-press {
		background:$baseColor;
		padding:4rem 0;
	}

	&.-post {
		.feed {
			.card {
				background:white;
				box-shadow:0 2px 5px rgba($textColor,.1);
				min-height:22rem;
				padding-bottom:1rem;
				> a {
					box-shadow:none;
					background:transparent;
					padding-bottom:0;
				}
			}
		}
	}
	
	.feed {
        @include columns(11,0.5);
		@include clearfix;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 1;
		
		@include respond-to('mobile') {
			display: block;
			flex-wrap: none;
			padding: 0 $MBgutter;
			overflow: visible;
		}
		
		.slick-list {
			overflow: visible;
		}
		
		.card {
			margin-right: 2.9vw;
			margin-bottom: 2.9vw;
			margin-top: 2%;
			width: calc(25% - 2.9vw);
			@include respond-to('tablet') { width: calc(50% - 1.7vw); };
			@include respond-to('mobile') { width: 100%; margin-bottom: 5vw; };
			
			&.-search {
				margin-top:0;
				width: calc(50% - 2.9vw);
				&:nth-child(2n+1) {
					clear:both;
				}
				@include respond-to('mobile') {
					width:100%;
				}
			}

			&:nth-child(4n+1) {
				clear:both;
			}

			&.has-meta ~ .card,
			&.has-meta {
				margin-top: 2.3em;
			}
			
			&.slick-slide {
				@include respond-to('mobile') {
					width: calc(100% - #{$MBgutter});
					margin-right: $MBgutter*.5;
					margin-left: $MBgutter*.5;
					padding-bottom: 4vh;
					
					&:nth-of-type(2n) {
						margin-right: $MBgutter*.5;
					}
				}
				
				h6 {
					@include respond-to('mobile') {
						text-align: center;
						padding-top: 4vh;
					}
				}
			}
			
			
			@include respond-to('tablet') {
				
				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
		}
		
		~ .button {
			margin-top: 5vh;
		}
	}
	
	.feed-footer {
		@include columns(12);
		text-align: center;
		
		.button {
			margin-right: 0;
		}
	}
	
	.feed-more {
        @include columns(11,0);
		text-align:right;
		a {
			font-weight:bold;
			color:$primaryColor;
		}
	}

	// Colour Options
	// ------------------------------------
	
	&.-secondary {
		background: $baseColor;
	}
	
	&.-dark {
		

	}
}

.feed-section-empty {
	text-align:center;
	.content {
		@include columns(10,1);
	}
}