// Clearance for mobile menus
body {
	@include respond-to('tablet') {
		padding-top:3rem;
	}
}

.primary-navigation {
	width:100%;
	position:absolute;
	z-index:9;
	height:4rem;
	margin:2rem 0;
	@include respond-to('tablet') {
		background:white;
		margin:0;
		position:fixed;
		z-index:10;
		height:3.5rem;
		border-bottom:1px $baseColor solid;
		transition:height 500ms $easeOut;
		top:0;
		width:100%;
		padding:0;
	}

	.container {
		position:relative;
	}

	// Content
	// ----------------------------

	.content {
		background:$baseColor;
		box-shadow:$shadow;
		width:100%;
		height:4rem;
		padding-left:10rem;
		position:relative;
		z-index:9;
		@include respond-to('tablet') {
			padding-left:0;
			width:100%;
		}
	}
	
	// Logo
	// ----------------------------

	.logo {
		display: block;
		float: left;
		position: absolute;
		top: -1rem;
		left: 0rem;
		background: #F4F4F4;
		width: 11rem;
		height: 6rem;
		background-size: auto 5rem;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		border-radius: 4px;
		z-index: 2;
		box-shadow: 0 0 6px rgba(0,0,0,0.2);

		&.-circular {
			top: -1.5rem;
			left: -1rem;
			background: #F4F4F4;
			width: 8rem;
			height: 8rem;
			background-size: auto 7rem;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: 5rem;
			box-shadow: 0 0 6px rgba(0,0,0,0.2);
			@include respond-to('tablet') {
				top: 0;
				left: 0;
				width: 7rem;
				height: 7rem;
				background-size: auto 6rem;
			}
		}

		@include respond-to('tablet') {
			left: 0;
			top: 0;
			width: 8rem;
			height: 4rem;
			background-size: auto 4rem;
			background-color:transparent;
			box-shadow:none;
		}

		span {
			display:none;
		}

	}
	
	
	// Menu Items
	// ----------------------------
	.menu {
		@include columns(8);
		
		@include respond-to('tablet') {
			width: 100%;
			margin:0;
			max-height: 0px;
			overflow: hidden;
			display: block;
			position: fixed;
			transform: none;
			top: 0;
			left: 0;
			z-index: 5;
		}
		
		&.-active {
			@include respond-to('tablet') {
				display: block;
				max-height: 15000px;
				
				.social {
					pointer-events: all;
					opacity: 1;
				}
				
				div:last-of-type {
					pointer-events: all;
					max-height: 15000px;
					
					&:before {
						opacity: 1;
						transition-delay: .2s;
					}
				}
				// overflow-y: auto;
				// -webkit-overflow-scrolling: touch;
			}
			
			+ .mobile-nav-action {
				width: calc(45px + 4em);
				overflow: hidden;
				opacity: 0;
				transform: translate(0,0);
				transform: translate3d(45%,0,0);
			}
			
			ul[class*="-header"] > li {
				@include respond-to('tablet') {
					opacity: 1;
					transform: translate(0,0);
					transform: translate3d(0,0,0);
					transition: .6s $easeOut;
					
					@for $i from 1 to 10 {
						&:nth-of-type(#{$i}) {
							transition-delay: .05s*$i;
						}
					}
				}
			}
		}
		
		ul[class*="-header"] {
			
			width: 100%;
			padding: 0 $gutter*.6;
			justify-content: space-between;
			text-align: left;
			
			@include respond-to('tablet') {
				padding: 0;
				flex-direction: column-reverse;
				border-radius: 0 0 1em 1em;
				overflow: hidden;
			}
			
			> li {
				display: inline-block;
				font-family: $headingFont;
				font-size:.9rem;
				font-weight:bold;
				margin:0;
				height: 2.5rem;

				@include respond-to('tablet') {
					height:auto;
				}
				
				&:not(.button) {
					// margin-right: 1vw;
				}
				
				> a {
					padding: 1.35rem $spaceY / 2;
					line-height: 1.35;
					display: block;
					position: relative;
					text-transform: uppercase;
					&:hover,
					&:focus {
						border-bottom:2px $primaryColor solid;

						@include respond-to('tablet') {
							border-bottom:none;
						}
					}
					
					@include respond-to('tablet') {
						padding: $MBgutter 0;
					}
				}
				
				&.button {
					@include font-size(18);
					min-width: 8em;
					text-align: center;
					margin: -.1rem 0 0 .5em;
					padding: 0;
					flex-grow: 1;
					transition:none;
					
					+ .button {
						margin-left: 1vw;
						@include respond-to('tablet') { margin-left: 0 }
					}

					&:hover,
					&:focus {
						background:transparent;
					}
					
					
					@include respond-to('tablet') {
						border-radius: 0;
						box-shadow: none;
						text-align: left;
						text-transform: uppercase;
						padding: 0 $MBgutter;
						@include font-size(18);
						
						.submenu-toggle {
							top: $MBgutter;
						}
						
					}
					
					&.-action {
						border: none;
					}
					
					> a {
						padding: .65em 1.5em 0.65em 1.5em;
						
						@include button-style($primaryColor, $primaryColor);
						
						i {
							margin-right: 0.5em;
							
							&.icon-turtle {
								margin-right: .75em;
								margin-left: -.25em;
							}
						}
						
						@include respond-to('tablet') {
							padding: $MBgutter 0;
						}
					}
					
				}
				
				&.current_page_item a:before,
				&:not(.button) a:hover:before,
				&:not(.button) a:focus:before {
					opacity: .5;
				}



				&:hover,
				&:focus {
					ul {
						height:auto;
						max-height:100vh;
						padding:1.5rem 0 .5em;
						@include respond-to('tablet') {
							padding:0;
						}
						li {
							opacity:1;
						}
					}
				}

			}
			
			// Sub Menu
			> li > ul {
				$width: 300px;
				position: absolute;
				z-index: 1;
				width: $width;
				left: 0;
				color: $baseColor;
				border-radius: $br;
				padding: 0;
				margin: 0;
				text-align: left;
				top: 4rem;
				background:$baseLightColor;
				box-shadow:$shadow;
				height:0;
				max-height:0;
				transition: 200ms;
				
				@include respond-to('tablet') {
					position: relative;
					width: 100%;
					left: auto;
					-ms-transform: none;
					transform: none;
					padding: 0;
					display: block;
					top: auto;
					text-align: center;
					height: auto;
					max-height: 100%;
					overflow: visible;
					box-shadow: none;
					background: transparent;
				}
				
				&:after {
					content: '';
					@include full;
					top: 1em;
					transform-origin: top;
					background: currentColor;
					border-radius: $br;
					z-index: -1;
					opacity: 0;
					transition: .4s $easeOut;
					transform: scale(.5,.5);
					transform: scale3d(.5,.5,1);
				}
				
				&:before {
					$unit: 16px;
					content: '';
					position: absolute;
					width: $unit;
					height: $unit;
					border-radius: $br*.3;
					top: calc(1em + #{-$unit/2.3});
					left: calc(50% - #{$unit/2});
					background: currentColor;
					opacity: 0;
					transition: .3s $easeOut;
					transform: scale(.4,.4) rotate(45deg);
					transform: scale3d(.4,.4,1) rotate(45deg);
				}
				
				&:hover {
					
					li a {
						opacity: .6;
					}
				}
				
				li {
					display: block;
					padding: .5em 1em;
					opacity: 0;
					transform: translate(0,-.25em);
					transform: translate3d(0,-.25em,0);
					transition: .3s $easeOut;
					
					@include respond-to('tablet') {
						opacity: 1;
						transform: none;
						padding: .5em .5em;

					}
					
					a {
						font-weight: 500;
						display: block;
						color: $textColor;
						transition: .3s $easeOut;
						
						@include respond-to('tablet') {
							font-weight: 400;
						}
						
						&:hover {
							opacity: 1;
						}
					}
					
					ul {
						display: none;
					}
				}
			}
			
			> li a:hover ~ ul,
			> li a:focus ~ ul,
			> li ul:hover {
				
			}
			
			> li.-open ul {
				transition: 0s;
				pointer-events: all;
				transform: none;
				@include respond-to('tablet') {
					padding-bottom: $MBgutter;
					display: block;
				}
				
				max-height: 10000px;
				
				li {
					opacity: 1;
					transform: translate(0,0);
					transform: translate3d(0,0,0);
					transition: .6s $easeOut;
					
					@for $i from 1 to 10 {
						&:nth-of-type(#{$i}) {
							transition-delay: .05s*$i;
						}
					}
				}
			}
			
			a {
				@include respond-to('tablet') {
					font-size: 18px;
				}
			}
		}
	}

	// Actions

	.actions {
		padding:.75rem 1rem .75rem .75rem;
		text-align:right;
		.search {
			width:7em;
			margin:0 1rem;
			display:inline-block;
			position:relative;
			@include respond-to('desktop') {
				width:12em;
			}
			.submit {
				position:absolute;
				top:.6rem;
				left:.5rem;
				padding:0;
				background:none;
				border:none;
			}
			input {
				border:none;
				padding:.5rem .5rem .5rem 2rem;
				font-size:1rem;
			}
		}

		@include respond-to('tablet') {
			display:none;
			color:$textColor;
			text-align:center;
			.search {
				opacity:1;
				// padding:0;
				margin:1rem 0 0;
				width:20rem;
				position:relative;
				.submit {
					margin:0;
					left:0;
				}
				input {
					border:1px $baseDarkColor solid;
				}
			}
			.button {
				display:block;
				clear:both;
				margin:1em auto;
				max-width:20rem;
			}
		}


	}
	
}


//
// Hamburger
// --------------------------------------------------

button.ham {
	$unit: 0.4s;
	
	width: 100%;
	max-width: 52px;
	height:3.5rem;
	min-width: auto !important;
	display: block;
	
	margin: 0;
	padding: 1.4rem 0;
	border: none;

	cursor:pointer;
	
	position: absolute;
	top:0;
	right:1.5rem;
	// top:$spaceY * 2;
	z-index: 20;
	background:transparent;	
	display: none;
	
	&:focus,
	&:active {
		outline: none;
		border: none;
		cursor:pointer;
		background-color:transparent;
	}
	
	@include respond-to('tablet') {
		display: block;
	}
	
	span {
		width: 1.8rem;
		height: 2px;
		position: absolute;
		left: 0;
		left: 15px;
		right: 0;
		top: calc(50% - (#{$underline} * 0.5 / 2));
		
		&:nth-of-type(1),
		&:nth-of-type(3) {
			
			&:before,
			&:after {
				content: '';
				@include full;
				background: $textColor;
			}
			
			&:before {
				transition: transform $unit $easeOut $unit;
			}
			
			&:after {
				transition: transform $unit*.8 $easeOut $unit;
			}
		}
		
		&:nth-of-type(1) {
			transition: transform $unit*1.1 $easeOut;
			
			&:before,
			&:after {
				transform: translate3d(0,-350%,0);
			}
		}
		
		&:nth-of-type(2) {
			background: $textColor;
			transition: opacity 0s $unit;
		}
		
		&:nth-of-type(3) {
			transition: transform $unit $easeOut;
			
			&:before,
			&:after {
				transform: translate3d(0,350%,0);
			}
		}
	}
	
	&.-active {
		
		span {
			&:nth-of-type(1),
			&:nth-of-type(3) {
				
				&:before,
				&:after {
					// background: #fff;
				}
			}
		}
		
		span:nth-of-type(1) {
			transition: transform $unit $easeOut;
			transform: rotate(45deg) translate3d(0,0,0);
			
			&:after,
			&:before {
				transform: translate3d(0,0,0);
			}
		}
		
		span:nth-of-type(2) {
			opacity: 0;
		}
		
		span:nth-of-type(1),
		span:nth-of-type(3) {
			transition: transform $unit $easeOut $unit;
			
			&:before {
				transition: transform $unit $easeIn;
			}
			
			&:after {
				transition: transform $unit*.8 $easeIn;
			}
		}
		
		span:nth-of-type(3) {
			transition: transform $unit*1.2 $easeOut $unit*1.1;
			transform: rotate(-45deg) translate3d(0,0,0);
			
			&:after,
			&:before {
				transform: translate3d(0,0,0);
			}
		}
		
		~ .menu-primary-navigation-container {
			left: 0;
			opacity: 1;
			transition: opacity 0.8s $easeOut, left 0s;
			
			.menu-primary {
				transform: translate3d(0,0,0);
				transition: transform 0.8s $easeOut;
			}
		}
	}
	
	
	@include respond-to('mobile-s') {
		
		&.active {
			
			span:nth-of-type(1) {
				transform: rotate(45deg) scale3d(.8,1,1) translate3d(0,0,0);
			}
			
			span:nth-of-type(3) {
				transform: rotate(-45deg) scale3d(.8,1,1) translate3d(0,0,0);
			}
		}
	}
}

.mobile-nav-action {
	width: auto;
	height: 45px;
	background-color: $actionColor;
	line-height: 45px;
	float: right;
	display: inline-block;
	padding: 0 1.5em;
	position: absolute;
	z-index: 20;
	padding-left: 20px;
	
	right: $spaceY * 4;
	top:$spaceY * 2;
	
	font-family: $headingFont;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 1px;
	
	display: none;
	
	transition: .3s $easeOut;
	transform: translate(0,0);
	transform: translate3d(0,0,0);
	
	i {
		margin-right: 0.5em;
		
		&:before {
			margin-left: 0;
		}
	}
	
	@include respond-to('tablet') {
		display: block;
		right: $spaceY * 4;
	}
}


.submenu-toggle {
	width: 1em;
	height: 1em;
	position: relative;
	float: right;
	margin-left: 14px;
	color: rgba(255,255,255,0.7);
	
	position: absolute;
	right: $MBgutter;
	top: $MBgutter;
	
	display: none;
	
	@include respond-to('tablet') {
		display: inline-block;
	}
	
	&:before {
		content: '\e86c';
		font-family: $iconFont;
		position: absolute;
		top: 2px;
		left: 0;
		font-size: 24px;
		
		color: $textColor;
	}
	
	li.menu-item-has-children.-open & {
		&:before {
			transform: rotate(180deg);
		}
	}
}

body.menu-active {
	overflow:hidden;
	height:100vh;
	
	// .support-navigation {
		// display:none;
		// position:fixed;
		// top:50vh;
	// }

	.primary-navigation {
		// position:fixed;
		top:0;
		height:100vh;
		overflow:auto;
		// position:relative;
		width:100%;
		display:block;
		float:none;
		margin:0;
		z-index:9;
		padding:0 0 15rem;

		&:after,
		&:before {
			display:none;
		}
		.logo {
			img {
				// margin-top:1rem;
			}
		}
		.ham {
			// display:block;
			// top:.5rem;
		}
		.container {
			position:relative;
		}
		.menu, 
		.menu-primary-container {
			position:relative;
			max-height:none;
			padding-top:2rem;
			background:white;
			// overflow:auto;
			&:after,
			&:before {
				display:none;
			}
		}
		.actions {
			display:block;
			position:relative;
		}
	}
	.primary-navigation #menu-primary {
		// position:fixed;
		// overflow:visible;
		// top:5rem;
		// left:0;
		// float:none;
		// height:calc(100vh - 8rem);
		position:relative;
		border-radius:none;
		max-height:none;
		display: block;
		pointer-events:all;
		overflow:visible;
		height:auto;
		z-index:9;
		> li {
			width:100%;
			position:relative;
			display: block;
			opacity:1;
			text-align:center;
			max-width:20em;
			margin:0 auto;
			a {
				display: block;
				position:relative;
				// cursor:pointer;
				&:hover,
				&:focus {
					background:orange;
				}
			}
			&.button {
				margin-top:.5em;
				a {
					&:hover,
					&:focus {
						background:transparent;
					}
				}
			}
		}
	}
}