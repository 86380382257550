
.woocommerce-pagination,
.pagination {
    text-align: center;
    margin: $spaceY * 2 0;

    ul {
        display: block;
        text-align: center;

        li {
            display: inline-block;
            vertical-align: middle;
        }
    }

    li > *,
    .page-numbers:not(ul) {
        display: inline-block;
        text-align: center;
        @include font-size(20);
        font-family: $headingFont;
        color: $textColor;
        font-weight: 600;

        width: 2.2em;
        height: 2.2em;
        padding: .6em;
        position: relative;


        &:before {
            content: '';
            position: absolute;
            top: 15%;
            right: 15%;
            bottom: 15%;
            left: 15%;
            border-radius: 50%;
            box-shadow: 0 0 0 2px $baseColor;
            opacity: 0;
            transition: .4s $easeOut;
        }

        &.current:before {
            opacity: 1;
            @include full;
            box-shadow: 0 0 0 2px $primaryColor;
        }

        &:hover:before,
        &:focus:before {
            opacity: 1;
        }

        &.prev,
        &.next {
            width: 1.5em;
            height: 1.5em;
            margin: 0 .75em;
            // background: $baseColor;
            color: transparent;
            border-radius: 50%;

            &:after {
                $unit: 8px;
                content: '';
                position: absolute;
                width: $unit;
                height: $unit;
                opacity: 1;
                top: calc(50% - #{$unit/2});
                left: calc(50% - #{$unit/2});
                border-top: 2px solid $textColor;
                box-sizing: border-box;
            }

            &.prev:after {
                border-left: 2px solid $textColor;
                transform: translate3d(15%,0,0) rotate(-45deg);
            }

            &.next:after {
                transform: translate3d(-25%,0,0) rotate(45deg);
                border-right: 2px solid $textColor;
            }
        }
    }

    a.page-numbers {

        // margin: 0 -.1em;

        width: 2em;
        height: 2em;
        padding: .6em;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        line-height: .9;
        transition: .3s $easeOut;
        z-index: 1;
        border-radius: 50%;
    }
}
