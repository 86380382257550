.card.-press {

	a {
		background:white;
		box-shadow:0 2px 5px rgba($textColor,.1);
	}

	.image {
		display:none;
	}

	.action {
		display:none;
	}

	.title {
		padding:.5rem;
		font-weight:bold;
	}
	.date {
		text-transform: none;
		letter-spacing:0;
		padding:0 .5rem;
	}
	
} 

.feed-section .feed {
	margin-bottom:2rem;
	.card.-press,
	.card.has-meta.-press,
	.card.has-meta.-press ~ .card {
		width: calc(50% - 2.9vw);
		@include respond-to('mobile') {
			width:100%;
		}
		margin-top:0;
		margin-bottom:1rem;
	}
}