
.card-action {
    height: 40vw;
    min-height: 300px;
    max-height: 500px;
    background: $baseColor;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .action-content {
        position: absolute;
        right: $gutter*.75;
        bottom: 0;
        left: $gutter*.75;
        padding: $gutter*.75;
        transform: translate(0,4em);
        transform: translate3d(0,4em,0);
        transition: .4s $easeOut;

        @include respond-to('tablet') {
            width:90%;
        }
        @include respond-to('mobile') {
            padding: $MBgutter*.75;
            left: $MBgutter*.75;
            padding: $MBgutter*.75;
            transform: none;
        }

        h4, p {
            position: relative;
        }

        p {
            @include font-size(16);
        }

        h4 {
            margin-bottom: .8rem;
            // height: 3.3em;

            @include respond-to('mobile') {
                height: auto;
            }
        }

        &:before {
            content: '';
            @include full;
            opacity: .96;
            transition: .6s $easeOut;
            background: linear-gradient(40deg, currentColor 60%, transparent) no-repeat bottom / 300% 100%;
        }
    }

    .image {
        @include full;
    }

    .card-content {
        opacity: 0;
        height: 3em;
        transition: .6s $easeOut;
        transform: translate(0,.5em);
        transform: translate3d(0,.5em,0);
        p:first-of-type {
            display: none;
        }

        @include respond-to('mobile') {
            height: auto;
            transform: none;
            opacity: 1;
        }
    }

    &:hover,
    &:focus {

        .action-content {
            transform: translate(0,0);
            transform: translate3d(0,0,0);

            &:before {
                opacity: 1;

                @at-root .-content & {
                    transform: scale(1.2,1.2);
                    transform: scale3d(1.2,1.2,1);
                }
            }

            &:after {
                opacity: .3;
                transform: scale(1.2,1.2);
                transform: scale3d(1.2,1.2,1);
            }
        }

        .card-content {
            opacity: 1;
            transform: translate(0,0);
            transform: translate3d(0,0,0);
        }
    }


    // Style Variants
    // -------------------


    &.action-1 .action-content {
        width: calc(50% - #{$gutter*.75});
    }

    &.action-2 {
        width: 50%;
        float: left;
        @include respond-to('mobile') {
            width: 100%;
            min-height: auto;
            height: 40vh;
        }
    }

    &.action-3 {
        width: 33.333%;
        float: left;

        @include respond-to('mobile') {
            width: 100%;
            min-height: auto;
            height: 35vh;
        }

        h4 {
            @include font-size(24);
            @include respond-to('mobile') { height: auto }
            height: 3.5em;

            @at-root .-content & {
                @include font-size(19);
                height: auto;
                max-height: 3.5em;
            }
        }
    }

    @at-root .-content & {

        @include respond-to('mobile') {
            height: auto;
        }
    }


    &.-default  {
        color: white;

        .action-content:before,
        &:before { color: white }

        .action-content {
            color: $secondaryColor;
        }
    }

    &.-light  {
        color: white;

        .action-content:before,
        &:before { color: white }

        .action-content {
            color: $secondaryColor;
        }
    }

    &.-dark {
        color: $textColor;

        .action-content:before,
        &:before { color: $textColor }

        .action-content {
            color: $actionColor;

            &:after {
                background: $textColor;
            }

            p,
            .button,
            a {
                color: white;
            }
        }
    }

    &.-primary {
        color: $primaryColor;

        .action-content:before,
        &:before { color: $primaryColor }

        .action-content {
            color: $textColor;

            &:after {
                background: $primaryColor;
            }

            .button {
                @include button-style(white,$secondaryColor);
            }
        }
    }

}
