
.section.join-section {
	padding: $spaceY * 2 0;

	.content {
		@include columns(12,0);
		padding:3rem 45% 2rem 2rem;
        background:$primaryColor;
        color:$textColor;
        text-align:center;
        h1,h2,h3,h4,h5,h6 {
            color:$textColor;
        }
        @include respond-to('mobile') {
            padding:1rem;
        }
    }
    
    &.-narrow {
        @include columns(8,2);
    }

    .image {
        position: absolute;
        top: -$gutter*.25;
        right: 0;
        bottom: -$gutter*.25;
        width:42%;
		overflow: hidden;

        @include respond-to('mobile') {
            width: 100%;
            height: 30vh;
            margin-bottom:2rem;
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
        }

        > span {
            @include full;
            transform: scale(1.05,1.05);
            transform: scale3d(1.05,1.05,1);
            transition: 3s;
            background-size:cover;
            background-position:center center;
        }
    }

}
