.-content .card-action {

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: $gutter*.75;
        left: $gutter*.75;
        height: 2rem;
        z-index: 1;
        opacity: 1;
        background: linear-gradient(to top, currentColor, transparent);

        @include respond-to('mobile') {
            right: $MBgutter*.75;
            left: $MBgutter*.75;
        }
    }

    .action-content {
        top: $gutter*.75;
        transform: translate(0,30vh);
        transform: translate3d(0,30vh,0);
        // transform: translate(0,16em);
        // transform: translate3d(0,16em,0);
        transition: .6s $easeOut;
        height: 100%;

        &:after {
            content: '';
            @include full;
            opacity: 0;
            background: white;
            z-index: -1;
            transition: .6s $easeOut;
        }

        @include respond-to('tablet') {
            left: auto;
            right: auto;
        }

        @include respond-to('mobile') {
            left: auto;
            right: auto;
            margin: 0 $MBgutter*.75;
            position: relative;
            transform: translate(0,calc(100% - 9rem - #{$MBgutter*1.5}));
            transform: translate3d(0,calc(100% - 9rem - #{$MBgutter*1.5}),0);
        }
    }

    h4 {
        height: auto;
        // max-height: 1.4em;
        @include respond-to('tablet') {
            @include font-size(18);
        }
    }

    .card-content {
        @include respond-to('mobile') {
            opacity: 0;
        }
    }
}
