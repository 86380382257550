.section.intro-section {
    // background: $baseColor;
	padding: 0 0 $spaceY * 2;
	@include clearfix();
	border-bottom:1px $baseColor solid;
    @include respond-to('mobile') {
        padding: $spaceY/1.5 $MBgutter*1.5 $spaceY;

        .feed-actions {
            
        }
    }

    .content {
		@include columns(8,1);
	}
	
	.feed-actions {
		@include columns(10,1);
	}

    path {
        fill: $baseColor;
    }

    @at-root .woocommerce & {
        margin-bottom: -15vh;
    }
}
