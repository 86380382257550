.section.featured-post-section {
    position: relative;
    z-index: 1;
    @include respond-to('mobile') { 
        // padding-top: $MBgutter*3; 
        // padding-bottom: 0; 
        padding:$gutter;
    };

    .content {
        @include columns(11,0.5);
        @include responsive-columns('mobile',1,0,0);
        background: white;
        border-radius: $br;

        @include respond-to('mobile') {
            margin-top: 3em;
        }
    }

}
