
.section.members-section {

	background:$textColor;
	color:white;

	.title {
		text-align:center;
		margin: 0 0 2em;
	}
	
	.content {
		@include columns(12,0);
		display: flex !important;
		flex-wrap: wrap;
		@include respond-to('desktop') {
			@include columns(10,1,0);
		}
		@include respond-to('mobile') { padding: 0 $MBgutter $MBgutter; }
	}

	a.member {
		cursor: pointer;
	}
	
	.member {
		width:25%;
		border-radius: $br;
		overflow: hidden;
		background: currentColor;
		display: block;
		float: left;
		position: relative;
		outline: none;
		margin-bottom:0;

		@include respond-to('tablet') {
			width:50%;
		}

		@include respond-to('desktop') {
			width: calc(25% - #{$gutter});
			margin-bottom: $gutter;
			margin-left: $gutter / 2;
			margin-left: $gutter / 2;
		}
		
		&:nth-of-type(1),
		&:nth-of-type(4n + 1) {
			margin-left: 0;
		}

		&.-without-quote  {
			.image {
				@include respond-to('mobile') {
					width:100%;
				}
			}
		}
		
		@include respond-to('mobile') {
			width: 100%;
			height:20em;
			margin-left: 0;
			margin-right: 0;
			
			&:not(:last-of-type) {
				margin-bottom: $MBgutter;
			}
			
			&:nth-of-type(2n) {
				margin-left: 0;
			}
		}
		
		.member-content {
			position: absolute;	
			display: flex;
			justify-content: center;
			flex-direction: column;
			z-index: 1;
			padding: 1rem;
			min-height:6.75rem;
			transition: all .8s $easeOut;
			width: 100%;
			height:100%;
			top:100%;
			background:$primaryTransparent;
			color:white;
			@include respond-to('mobile') {
				position:absolute;
				width:50%;
				height: 100%;
				left:50%;
				top:0;
				padding: $MBgutter;
				margin: 0;
			};
			
			.member-content-container {
			}


			.quote {
				font-size:1rem;
				font-weight:bold;
				line-height: 1.0;
				margin-bottom: .25rem;
				text-align:center;
				@include respond-to('desktop') {
					font-size:1.1rem;
					line-height: 1.2;
				}
				@include respond-to('mobile') {
					text-align:left;
					font-size:1rem;
					line-height:1.0;
				}	
			}

			.name {
				font-size:0.9rem;
				font-weight:bold;
				line-height: 1;
				margin-bottom: .5em;
				text-align:center;
				color:$textColor;
				@include respond-to('desktop') {
					font-size:1.0rem;
					line-height: 1.2;
				}
				@include respond-to('mobile') {
					text-align:left;
				}	
			}
			
			&.-long {
				
				.title {
					min-height: 1.2em;
				}
			}
		}

		.image {
			position:relative;
			width:100%;
			padding-bottom:100%;
			opacity:1;
			transition: all 1s $easeOut;
			@include respond-to('mobile') {
				width: 50%;
				height: 100%;
				padding: 0;
				background-position: center center;
			}
		}
		
		&:focus,
		&:hover,
		&:active {
			.member-content {
				top:0;
			}
		}
		
		
	}
	
	
	// Footer Config
	// -------------------
	
	~ .featured-panels.-footer {
		padding-top: 5vh;
	}
	

	// Single Section


	&.-count-1 {
		background:white;
		.content {
			@include columns(8,2,0);
		}
		.member {
			width:100%;
			height:16rem;
			background:$primaryColor;
			color:white;
			margin-bottom:4rem;
			overflow:visible;

			@include respond-to('mobile') {
				padding-top:16rem;
				height:auto;
			}
			.image {
				width:16rem;
				height:16rem;
				position:absolute;
				top:0;
				right:0;
				padding:0;
				@include respond-to('mobile') {
					width:100%;
					height:16rem;
				}
			}
			.member-content {
				background:transparent;
				position:relative;
				top:0;
				width:calc(100% - 20rem);
				padding:2em;
				@include respond-to('mobile') {
					width:100%;
					padding:1em;
					margin:0;
					text-align:center;
					left:auto;
				}
				.quote {
					font-size:1.6rem;
					line-height:1.2;
					text-align:left;
					margin:0 0 1rem 0;
					@include respond-to('mobile') {
						font-size:1.3rem;
						text-align:center;
					}
				}
				.name {
					text-align:left;
					text-transform:uppercase;
					@include respond-to('mobile') {
						text-align:center;
					}
				}
			}

			&:before {
				content:"";
				display:block;
				position:absolute;
				bottom:-3rem;
				left:0;
				border-width:1.5rem;
				border-style:solid;
				border-color:$textColor transparent transparent $textColor;
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:-2rem;
				left:0;
				border-width:1rem;
				border-style:solid;
				border-color:$primaryColor transparent transparent $primaryColor;
			}
		}	
	}
	

}
