
header.progress {
    padding: 3.5vw 3.5vw 0;
    margin-bottom: -1.5vw;
}

.progress-bar {
    width: 100%;
    background: white;
    // border-radius: $br*.6;
    position: relative;
    height: 2em;
    z-index: 1;

    &:before {
        content: '';
        // border-radius: $br*.8;
        @include full;
        border: 2px solid white;
        z-index: 1;
    }

    .percent {
        position: absolute;
        right: 0;
        font-family: $headingFont;
        top: calc(50% - .5em);
        right: .75em;
        color: $textColor;
        letter-spacing: 0.1em;
    }

    .progress {
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        // border-radius: $br*.3;
        overflow: hidden;
        padding: 0;
        z-index: 1;
        max-width: calc(100% - 4px);
        // background: $actionColor;

        &:before {
            content: '';
            @include full;
            background: linear-gradient(to right, $actionColor 20%, lighten($actionColor,15));
            // border-radius: $br*.3;
            transform: translate(calc(-100% + 1em),0);
            transform: translate3d(calc(-100% + 1em),0,0);
            animation: appear 2s $easeOut forwards 1s;
        }

        span {
            $base: 3px;

            position: absolute;
            top: 0.75em;
            right: 1.5em;
            opacity: 0;
            animation: appear 2s cubic-bezier(0.5, 0, 0, 1) forwards 3.25s;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border: 1px solid currentColor;
                    box-sizing: content-box;
                    border-radius: 50%;
                    opacity: 1;
                    transform: translate3d(0,.15em,0);


                }

                &:before {
                    top: - $base*.8; right: $base*6.4;
                    width: $base; height: $base;
                    transition: box-shadow 1s linear;
                    transform: translate3d(0,.2em,0);

                    box-shadow:
                    #{-$base*2} #{-$base*1} 0 -3px white,
                    #{-$base*2} #{-$base*1} 0 -1px currentColor,
                    ;
                }

                &:after {
                    top: - $base*3.25;
                    right: $base*4;
                    width: $base*1.4;
                    height: $base*1.4;
                    transform: translate3d(0,.3em,0);
                }
        }

        i {
            position: absolute;
            right: 0.75em;
            top: .35em;
            opacity: 0;
            transform: translate(calc(-100% + 1em), 0);
            transform: translate3d(calc(-100% + 1em), 0, 0);
            animation: appear 2s cubic-bezier(0.5, 0, 0, 1) forwards 3s;
        }
    }

    .milestone {
        display: inline-table;
        background: $baseColor;
        bottom: calc(100% + 2vw);
        // border-radius: $br*1.75 $br*1.75 $br*1.75 0;
        font-family: $headingFont;
        position: absolute;
        font-weight: 600;
        letter-spacing: .02em;
        @include font-size(18);
        z-index: 1;
        padding: .55em .75em;
        @include respond-to('mobile') {
            display: none; 
            padding: .35em .55em;
        }

        opacity: 0;
        animation: appear 1s $easeOut forwards 2.5s;
        transform-origin: bottom left;
        transform: translate(0,20%) rotate(6deg);
        transform: translate3d(0,20%,0) rotate(6deg);

        &:after {
            $unit: 8px;
            content: '';
            position: absolute;
            border: $unit solid transparent;
            border-right-color: $baseColor;
            bottom: -($unit - 2px);
            left: -$unit;
            transform: rotate(45deg);
        }
    }

    + .caption {
        display: block;
        font-weight: 600;
        color: $secondaryColor;
        font-family: $headingFont;
        letter-spacing: .05em;
        @include font-size(18);
        margin: .5em 0 2em;
        @include respond-to('mobile') { margin-bottom: 1em }

        em {
            font-style: normal;
            float: right;
        }
    }
}


// Card Progress
// --------------

.card .progress,
.card-featured .progress {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    padding: $gutter*.25 $gutter*.3;
    @include respond-to('mobile') { padding: $MBgutter*.75; };

    .progress-bar {
        overflow: hidden;
        height: 2.25em;

        &:before {
            content: none;
        }
    }

    .progress {
        top: 0;
        bottom: 0;
        left: 0;

        &:before {
            // border-radius: $br*.5;
        }

        i {
            right: 1em;
            top: .5em;
            color: $textColor;
        }

        span {
            top: 1em;
            right: 1.75em;
            color: $textColor;
        }
    }

    .milestone {
        background: transparent;
        left: .75em !important;
        top: calc(50% - .5em);
        padding: 0;
        color: $textColor;

        &:after {
            content: none;
        }
    }

    .caption {
        display: none;
    }
}
