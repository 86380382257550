// ------------------------------------
// Partial: Header
// ------------------------------------

header {
	position: relative;
	// background:orange;
	color:$textColor;
	padding:22rem 0 10rem;
	@include clearfix;

	@include respond-to('mobile') {
		padding:18rem 0 5rem;
	}
	
	&.-imageless {
		clip:none;	
	}
	
	.content {
		position: relative;
		z-index: 1;
		@include columns(7);

		.header-metas {
			display:block;
			margin:0 0 .5em;
			.header-meta {
				display: inline-block;
				background: white;
				padding: .2em .5em;
				border-radius: .2em;
				margin: 0 .5em .5em 0;
				font-size: 1rem;
				font-weight: bold;
			}
		}
		
		.header-title {
			display:inline-block;
			opacity: 0;
			animation: appear 1s forwards 1s;
			color:$primaryColor;
			margin-bottom:0.5rem;
			color:$textColor;
			font-weight:bold;
			line-height:1.35;

			// Highlights
			span {
				display: inline;
				background: $primaryColor;
				color: $textColor;
				padding: 0.25rem 0.5rem;

				/* Needs prefixing */
				box-decoration-break: clone;
				-webkit-box-decoration-break: clone;

			}
			
			&.-long {
				@include font-size(35);
			}
		}

		.header-action {
			padding:.25em;
		}

	}

	&:after {
		content:"";
		display:block;
		position:absolute;
		top:auto;
		bottom:-15rem;
		left:0;
		width:100%;
		height:20rem;
		background:linear-gradient(darken($baseColor,10%),white);
		-ms-transform: skewY(-5deg);
		transform: skewY(-5deg);
		@include respond-to('desktop') {
			-ms-transform: skewY(-3deg);
			transform: skewY(-3deg);
		}
		@include respond-to('mobile') {
			height:10rem;
			bottom:-8rem;
		}
	}
}

.header-introduction {
	position:relative;
	.content {
		@include columns(7);
		padding:4rem 0 0;
		@include respond-to('mobile') {
			padding:2rem 0 0;
		}
		p {
			font-size:1.3rem;
			line-height:1.4;
			max-width:48rem;
		}
	}
	.aside {
		@include columns(5);
		background:$baseColor;
		box-shadow:$shadow;
		padding:1rem;
		position:relative;
		top:-9rem;
		margin-bottom:-7rem;
		@include respond-to('mobile') {
			top:auto;	
			margin-bottom:2rem;
		}
	}
}
