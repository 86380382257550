// _posts.scss

.wp-caption {
	// width: 100% !important;
	position: relative;
	overflow: hidden;
	// border-radius: 5px 10px 10px 10px;
	margin: 2em 0 0;
	max-width: 100%;

	img {
		max-width: 100%;
		height: auto;
		width: 100%;
	
	}
	&-text {
		&:before {
			content:"";
			position:absolute;
			width:4em;
			height:4em;
			right:0;
			top:-4em;
			background:white;
		}
		position:relative;
		width:100%;
		text-align: left;
		font-size: 1rem;
		font-weight: bold;
		padding: 1em 0;
		color: $secondaryColor;
		line-height: 1.18;

		@include respond-to('mobile') {
			// @include full(relative,auto);
			// background: $secondaryColor;
			// width: 100%;
			// margin-top: -1em;
			// padding-top: 2em;
			// z-index: -1;
		}
	}
}

.alignnone {
	width: 100%;
	height: auto;

	img {
		width: 100%;
	}
}

.alignright,
.alignleft {
	margin-top: 1em;

	.wp-caption-text {
		// left: 0;
		// right: 0;
		// width: 100%;
		// background: linear-gradient(160.15deg, #021945 50%, rgba(2,25,69,0) 100%) no-repeat center / 250% 100%;
	}
}

.alignright {
	float: right;
	margin-left: $gutter*.75;
	margin-right: -$gutter*.75;
}

.alignleft {
	float: left;
	margin-right: $gutter*.75;
	margin-left: -$gutter*.75;
}

.aligncenter {
	display: block;
	margin: 0 auto;
}


.single {
	.basic-section {
		text-align: left;
		color: #000;

		.meta {
			display: inline-block;
			color: $secondaryColor;
	        text-transform: uppercase;
	        letter-spacing: .1em;
	        font-weight: 600;
	        text-align: left;
	        font-size: .85em;
			margin-bottom: 1em;
			vertical-align: middle;

			&.-date {
				color: rgba($secondaryColor,.6);
			}

			+ .meta {
				@include seperator($primaryColor);
			}
		}

		h1, h2, h3, h4, h5 {
			text-align: center;
			margin-bottom: 1em;
		}

		h1, h2, h3, h4, h5, blockquote {
			color: $textColor;
		}
	}

	p > img.alignnone {
		margin-left: -$gutter*.75;
		margin-right: -$gutter*.75;
		max-width: calc(100% + #{$gutter*.75*2});
		width: calc(100% + #{$gutter*.75*2});
	}

	img {
		max-width: 100%;
		height: auto;
		border-radius: $br;
	}
}

.section.related-content {
	.introduction {
		text-align:center;
	}
}

