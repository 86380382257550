
.onsale {
    font-weight: 600;
    position: absolute;
    top: 1.5em;
    right: 0;
    bottom: auto;
    left: auto;
    z-index: 3;
    background: $actionColor;
    text-transform: uppercase;
    letter-spacing: .1em;
    padding: .5em .8em;
    font-size: 1.1em;
    font-family: $headingFont;
}
