//
// Global Settigns
// --------------------------------------------------

* {
	box-sizing: border-box;
}

html {
	position: relative;
	// overflow-x: hidden;
}

body {
	position: relative;
	width:100%;
	max-width:100%;
}

img {
	max-width: 100%;
	height: auto;
}

.container {
	@include container()
}

.error {
	color: $errorColor;
}

.success {
	color: $successColor;
}

.sub {
	font-family: $paragraphFont;
	letter-spacing: .05em;
	color: $textColor;
}

.split-main { @include columns(7); }

.split-aside { @include columns(5); }

.wysiwyg { padding: $spaceY 0em; }


.hide {
	font-size: 0;
	color: rgba(255, 255, 255, 0);
	position: absolute;
	left: 100vw;
}

.screen-reader-text {
	position: absolute;
	left: 150vw;
	font-size: 0;
	color: transparent;
}

.-mobile {
	display: none;
	@include respond-to('mobile') { display: block }
}

.hide-mobile {
	@include respond-to('mobile') { display: none !important }
}

.page-template-default.page {
	header + .basic-section {
		padding: 10vh 0;
		@include respond-to('mobile') { padding: $MBgutter*2 };
	}
}

body.admin-bar {
	margin-top: -32px;
	@include respond-to('tablet') { margin-top: -46px }
}

#wpadminbar:not(override) {
	position: absolute;
	top:-32px;
// 	transition: .3s;
// 	opacity: 0;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	background: none;
// 	backface-visibility: hidden;
// 	transform: translateZ(0);
// 	@include respond-to('tablet') {  display: none !important; }

// 	.quicklinks {
// 		transform: translate3d(0,-2.5em,0);
// 		transition: .3s $easeOut;
// 		background: $darkestColor;
// 		@include clearfix;
// 	}

// 	&:hover {
// 		opacity: 1;

// 		.quicklinks {
// 			transition: .5s $easeOut .3s;
// 			transform: translate3d(0,0,0);
// 		}
// 	}

// 	@include respond-to('mobile') {
// 		display: none !important;
// 	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 35px;
	height: 0;
	left: -$gutter/2;
	right: -$gutter/2;
	width: calc(100% + #{$gutter});
	overflow: hidden;
	margin-bottom: 1em;
	iframe {
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}