
.post-pagination {
    @include columns(10,1,0);
    margin-top: -5vh;
    margin-bottom: 10vh;
    text-align: center;

    .button:first-of-type:not(:last-of-type) {
        float: left;
    }

    .button:last-of-type:not(:first-of-type) {
        float: right;
    }
}
