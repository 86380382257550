
.feed-actions {
	@include clearfix;
	position: relative;
	z-index: 2;
	@include container;
	margin-bottom:2rem;
	@include respond-to('mobile') { 
		// padding: $MBgutter 0; margin-top: 0; 
		@include columns(12);
	}
	
	&.-shop {
		margin-bottom: 0;
	}
	
	// Search Box
	// ---------------------------------------------
	.search-form {
		width: calc(66% - 1.7vw);
		@include respond-to('tablet') { width: calc(50% - 1.7vw); };
		@include respond-to('mobile') { width: 100%; margin-bottom: 5vw; };
		
		.aws-container {
			overflow: hidden;
			position: relative;
			height: 3rem;
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				width: 1em;
				border: 2px solid $primaryColor;
				border-right-color: transparent;
				left: 0;
				z-index: 3;
				pointer-events: none;
			}
		}
		
		form {
			margin: 0;
			
			&:after {
				right: calc(-100% + 1em);
			}
		}
		.search-submit  {
			margin:0;
		}
	}
	
	.search-form {
		position: relative;
		overflow: hidden;
		float:left;
		
		&.active {
			box-shadow: inset -2px 0 0 $primaryColor;
			
			&:after {
				content: '';
				position: absolute;
				top: calc(50% - 6px);
				right: calc(1.4em - 6px);
				width: 8px;
				height: 8px;
				border-left: 2px solid  $textColor;
				border-bottom: 2px solid  $textColor;
				z-index: 3;
				transform: rotate(-45deg);
			}
		}
	}
	
	.search-form {
		// border-radius: .3rem;
		
		&:before {
			right: 50%;
			transition: .4s $easeOut .2s;
			will-change: right;
			@include respond-to('tablet') { right: 0; };
		}
		
		.search-icon {
			transition: .4s $easeOut .2s;
			will-change: right;
			position: absolute;
			top: .75em;
			right: calc(50% + .5em);
			z-index: 3;
			transform: scale(.8,.8);
			transform: scale3d(.8,.8,1);
			@include respond-to('tablet') { right: .5em; };
		}
		
		&.-focus {
			
			.search-icon {
				right: .5em;
			}
			
			&:before {
				right: 0;
			}
		}
	}
	
	.aws-search-form {
		transform: translate(-50%,0);
		transform: translate3d(-50%,0,0);
		@include respond-to('tablet') { transform: none; };
		height: 100%;
	}
	
	input[type="text"][name="s"] {
		// border-radius: .3rem !important;
		border-color: $primaryColor;
		padding: .4em .65em .4em;
		background: white;
		line-height: normal;
		height: auto;
		width: 100%;
		height: 100%;
		@include font-size(18);
		transition: .4s $easeOut .2s;
		margin-bottom: 0;
		
		&::placeholder {
			color: rgba($textColor,.5);
			opacity: 1;
			padding-left: calc(50% + .8em);
			transition: .3s $easeOut .6s;
			@include respond-to('tablet') { padding-left: 0; };
		}
		
		&:focus {
			transform: translate(50%,0);
			transform: translate3d(50%,0,0);
			@include respond-to('tablet') { transform: none; };
			border-bottom-right-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
			
			&::placeholder {
				color: transparent;
				opacity: 1;
				transition: .2s $easeOut;
			}
		}
	}
}

.aws-search-result:not(override) {
	border: 2px solid $primaryColor;
	margin-top: -3px;
	border-top-color: lighten($primaryColor,15);
	
	.aws_result_sale {
		height: auto;
		width: auto;
	}
	
	.aws_result_item:hover,
	.aws_result_item.hovered {
		background: lighten($primaryColor,25);
	}
	
	.aws_result_link {
		padding: 1em;
	}
	
	.aws_result_title {
		font-family: $headingFont;
		color: $textColor;
		font-size: 1.2em;
		margin-bottom: .5em;
	}
	
	.aws_onsale {
		transform: none;
		background: $actionColor;
		color: $textColor;
		font-weight: 600;
		height: auto;
		text-transform: uppercase;
		letter-spacing: .2em;
		font-family: $headingFont;
		padding: .4em .6em;
		right: 0;
		top: 0;
	}
}

// Category Filter
// ---------------------------------------------
.feed-actions.-shop  {
	margin-top: 2vh;
	
	.filter-dropdown {
		margin-left: auto;
		@include respond-to('mobile') { 
			// margin-left: 0; 
		};
	}
}
