//
// Variables
// --------------------------------------------------


// Fonts
// -------------------
$headingFont: 'Avenir Next', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$paragraphFont: 'Avenir Next', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$iconFont: 'fontello';

// Colours
// -------------------

$textColor: #2D2A2B;
$textLightColor: lighten($textColor, 30%);
$baseColor: #F4F4F4;
$baseLightColor: lighten($baseColor, 10%);
$baseDarkColor: darken($baseColor, 30%);

$primaryColor: #F79020;
$primaryTransparent: rgba(247, 144, 32, .9);
$secondaryColor: $textColor;
$actionColor: $primaryColor;
$baseColorer: $primaryColor;
$accentColor: $primaryColor;

$socialFacebook: #3B5998;
$socialTwitter: #00ACED;
$socialInstagram: #3F729B;
$socialYoutube: #CD201F;
$errorColor: $primaryColor;
$successColor: #00AA00;

$baseFontSize: 16px;
$spaceY: 1rem;
$br: 0;
$gutter: 1rem;
$MBgutter: 1rem;

$columns: 12;
$column: 100vw / 12;
$underline: 5px;
$shadow: 0 1px 4px rgba(0,0,0,0.2);

// Transitions + Animations
// -------------------
$easeOut: cubic-bezier(.5,0,0,1);
$easeIn: cubic-bezier(.8,0,.5,1);
@keyframes appear {
	to {
		opacity: 1;
		transform: translate(0,0) scale(1,1);
		transform: translate3d(0,0,0) scale3d(1,1,1);
	}
}
@keyframes fadeIn {
	to {
		opacity: 1;
	}
}
@keyframes load {
	to {
		transform: rotate(360deg);
	}
}
// Breakpoints
// -------------------
$breakpoints: (
	'mobile-s': (max-width: 400px),
	'mobile': (max-width: 736px),
	'tablet': (max-width: 1024px),
	'desktop': (min-width: 1200px)
);

// Include gutter on outside
$gutterOnOutside: true;

// Breakpoints Grid Settings
// -------------------
$grid-settings: (
	base: (container-columns: $columns, gutter: 1%, max-width: 960px,),
	desktop: (container-columns: $columns, gutter: 1%, max-width: 1200px,),
	tablet: (container-columns: $columns, gutter: 1%,),
	mobile: (container-columns: 1, gutter: 5%,),
	mobile-s: (container-columns: 1, gutter: 5%,)
);
