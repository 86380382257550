footer {
	position: relative;
	float: left;
	width: 100%;
	background-color: $textColor;
	color: $baseColor;
	padding: $spaceY * 4 0;
	z-index: 1;

	@include clearfix;
	
	.brand {
		@include columns(3);
		margin-bottom:2rem;
		img { 
			width: 100%; 
			border-radius: 0; 
			max-width:12rem;
		}
	}
	.copyright {
		@include columns(9);
		p {
			font-size:1rem;
			a {
				color:white;
			}
		}
	}
	
	.footer-navigation {
		@include columns(9);
	}

	ul.menu-footer {
		@include clearfix;
		width:100%;
		padding:0 0 $spaceY 0;
		li {
			@include columns(3);
			font-weight:bold;
			a {
				display:block;
				padding:$spaceY / 2 0;
				color:$primaryColor;
				text-transform: uppercase;
			}
			ul.sub-menu {
				margin:0;
				padding:0;
				li {
					display:block;
					width:auto;
					float:none;
					font-weight:normal;
					margin:0;
					padding:0;
					a {
						color:white;
						text-transform: none;
					}
				}
			}
			&.extended {
				@include columns(4);
				ul.sub-menu {
					li {
						width:50%;
						float:left;
					}
				}
			}
		}
	}

	.actions {
		position: relative;
		
		.button {
			&:before,
			&:after {
				color: white;
			}
			
			i {
				margin-right: 0.5em;
			}
			
			&:first-of-type {
				color: white;
			}
		}
		
		.social {
			@include columns(3);
			box-shadow:none;
			font-size:1.5rem;
			
			@include respond-to('mobile') {
				padding-top: 0;
				box-shadow: none;
			}
			
			.social-button {
				line-height: 1;
			}
		}
		
		@include respond-to('mobile') {
			text-align: center;
			
			.button,
			.social {
				width: 100%;
				margin-left:0;
				margin-right:0;
				padding-left:0;
				padding-right:0;
			}
		}
	}
	
	.copyright {
		@include columns(8);
		color:$baseDarkColor;
		
		@include respond-to('mobile') {
			padding:2rem 0;
		}
		
		span {
			display: inline-block;
			position: relative;
			margin-right: .1em;
		}
	}




	margin-top:10rem;

	&:before {
		content:"";
		display:block;
		position:absolute;
		top:-5rem;
		bottom:auto;
		left:0;
		width:100%;
		height:15rem;
		background:$textColor;
		border-top:$primaryColor 10px solid;
		-ms-transform: skewY(-5deg);
		z-index:-1;
		transform: skewY(-5deg);
		@include respond-to('desktop') {
			-ms-transform: skewY(-3deg);
			transform: skewY(-3deg);
		}
		@include respond-to('mobile') {
			height:10rem;
			bottom:-8rem;
		}
	}
	
}
