
.section.images-section {

	background:white;
	color:$textColor;
	float:left;
	clear:both;

	.title {
		text-align:center;
		margin: 0 0 2em;
	}
	
	.content {
		text-align:center;
	}

	a.image {
		cursor: pointer;
	}
	
	.image-single {
		width:10rem;
		border-radius: $br;
		overflow: hidden;
		display: inline-block;
		position: relative;
		margin-bottom: $gutter;
		margin-right:1em;
		margin-left:1em;
		outline: none;
		
		
		@include respond-to('mobile') {
			width:15rem;
			height: 8rem;
		}
		

		> .image {
			position:relative;
			width:100%;
			padding-bottom:100%;
			opacity:1;
			transition: all 1s $easeOut;
			@include respond-to('mobile') {
				width: 100%;
				height: 100%;
				padding: 0;
				background-position: center center;
			}
		}
		
		&:focus,
		&:hover {
			.image-content {
				top:0;
			}
		}
		
		
	}
	

}
