.card-featured {
	background: $baseColor;
	color:$textColor;
    
    @include clearfix;

    .feature-content {
        padding: 2rem 2rem 5rem 0;
        min-height: 15vw;
        width: 55%;
        position: relative;
        left: 45%;

        @include respond-to('mobile') {
            float: left;
            padding: $MBgutter $MBgutter calc(#{$MBgutter} + 4em);
            width:100%;
            left:0;
        }

        h6 {
            @include h2;
            margin-bottom:.25rem;
        }
        .meta {

		color: rgba($secondaryColor,.6);
		text-transform: uppercase;
        }
    }

    .button {
        position: absolute;
        bottom: $gutter*2;
        left: calc(42% + 2rem);
        margin-bottom: 0;
        z-index: 1;

        @include respond-to('mobile') {
            width: calc(100% - #{$MBgutter*2});
            left: $MBgutter;
            bottom: $MBgutter;
            text-align: center;
        }

        &:hover,
        &:focus {

            ~ .image span {
                transform: none;
            }
        }
    }

    .image {
        position: absolute;
        top: -$gutter*.25;
        right: 0;
        bottom: -$gutter*.25;
        left: 0;
        width:42%;
        overflow: hidden;

        @include respond-to('mobile') {
            width: 100%;
            height: 30vh;
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
        }

        > span {
            @include full;
            transform: scale(1.05,1.05);
            transform: scale3d(1.05,1.05,1);
            transition: 3s;
        }

        >iframe {
            width:100%;
            height:auto;
        }

        &.-video {
            overflow: visible;
            @include respond-to('tablet') {
                top:0;
                left:0;
            }
            @include respond-to('mobile') {
                height:0;
                padding-bottom: 66%;
            }
        }

    }

}
