
.basic-section {

    &.-centered {
        text-align: center;
    }

    .content {
        @include columns(12);

        @include respond-to('tablet') {
            @include columns(12);
        }

        @include respond-to('mobile') {
            width:90vw;
        }

        table {
            margin:0 0 1rem;
            vertical-align:top;
            tr {
                padding:0;
                td {
                    padding:0 0 2rem 0;
                    line-height:1.5;
                }
            }
        }
    }

    img { border-radius: $br }

}
