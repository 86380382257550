// _actions.scss

.action-wrap {
	// border-radius: 1.25rem;
	overflow: hidden;
	margin-top: -10vh;
	position: relative;
	z-index: 1;
}

.action-box {
	// background-color: $baseColor;

	iframe {
		width:100%;

	}

	form {
		position: relative;
		margin: 3.5vw;
	}

	&:not(.-fa) {

		input:not([type="radio"]):not([type="checkbox"]),
		select,
		textarea {
			margin-bottom: 1em;
		}
	}

	// Header
	// ----------------------------
	.form-header {
		margin: 3.5vw 3.5vw -3.5vw;
		padding: 0 0 1px;

		h6 {
			margin-bottom: .2em;
		}
	}

	@at-root .progress + .form-header {
		margin-bottom: -2vw;
	}

	// Action Form
	// ----------------------------
	.message-form {
		@include clearfix;

		input:not([type="checkbox"]):not([type="radio"]),
		textarea {
			width: 100%;
			// border-color: #fff;
			transition: all 300ms $easeIn;

			&:focus {
				border-color: $secondaryColor;
			}
		}
	}

	.form-submit {
		margin-top: 1vw;
		float: left;
		text-align: center;
		clear: both;
		width: 100%;
	}

	.field:not(.fancy-checkbox) {
		width: 100%;
		float: left;
		@include clearfix;
		clear: both;
		position: relative;

		label {
			display: none;
		}
	}

	.field.fancy-checkbox {
		text-align: left;
		@include clearfix;
		clear: both;

		> div {
			float: left;
			width: 35px;

			input[type=checkbox] {
				margin-right: 10px;
			}
		}

		label {
			width: calc(100% - 35px);
			float: left;
			margin-top: .3em;
			cursor: pointer;
		}
	}

	// MP lookup
	// ----------------------------
	.rep-results {
		width: 100%;
		float: left;
		margin-bottom: 1em;

		.mp {
			width: 100%;
			float: left;
			position: relative;
			border: 2px solid #021945;
			// border-radius: .3em;
			margin-bottom: 1em;
			overflow: hidden;
			background-color: #fff;

			&.active {
				background-color: #021945;
				color: #fff;

				.icon-check {
					transform: translateY(-50%) scale(1,1);
					transform: translateY(-50%) scale3d(1,1,1);
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		input[type=radio] {
			width: 0;
			height: 0;
			margin: 0;
			padding: 0;
			visibility: hidden;
			position: absolute;
			left: -10000px;
		}

		.headshot {
			width: 80px;
			height: 80px;
			float: left;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
		.content {
			width: calc(100% - 80px);
			float: left;
			padding: .4em .65em .6em;
			text-align: left;

			.center {
				padding-top: .5em;
			}

			.electorate {
				font-family: $headingFont;
				text-transform: uppercase;
				display: block;
			}
			.name {
				display: block;
				font-weight: normal;
			}
			.icon-check {
				position: absolute;
				right: .6em;
				top: 50%;
				transition: .3s $easeOut;

				transform: translateY(-50%) scale(1,1);
				transform: translateY(-50%) scale3d(1,1,0);
			}
		}
	}

	// Form Footer
	// ----------------------------
	.submit-element {

		+ p {
			margin: 1em 0 0;
		}
	}
}


section.section-split {
	.container-fluid {
		max-width: 100%;
		@include columns(10,1,-1%);
	}

	article.split-half {
		> p {
			padding-left: 2vw;
			padding-right: 4vw;
		}
	}

	aside.split-half {
		@include columns(6,1,0);
		float: right !important;

		@include respond-to('mobile') {
			float: left !important;
			@include columns(10,1);
			margin-bottom: 3vh;
		}
	}
}


// Completed action
// ----------------------------
.action-complete {
	text-align: center;
	padding: 3.5vw;
	position: relative;
	@include clearfix;

	@at-root .progress + & {
		padding-top: 1vw;
	}

	.text {
		margin-bottom: 2em;
	}

	.social {
		font-size: 1.1rem;

		a {
			display: inline-block;
			padding: .5em .8em .5em .5em;
			margin: 0 .6em;
			// border-radius: $br*.6;
			font-family: $headingFont;
			font-weight: bold;
			letter-spacing: 0.025em;
		}
	}

	.next-action {
		padding: $gutter;
		background: $textColor;
		color: white;
		// border-radius: $br;
		position: relative;
		overflow: hidden;
		margin: $gutter -3.5vw -3.5vw;

		.image {
			@include full;

			&:before {
				content: '';
				@include full;
				background: black;
				opacity: .3;
			}
		}

		.title {
			text-shadow: 0 0 20px black;
		}

		.button {
			color: white;
			background: rgba(black,.1);
		}

		.header-meta {
			color: white;
			text-shadow: 0 0 20px black;
			margin-bottom: 1vh;
			font-weight: 600;
		}
	}
}
