.card {
	position: relative;
	display: inline-block;
	text-align: center;
	float: left;
	background: $baseColor;
	color:$textColor;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	outline: none;
	
	> a {
		width: 100%;
		overflow: hidden;
		// outline: none;
		// min-height: 515px;
		display:block;
		padding-bottom: 2em;
		background:white;
		box-shadow:0 2px 5px rgba($textColor,.1);
		
		@include respond-to('mobile') {
			padding-bottom: 1rem;
		}
		> .flex-wrapper {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}
	}
	
	.date,
	.topic {    
		display: block;
		width: 100%;
		padding: .75rem 1rem 0;
		margin-bottom: -1em;
		position: relative;
		z-index: 1;
		color: rgba($secondaryColor,.6);
		text-transform: uppercase;
		letter-spacing: .1em;
		font-weight: 600;
		text-align: left;
		font-size: .85rem;
	}

	.heading-container {
		flex-grow: 1;
	}
	
	.title {
		padding: 1.5rem 1rem .5rem 1rem;
		position: relative;
		z-index: 1;
		text-align: left;
		color: $secondaryColor;
		transition: .4s $easeOut;
		font-weight:bold;
		will-change: color;
		margin:0;
		
		@include respond-to('mobile') {
			// padding: $MBgutter $MBgutter 0;
		}
	}

	.excerpt {
		margin: 0;
		padding: 0 1rem 1rem;
		text-align: left;
		font-size: .85rem;
	}
	
	.caption {
		padding: .75em;
		background: rgba($primaryColor,.3);
		font-family: $headingFont;
		color: $secondaryColor;
		text-align: left;
		font-weight: 600;
		position: relative;
		transition: .4s $easeOut;
		z-index: 1;
		margin: 0 1px;
		
		em {
			font-style: normal;
			font-weight: 400;
		}
	}
	
	.image {
		display:block;
		width:100%;
		padding-bottom: 66.5%;
		position: relative;
		z-index: 1;
		overflow: hidden;
		height: 120px;
		
		&:before {
			content: '';
			@include full;
			background: currentColor;
			opacity: .4;
			z-index: -1;
		}
		
		> span:not(.onsale) {
			@include full;
			transition: 2s $easeOut;
			transform: scale(1.05,1.05);
			transform: scale3d(1.05,1.05,1);
		}
	}

	.action {
		padding: 0 1rem;
		text-align:left;
	}
	
	.read-more {
		margin-top: auto;
		position: relative;
		z-index: 1;
		margin-bottom: 0;
		margin-right: 0;
		text-align:left;
		text-transform:uppercase;
		font-weight:bold;
		color:$primaryColor;

		&:after {
			content:"›";
			display:inline;
			margin:0 0 0 .5em;
			font-size:1.4em;
			position:relative;
			top:-.2em;
			line-height:1em;
		}
		
		&:hover {
			// background: white;
		}
		
		@include respond-to('mobile') {
			margin-top: 2vh;
		}
	}
	
	.background {
		z-index: 0;
		// @include full;
		// overflow: hidden;
		
		// &:before,
		// &:after {
		// 	content: '';
		// 	@include full;
		// }
		
		&:before {
			transition: .4s $easeOut;
		}
		
		&:after {
			// background: linear-gradient(140deg, white, $primaryColor 50%, #47CBC4 100%) no-repeat center / 100% 200%;
			background-position: 0%;
			opacity: 0;
			transition: 1s $easeOut;
			will-change: background-position, opacity;
		}
	}
	
	// Progress
	// --------------
	header.progress {
		opacity: 0;
		transition: .4s $easeOut;
		padding: 1.25em;
		
		.percent {
			display: none;
		}
		
		i {
			transform: translate(calc(-100% + 1em), 0);
			transform: translate3d(calc(-100% + 1em), 0, 0);
			animation: none;
			opacity: 0;
			transition: .2s $easeOut, transform 0s .2s;
		}
		
		.progress > span {
			opacity: 0;
			transition: .2s $easeOut;
			transform: translate(-.4em, 0);
			transform: translate3d(-.4em, 0, 0);
		}
		
		.milestone.-right {
			left: auto !important;
			right: .75em;
			transform-origin: right;
		}
		
		.milestone,
		.progress > span,
		.progress:before {
			animation: none;
			transition: 0s .4s;
		}
	}
	
	a:hover,
	a:focus {
		@supports not ( background: linear-gradient(0deg, white,white) no-repeat center / cover ) { background: $primaryColor; }
		
		h6 {
			color: $textColor;
		}
		
		.image > span {
			transform: scale(1,1);
			transform: scale3d(1,1,1);
		}
		
		.caption {
			background: rgba($textColor,.05);
			color: $textColor;
		}
		
		.background {
			
			&:before {
				opacity: 0;
				transition-delay: .3s;
			}
			
			&:after {
				opacity: 1;
				background-position: 0% 100%;
			}
		}
		
		header.progress {
			opacity: 1;
			
			.progress:before {
				transform: none;
				transition: 1s $easeOut;
			}
			
			.progress > span {
				opacity: 1;
				transform: translate(0, 0);
				transform: translate3d(0, 0, 0);
				transition: 1s $easeOut .3s;
			}
			
			i {
				transform: translate(0, 0);
				transform: translate3d(0, 0, 0);
				opacity: 1;
				transition: .6s $easeOut .3s;
			}
			
			.milestone {
				transform: none;
				opacity: 1;
				transition: .4s $easeOut .3s;
			}
		}
		
		.button {
			background: white;
		}
	}
	
	// Meta
	// --------------
	
	.meta {
		position: absolute;
		bottom: 100%;
		margin-bottom: 1em;
		color: $textColor;
		left: 0;
		right: 0;
		
		.sub {
			float: left;
			transition: .4s $easeOut;
			
			&:hover,
			&:focus {
				box-shadow:
				inset 0 -.15em 0 $primaryColor,
				0 .1em 0 $primaryColor;
			}
		}
		
		.-cat {
			float: right;
		}
		
		@include respond-to('mobile') {
			padding: 4em 4em 0;
		}
	}

	// Search Cards 

	&.-search {
		.title {
			font-size:1.4rem;
		}
	}
}
