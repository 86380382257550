
.section.panel-section {

	.title {
		text-align:center;
		margin: 0 0 2em;
		font-size: 1.8rem;
	}
	
	.content {
		@include columns(10,1,0);
		display: flex !important;
		flex-wrap: wrap;
		@include respond-to('mobile') { padding: 0 $MBgutter $MBgutter; }
	}
	
	.panel {
		width: calc(50% - #{$gutter/2});
		border-radius: $br;
		overflow: hidden;
		background: currentColor;
		display: inline-block;
		float: left;
		position: relative;
		margin-bottom: $gutter;
		cursor: pointer;
		outline: none;
		
		&:nth-of-type(2n) {
			margin-left: $gutter;
		}
		
		@include respond-to('mobile') {
			width: 100%;
			
			&:not(:last-of-type) {
				margin-bottom: $MBgutter;
			}
			
			&:nth-of-type(2n) {
				margin-left: 0;
			}
		}
		
		.panel-content {
			position: absolute;
			bottom:0;
			z-index: 1;
			padding: 2rem 1rem 1rem;
			transition: all .8s $easeOut;
			width: 100%;
			background:linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
			color:white;
			
			@include respond-to('mobile') {
				padding: $MBgutter;
				margin: $MBgutter*.75 $MBgutter*.75 0;
				width: calc(100% - #{$MBgutter*1.5});
			};
			
			.title {
				line-height: 1;
				margin-bottom: .5em;
				text-align:left;
				color:white;
			}
			
			&.-long {
				
				.title {
					min-height: 1.2em;
				}
			}
		}

		.image {
			position:relative;
			width:100%;
			padding-bottom:56%;
			opacity:.9;
			transition: all 1s $easeOut;
		}
		
		&:focus,
		&:hover {
			.image {
				opacity:1;
			}
		}


		&.-with-image {


			.panel-content {
				.title {
					margin-top:auto;
				}
			}
		}
		
		
	}
	
	
	// Footer Config
	// -------------------
	
	~ .featured-panels.-footer {
		padding-top: 5vh;
	}
	
}
