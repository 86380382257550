//
// Typography
// --------------------------------------------------
@mixin heading-font {
	font-family: $headingFont;
	font-weight: 600;
	line-height: 1.1;
}

@mixin paragraph-font {
	font-family: $paragraphFont;
	line-height:1.4;
}

@mixin h1 {
	@include heading-font();
	@include responsive-font-size(50,.75);

	&:not(:last-child) {
		margin-bottom: .4em;
	}
}

@mixin h2 {
	@include heading-font();
	@include responsive-font-size(30);
}

@mixin h3 {
	@include paragraph-font();
	font-weight: 600;
	@include responsive-font-size(20, 1);
	margin-bottom: .5em;
	@include respond-to('mobile') { margin-bottom: $MBgutter; };
}

@mixin h4 {
	@include heading-font();
	@include responsive-font-size(20, 1);
}

@mixin h5 {
	@include heading-font();
	@include responsive-font-size(14);
}

@mixin h6 {
	@include heading-font();
	@include responsive-font-size(14);
	@include respond-to('mobile') { font-size: 18px; }
	font-weight: 500;
	line-height: 1.25;
	letter-spacing: 0.02em;
}

@mixin p {
	@include paragraph-font();
	@include font-size(19);
	line-height: 1.4;

	@include respond-to('tablet') {
		@include font-size(17);
	}

	@include respond-to('mobile') {
		@include font-size(16);
	}
}

@mixin sub {
	@include paragraph-font();
	@include font-size(16);
	font-weight: 600;
	letter-spacing: .1em;
	text-transform: uppercase;
}

body {
	color: $textColor;
	font-family: $paragraphFont;
	-webkit-font-smoothing: antialiased;

	font-size: 100%;
	line-height:1.1;
}

// Headings
// -------------------

h1,
h2,
h3,
h4,
h5,
h6 {

}

h1 {
	@include h1();
	line-height: 1.17;
	color:$primaryColor;
}

h2 {
	@include h2();
	margin-bottom: 0.85em;
}

h3 {
	@include h3();
	margin-bottom: 0.85em;
}

h4 {
	@include h4();
	margin-bottom: 0.85em;
	
	line-height: 1.25;
}

h5 {
	@include h5();
	margin-bottom: 0.85em;
}

h6 {
	@include h6();
	margin-bottom: 0.85em;
}

// Paragraph
// -------------------

p {
	@include p();
	margin: 0 0 1em;

	a {
		color: $textColor;
		transition: .1s $easeOut;
		box-shadow: inset 0 -2px 0 rgba($primaryColor,.5);

		&:hover {
			box-shadow: inset 0 -3px 0 $primaryColor;
		}

		&.button {
			box-shadow:none;
		}
	}
}

a {
	color: inherit;
	text-decoration: none;
}

// Basic Elements
// -------------------

abbr,
acronym {
	border-bottom: 1px dotted;
	cursor: help;
}

address {
	font-size: 1rem;
	font-style: italic;
}

blockquote {
	padding-left: 0;
	position: relative;
	text-align: center;
	margin: 2em 0;
	clear: both;

	&:before,
	&:after {
		font-size: 1.3em;
		@include respond-to('tablet') { font-size: 1.15em }
		@include respond-to('mobile') { font-size: 1em }
	}

	&:before {
		font-family: $iconFont;
		content: '\e91d';
		color: $primaryColor;
		left: -1.75em;
		top: 0;
		position: absolute;
	}

	&:after {
		font-family: $iconFont;
		content: '\e91c';
		color: $primaryColor;
		right: -1.5em;
		bottom: 0;
		position: absolute;
		@include respond-to('tablet') { right: -1.75em }
		@include respond-to('mobile') { right: -1.65em }
	}

	p {
		@include responsive-font-size(25);
		line-height: 1.2;
		font-weight: 500;
		display: inline-block;
		margin-bottom: 0;
	}
}

cite {
	font-style: italic;
}

code {
	font-size: 0.96rem;
	font-family: monospace;
	background: $baseColor;
	border: 1px solid darken($baseColor, 5%);
	padding: 0.125em 0.25em;
	line-height: 150%;
}

em,
i {
	font-style: italic;
}

strong,
b {
	font-weight: bold;
}

hr {
	outline: none;
	border: none;
	margin: 1em 0;
	padding: 0;
	border-top: 1px solid $baseColor;
}

ol,
ul {
	margin: 1em 0;
	padding: 0 0 0 2em;

	li {
		font-size: 1em;
		list-style-position: outside;
	}
}

ul li { 
	list-style: none inside none; 
}
ol li { list-style: decimal; }

// Styled Lists

section .content,
main .content,
.section-split .split-main {


	ol,
	ul {
		padding-left: 1em;
		line-height: 1.6;

		li { 
			position: relative;

			// List Links
			a {
				color: $textColor;
				transition: .1s $easeOut;
				box-shadow: inset 0 -2px 0 rgba($primaryColor,.5);
		
				&:hover {
					box-shadow: inset 0 -3px 0 $primaryColor;
				}
			}
		}
	}

	ol {
		margin: 1em 0;
		padding: 0 0 0 1.5em;

		li {
			list-style: none;
			padding-left: 1em;
			font-weight:400;
			@include p();

			&:not(:last-of-type) {
				margin-bottom: .25em;
			}

			&:before {
				margin-right: .7em;
				display: inline-block;
				color: $primaryColor;
				font-family: $headingFont;
				font-weight: 600;
				position: absolute;
				left: 0;
				top: .05em;
			}
		}
	}

	ul {

		> li {
			padding-left: 1.25em;
			font-weight:400;
			@include p();

			&:not(:last-of-type) {
				margin-bottom: .25em;
			}

			&:before {
				content: '»';
				font-weight:bold;
				// width: 6px;
				// top: calc(.5em + 1px);
				color: $primaryColor;
				padding-bottom: 6px;
				// border-radius: 50%;
				position: absolute;
				left: 0;
				vertical-align: baseline;
				// border: 2px solid currentColor;
				// background: currentColor;
			}
		}


		li > li,
		ul li {

			&:before {
				background: transparent;
			}
		}
	}

	ol {

		> li {
			counter-increment: steps;

			&:before {
				content: counter(steps);
			}
		}

		li > li,
		ol li {
			counter-increment: more;

			&:before {
				content: counter(more);
			}
		}
	}
}


// Form
// -------------------

form {
	margin: 1em 0;
}

// Descriptions
// -------------------

dl {
	margin: 1em 0;

	dt {
		font-weight: bold;
	}
	dd {
		margin: 0 0 0.5em;
	}
}

// Tables
// -------------------

table {
	width: 100%;
	margin: 1em 0;
	// border-radius: $br*.5;
	// overflow: hidden;
	border: 1px solid $baseColor;
	// box-shadow: inset 1px 0 0 $baseColor;

	&:first-child {
		margin-top: 0;
	}

	th {
		padding: 1em;
		border: 1px solid $baseColor;
		background: lighten($baseColor, 3.5%);
		text-align: left;
		font-weight: bold;
	}

	td {
		padding: 1em;
		border: 1px solid rgba($textColor, .1);

		// img {
		// 	border-radius: $br*.5;
		// }

		@include respond-to('mobile') {
			width: 100% !important;
			height: auto !important;
			display: block;
			// margin-bottom: .5em;

			&:not(:last-of-type) {
				border-bottom: none;
			}
		}
	}
}


// Typekit FOUT fix
// -------------------
// h1, h2, h3, h4, h5, h6, p, span, a, small, table, select, input, i, .faux-table div, label, .number, .tagline {
//  	opacity: 0;
//  	transition: opacity 0.20s ease-in;
// }
// .wf-active,
// .wf-inactive, .mce-content-body {
//   h1, h2, h3, h4, h5, h6, p, span, a, small, table, select, input, i, .faux-table div, label, .number, .tagline {
//     opacity:1;
//   }
// }
