
.fancy-checkbox {
    text-align: center;
    margin-bottom: 1em;
    margin-top: 1em;

    > div,
    > div + label {
        display: inline-block;
    }

    label {
        font-weight: 500;
    }
}

.field:not(.fancy-checkbox) + .fancy-checkbox {
    padding-top: 1em;
}
