.join-map {
	display: inline-block;
	position: relative;
	width: 100%;
	padding-bottom: 100%; 
	vertical-align: middle; 
	overflow: hidden; 
	svg {
		display: inline-block;
		position: absolute;
		top: 0;
		width:150%;
		left: -25%;
	}
	#states {
		a {
			fill:$baseDarkColor;
			cursor:pointer;
			text {
				fill:$textColor;
				font-weight:bold;
			}
			&:hover,
			&:focus {
				fill:$primaryColor;
			}
		}
	}
}