//
// Forms
// --------------------------------------------------


// Basic Styles
// -------------------

form {
	display: block;
}

fieldset {
	display: block;
	margin: 1.25em 0;
	padding: 0;
}

legend {
	display: block;
	width: 100%;
	margin: 0 0 1em 0;
	font-weight: bold;
	// border-bottom: 1px solid $baseColor;
}

label {
	display: block;
	margin: 0 0 0.25em;
	font-weight: bold;
}

// Form Elements
// -------------------

.wForm:not(.this) .oneField input,
textarea,
select,
.select2-selection,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="image"],
input[type="color"] {
	padding: .4em .65em .6em;
	margin: 0 1em .5em 0;
	vertical-align: middle;
	border: 2px solid currentColor;
	line-height: 1.4;
	font-weight: 400;
	font-family: inherit;
	@include font-size(19);
	font-family: $paragraphFont;
	-webkit-appearance: none;
	-moz-appearance: none;
	color: $textColor;
	background: white;
	width: 100%;
    max-width: 100%;
	display: block;
	transition: .3s $easeOut;

	@include respond-to('mobile') {
		font-size: 1em;
		padding: .5em 0 .5em .65em;
	}

	&::placeholder {
		color: rgba($textColor,.7);
	}

	&:focus {
		border-color: $primaryColor;
	}
}

// Form Buttons

input[type="submit"],
input[type="button"] {
	display: block;
	// border-radius: 0;
	margin: 0 auto;
}

input[type="file"] {
	padding: 0.125em;
	margin: 0 0 0.625em;
	font-family: inherit;
	font-size: 1rem;
	-webkit-appearance: none;
	border-radius: 0;
	line-height: 100%;
}

// Checkboxes + Radios
$thick: 2px;
.wForm:not(.this) .oneField input[type="radio"],
.wForm:not(.this) .oneField input[type="checkbox"],
input[type="radio"],
input[type="checkbox"] {
	background: rgba($primaryColor,.3);
	vertical-align: middle !important;
	display: inline-block;
	
	margin: 0 .75em 0 0;
	width: 25px;
	height: 25px;
	padding: 0 !important;
	display: inline-block;
	border: 0;
	border: $thick solid $textColor;
	transition: .4s $easeOut, background 0s !important;
	box-sizing: border-box;
	cursor: pointer;

	&[type="radio"] {
		border-radius: 49%;
	}

	&[type="checkbox"] {
		border-radius: $thick;
	}

	&:hover,
	&:focus {
		background: lighten($primaryColor,20);
	}

	// Errors
	// -------------------
	@at-root .woocommerce-invalid & {
		border-color: $errorColor;
	}
}


// Radios + Checkboxes
// -------------------

.wForm:not(.this) .oneField input[type="checkbox"],
.wForm:not(.this) .oneChoice input[type="checkbox"],
input[type="checkbox"] {
	background: white !important;
	float: left;

	&:checked {
		background:  white url('/wp-content/themes/wire/assets/dist/img/tick.png') no-repeat center / 60% !important;
	}

	+ label {
		display: inline-block;
		float: left;
		max-width: calc(100% - 25px - 1em);
	}
}

.wForm:not(.this) .oneField input[type="radio"],
.wForm:not(.this) .oneChoice input[type="radio"],
input[type="radio"] {
	box-shadow: inset 0 0 0 .9em white;

	&:checked {
		box-shadow: inset 0 0 0 $thick*1.5 white;
		border: $thick solid rgba($textColor,1);
		background: $textColor;
	}

	+ label {
		display: inline-block;
		float: left;
		max-width: calc(100% - 25px - 1em);
	}
}


// Misc Additions

textarea {
	padding: 0.55em .7em; // to make the textarea more writeable
}

.select2-selection,
select {
	width: 100%;
	padding-right: 2em;
	-webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='#001F5C'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
	background: url('/wp-content/themes/wire/assets/dist/img/dropdown.png') no-repeat;
	background-size: 12px;
	background-position: calc(100% - .9em) 53%;
	background-repeat: no-repeat;
	background-color: white;

	&::-ms-expand {
	    display: none;
	}
}


// Select 2 Override
// -------------------

.select2-container--default:not(.override) .select2-selection--single {
	color: $textColor;
	height: auto;
	border: 2px solid $textColor;

	.select2-selection__rendered {
		padding: 0;
		min-height: 1.66em;
	}

	.select2-selection__arrow {
		display: none;
	}
}

.select2-container--below.select2-container--open .select2-selection {
	border-color: $primaryColor;
}

.select2-container--open.select2-container--default:not(.override) {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	.select2-results__option {
		padding: .75em;

		&:not(:last-of-type) {
			border-bottom: 1px solid rgba($textColor,.1)
		}
	}

	.select2-dropdown {
		border: 2px solid $primaryColor;
		border-right-width: 1px;
		box-shadow: 1px 0 0 $primaryColor;
		border-top: none;
	}
}


/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
