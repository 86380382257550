.section.topics-section {
	padding:$spaceY * 4 0;
	.content {
		text-align:center;
		padding:0 0 $spaceY * 2 0;
	}
	.topics {
		.topic {
			@include columns(2.4);
			display:block;
			text-align:center;
			padding:0 $spaceY / 2;
			min-height:$spaceY * 20;
			font-size:.9rem;
			line-height:1.1rem;
			@include respond-to('mobile') {
				width:50%;
				margin:0;
				min-height: 18rem;
				font-size: .8rem;
				line-height: 1rem;
			}
			h3 {
				margin:0 0 $spaceY;
				text-transform:none;
				color:$textColor;
			}
			img {
				display:inline-block;
				max-width:3rem;
				height:auto;
				margin:0 0 1em;
			}
		}
	}
}