.rep-results {
    background: rgba($textColor,.1);
    display: none;
    margin-top: -.75rem;
    padding: $gutter*.5;
    border-radius: 0 0 $br*.5 $br*.5;
    z-index: 0;

    img {
        border-radius: 0;
    }

    h6 {
        @include font-size(16);
        margin-bottom: .5em;
    }
}
