
.section.featured-panels {
    z-index: 2;
    clear: both;
    @include respond-to('mobile') { margin-bottom: -$MBgutter*4; }


    .section-title {
        padding:0 1rem 1rem;
        text-align:center;
    }

    &.-footer {
        padding-bottom: 0;
        margin-top: -5vh;
        // margin-bottom: -10vh;
        bottom: -10vh;
        @include respond-to('mobile') { bottom: 0 }
    }

    .content {
        @include columns(13,.5,0);
        border-radius: $br;
        overflow: hidden;
        @include respond-to('mobile') {
            padding: $MBgutter 0;
        }
    }

    ~ footer {
        padding-top: 18vh;
        @include respond-to('mobile') { padding: $MBgutter*4.5 $MBgutter $MBgutter; }
    }
}
