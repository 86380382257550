.action-box {
    // height: 500px;
    // margin-top: -14em;
    // overflow: hidden;
    position: relative;
    top: -5vh;
    z-index: 1;
    // border-radius: $br;
    // background: linear-gradient(50deg, $primaryColor 60%, rgba($primaryColor,.5)) no-repeat center / 250% 250%;
    float: none;
    display: inline-block;

    @supports (position: sticky) {
        position: sticky;
        top: 2em;

        @include respond-to('mobile') {
            top: auto;
            position: relative;
        }
    }

    .milestone:not(.-right) {
        animation: milestoneRight 1s $easeOut forwards 2.5s;
        transform-origin: right;
        transform: translate(-100%, -20%) rotate(6deg);
        transform: translate3d(-100%, -20%, 0) rotate(6deg);
        // border-radius: 1.05em 1.05em 0 1.05em;

        @keyframes milestoneRight {
            to {
                transform: translate(-100%, 0);
                transform: translate3d(-100%, 0, 0);
                opacity: 1;
            }
        }

        &:after {
            left: auto;
            right: -8px;
            transform: scale(-1,1) rotate(45deg);
            transform: scale3d(-1,1,1) rotate(45deg);
        }
    }
}
