// _marquee.scss

.-donate + .marquee,
.-appeal + .marquee {
    $color: #5079AB;
    color: $color;
    background: linear-gradient(83.48deg, $secondaryColor 0%, currentColor 100%) no-repeat 0 / 100% 100%;
	&:before { background: linear-gradient(to left, $color, rgba($color,0)); }
}

.marquee {
    $color: #14417e;
    width: 100vw;
    overflow: hidden;
    position: relative;
    margin: 0;
    overflow: hidden;
    box-sizing: content-box;
    position: relative;
    color: $color;
    background: linear-gradient(83.48deg, $textColor 0%, $color 100%) no-repeat 0 / 100% 100%;
    @include clearfix;

    &.-still {

        @include respond-to('mobile') {
            padding-left: $MBgutter;
            overflow-x: auto;
            box-sizing: border-box;

            &:before {
                content: none;
            }
        };

        ul:not(.-sub) {
            animation: none;
        }

        .-sub {
            display: none;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 4em;
        z-index: 1;
        background: linear-gradient(to left, currentColor, rgba($color,0));
        @include respond-to('mobile') { content: none; }
    }

    h4 {
        color: $primaryColor;
        @include sub;
        margin: 3em 4vw -2em;
    }

    ul {
        display: inline-block;
        margin: 0;
        padding: 3em 0;
        white-space: nowrap;
        box-sizing: content-box;
        color: #fff;

        @include respond-to('mobile') {
            padding: 5vh 0;
        }

        &:not(.-sub) {
            animation: ticker 40s linear infinite;
        }

        @keyframes ticker {
            to {
                transform: translate(-100%,0);
                transform: translate3d(-100%,0,0);
            }
        }

        ul {
            position: absolute;
            top: 0;
            left: 100%;
            animation: none;
        }
    }

    li {
        list-style: none;
        display: inline-block;
        padding: 0 4vw;
    }

    .name {
        color: $accentColor;
        font-weight: 600;
    }

    p {
        @include responsive-font-size(20);
        font-family: $headingFont;
        margin-bottom: 0.25em;
    }

    small {
        font-size: 12px;
    }
}
